import axios from "axios";
import config from "@/config/index.js";

const accessCode = {

	state: {},

	mutations: {},

	actions: {
		async fetchAccessCodes() {
			return await axios.get(config.serverBaseURL + "/api/access-codes/all");
		},
		async accessCode(action, accessCodeId) {
			return await axios.get(config.serverBaseURL + "/api/access-codes/one/" + accessCodeId);
		},
		async deleteAccessCode(action, accessCodeId) {
			return await axios.delete(config.serverBaseURL + "/api/access-codes/delete/" + accessCodeId);
		},
		async generateAccessCode(action, siteName) {
			return await axios.post(config.serverBaseURL + "/api/access-codes/generate", {
				siteName: siteName
			});
		},
		async checkAccessCode(action, accessCode) {
			return await axios.post(config.serverBaseURL + "/api/access-codes/check", {
				accessCode: accessCode
			});
		},
		async accessCodeUsers(action, accessCodeId) {
			return await axios.post(config.serverBaseURL + "/api/access-codes/users/all", {
				accessCodeId: accessCodeId
			});
		},
	},

	getters: {}

};

export default accessCode;