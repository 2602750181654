<template>
  <div>
    <ActGoal1 @onComplete="handleOnComplete" v-if="this.lastCompletedGoalId === null" />
    <ActGoal2 @onComplete="handleOnComplete" v-else-if="this.lastCompletedGoalId === 1" />
    <ActGoal3 @onComplete="handleOnComplete" v-else-if="this.lastCompletedGoalId === 2" />
    <ActGoal4 @onComplete="handleOnComplete" v-else-if="this.lastCompletedGoalId === 3" />
    <ActGoal5 @onComplete="handleOnComplete" v-else-if="this.lastCompletedGoalId === 4" />
  </div>
</template>

<script>
  import ActGoal1 from '@/components/Act/ActGoal1.vue';
  import ActGoal2 from '@/components/Act/ActGoal2.vue';
  import ActGoal3 from '@/components/Act/ActGoal3.vue';
  import ActGoal4 from '@/components/Act/ActGoal4.vue';
  import ActGoal5 from '@/components/Act/ActGoal5.vue';

  export default {
    name: 'ActSurvey',
    components: {
      ActGoal1,
      ActGoal2,
      ActGoal3,
			ActGoal4,
			ActGoal5
    },
    data() {
      return {
        goals: JSON.parse(JSON.stringify(this.$store.getters.goals)),
        lastCompletedGoalId: null,
      };
    },

    methods: {
      handleOnComplete(questions) {
        let goalId = this.lastCompletedGoalId;
        if (goalId == null) goalId = 1;
        else goalId++;

        this.completeGoal(goalId, questions);

        if(goalId == 1) this.lastCompletedGoalId++;
      },

      completeGoal(goalId, questions) {
        let status = 'incomplete';
        if (goalId == 1) status = 'complete';

				let postData = {
          goalId: goalId,
          status: status,
        };

				if(this.$route.params.userId !== undefined) postData.userId = this.$route.params.userId;

        this.$store.dispatch('saveActionSurveyGoal', postData);

        if (goalId !== 1) this.savePreSurvey(goalId, questions);
      },

      savePreSurvey(goalId, questions) {
				
				let postData = {
          goalId: goalId,
          questions: questions,
        };

				if(this.$route.params.userId !== undefined) postData.userId = this.$route.params.userId;

        this.$store.dispatch('saveActionSurveyAnswers', postData);

        this.$helper.Notification.show({
          text: 'Thank You! Survey is saved.',
          classes: ['success'],
        });

        this.$router.push(this.$store.getters.dashboardURL);
      },

      completeSurvey() {
        this.$store.dispatch('updateActionSurveyStatus', 'complete');
      },

      async fetchAssessmentSurveys() {

				let response = null;

				if(this.$route.params.userId === undefined) response = await this.$store.dispatch('myActionSurveys');
				else response = await this.$store.dispatch('userActionSurveys', this.$route.params.userId);

        let actionSurveys = response.data;
        let actionSurvey = actionSurveys[0];
        if (actionSurvey === undefined) return;
        let completedGoalIds = actionSurvey.action_survey_goals;

        if (completedGoalIds.length > 0) this.lastCompletedGoalId = completedGoalIds[completedGoalIds.length - 1].goal_id;
      },
    },

    /**
     * ===== Hooks
     */

    mounted() {
      this.fetchAssessmentSurveys();
    },
  };
</script>
