import { createStore } from "vuex";

/**
 * ===== Modules
 */

import appModule from "@/store/app.js";
import userModule from "@/store/user.js";
import caregiverModule from "@/store/caregiver";
import designationModule from "@/store/designation";
import groupModule from "@/store/group";
import individualModule from "@/store/individual";
import assessmentModule from "@/store/assessment";
import goalModule from "@/store/goal";
import actionSurveyModule from "@/store/action-survey";
import accessCodeModule from "@/store/access-code";

/**
 * ===== Store
 */

const store = createStore({

	modules: {
		app: appModule,
		user: userModule,
		caregiver: caregiverModule,
		designation: designationModule,
		group: groupModule,
		individual: individualModule,
		assessment: assessmentModule,
		goal: goalModule,
		actionSurvey: actionSurveyModule,
		accessCode: accessCodeModule
	}

});

export default store;