<template>
  <div class="position-relative | input-suggestion" style="width:100%">
    <input @input="this.search" v-model="this.keyword" type="text" class="input-style-1 | no-shadow has-icon" :placeholder="this.placeholder" autocomplete="new-password" />
    <Icon name="search" />
    <ul class="suggestions" :class="{ active: this.searching === true || this.record !== null }">
      <li @click="this.handleRecordClick" v-if="this.searching === true || this.record !== null">
        <Icon name="solid-user" v-if="this.for === 'user'" />
        <Icon name="solid-users" v-else-if="this.for === 'group'" />
        <span class="text" v-if="this.searching === true">Searching...</span>
        <span class="text" v-else>{{ this.record.title !== undefined ? this.record.title : this.record.first_name + ' ' + (this.record.last_name === null ? '' : this.record.last_name) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';

  export default {
    name: 'InputSuggestion',
    props: ['for', 'placeholder', 'role'],
    components: {
      Icon,
    },
    data() {
      return {
        searching: false,
        keyword: '',
        record: null,
      };
    },
    methods: {
      async search() {
        this.searching = true;
        let data = null;
        if (this.for === 'group') {
          data = await this.$store.dispatch('groupByUid', this.keyword);
        } else if (this.for === 'user') {
          data = await this.$store.dispatch('individualByUidOrEmail', this.keyword);
        }

        if (data == null) data = null;
        else data = data.data;

        if (data !== null && this.role !== undefined) {
          if (data.role_title !== this.role) data = null;
        }

        this.record = data;
        this.searching = false;
      },

      handleRecordClick() {
        if (this.record === null) return;
        this.$emit('selectSuggestion', this.record);
        this.record = null;
        this.keyword = '';
      },
    },
  };
</script>
