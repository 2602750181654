<template>
  <div>
    <div class="margin-top-2">
			
      <div class="container">
        <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
          <ul>
            <li><a href="#">Assess</a></li>
            <li class="active"><a href="#">Act</a></li>
            <li><a href="#">Follow up</a></li>
          </ul>
          <!-- <div class="assessment-audio" @click="this.audio">
            <Icon name="solid-volume" :classes="['fill-secondary-400']" />
            <span>Listen to text</span>
          </div> -->
        </div>
      </div>

      <div class="container | margin-top-2">
        <div class="d-flex justify-content-center">
          <p class="section-heading-5 | text-align-center max-width-80 light">
            <b>Let's continue to learn about how you can keep your mouth healthy by eating right, brushing your teeth, and visiting your dental provider.</b>
          </p>
        </div>

				<CaptionCards active="all" complete="0" />

        <div class="d-flex justify-content-center">
          <p class="section-heading-5 | text-align-center max-width-80 light">
            <b>We are going to start with the importance of visiting your dental provider.</b>
          </p>
        </div>

        <div class="margin-top-3 | text-align-right">
          <router-link :to="this.$route.params.userId === undefined ? '/act/survey' : '/act/survey/' + this.$route.params.userId " class="button button-primary min-width-button | width-md-100">Next</router-link>
        </div>

      </div>
			
    </div>
  </div>
</template>

<script>
  // import Icon from '@/components/Icon/Icons.vue';
	import CaptionCards from '@/components/Act/ActCaptionCards.vue';

  export default {
    name: 'ActIndex',
    components: { CaptionCards },
  };
</script>
