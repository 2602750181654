<template>
  <div class="container">
    <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
      <ul>
        <li><a href="#">Assess</a></li>
        <li class="active"><a href="#">Act</a></li>
        <li><a href="#">Follow up</a></li>
      </ul>
    </div>
    <div v-if="this.pageNumber == 1">
      <h1 class="pre-survey-title | color-secondary-400">Week 1: Visit Your Dentist</h1>
      <div class="pre-survey-grids-3 | margin-top-3">
        <div class="grid">
          <div class="pre-survey-vertical-section">
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
            <img src="@/assets/pre-survey-1.jpg" alt="img1" />
            <ul>
              <li>Dentists can help prevent mouth-problems and fix them if needed</li>
              <li>Your dental office welcomes you and helps you be comfortable</li>
            </ul>
          </div>
        </div>
        <div class="grid">
          <div class="pre-survey-vertical-section">
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
            <img src="@/assets/pre-survey-2.jpg" alt="img2" />
            <ul>
              <li>Visit your dentist at least twice a year or as recommended</li>
              <li>Don't wait to visit your dentist if you have mouth pain or sensitivity</li>
              <li>Between visits, eat healthy and brush your teeth</li>
            </ul>
          </div>
        </div>
        <div class="grid">
          <div class="pre-survey-vertical-section">
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Tips</h2>
            <img src="@/assets/pre-survey-3.jpg" alt="img3" />
            <ul>
              <li>Bring a comfort item with you</li>
              <li>Ask about what will happen during your visit</li>
              <li>Tell your office about any worries or concerns you have</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.pageNumber == 2">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-3">
        <div>&nbsp;</div>
        <h2 class="pre-survey-title | color-secondary-400 text-align-center">Well Done!</h2>
        <div>&nbsp;</div>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <div class="d-flex justify-content-center">
        <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
          <b> You did a great job learning about <span class="color-primary-400">visiting your dentist</span>. This week you will learn more about <span class="color-secondary-400">brushing your teeth</span>. </b>
        </p>
      </div>

      <CaptionCards active="2" complete="1" />
    </div>

    <div class="margin-top-2 | text-align-right">
      <button @click="changePage()" class="button button-primary min-width-button | width-md-100">Next</button>
    </div>
  </div>
</template>

<script>
  
  import WizardBar from '@/components/Act/ActWizardBar.vue';
  import CaptionCards from '@/components/Act/ActCaptionCards.vue';

  export default {
    name: 'ActGoal1',
    components: {
      WizardBar,
      CaptionCards,
    },
    data() {
      return {
        pageNumber: 1,
      };
    },
    methods: {
      changePage() {
        this.pageNumber++;

        if (this.pageNumber === 3) {
          this.$emit('onComplete', null);
        }
      },
    },
  };
</script>
