<template>
  <div class="app app-border">
    <PrimaryNavigation />
    <router-view></router-view>
    <PrimaryFooter />
    <div :class="{ overlay: true, active: this.$store.getters.overlay !== false }"></div>
  </div>
</template>

<script>
  import PrimaryNavigation from '../components/PrimaryNavigation.vue';
  import PrimaryFooter from '../components/PrimaryFooter.vue';

  export default {
    name: 'WebLayout',
    components: {
      PrimaryNavigation,
      PrimaryFooter,
    },

    /**
     * ===== Hooks
     */

    mounted() {
      let documentEvent = this.$store.getters.documentEvent;
      let store = this.$store;
      if (documentEvent.click === null) {
        const func = document.addEventListener('click', function (event) {
          let target = event.target;
          let profileBadge = document.querySelector('.profile-badge');

          if (target.closest('.profile-badge') === null && target.classList.contains('profile-badge') !== true && profileBadge !== null) profileBadge.classList.remove('active');

          if (target !== undefined && target.classList.contains('overlay')) store.dispatch('hideConfirmation');
        });

        const resizeFunc = function () {
          let screenWidth = screen.width;

          let normalVideoDimentionsEls = document.querySelectorAll('.normal-video-dimentions');

          if (screenWidth !== undefined && screenWidth !== null && screenWidth < 768) {
						normalVideoDimentionsEls.forEach(el => {
							el.removeAttribute('width');
							el.removeAttribute('height');
							el.setAttribute('style', `width:100%;height:25vh;`);
						});
          }
					else if(screenWidth !== undefined && screenWidth !== null && screenWidth >= 768) {
						normalVideoDimentionsEls.forEach(el => {
							el.removeAttribute('style');
							el.setAttribute('width','640');
							el.setAttribute('height','360');
						});
					}

        };

        const resize = window.addEventListener('resize', resizeFunc);

        this.$store.commit('documentEventClick', func);
        this.$store.commit('documentEventResize', resize);

        let timezoneName = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        document.cookie = `timezone=${timezoneName}; path=/`;

        setTimeout(()=>{ resizeFunc(); },1000);
      }
    },
  };
</script>
