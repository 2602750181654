<template>
  <div class="section-border">
    <div class="widget-header">
      <div class="widget-icon-text-header left">
        <Icon name="outline-result" class="icon" style="stroke:var(--clr-neutral-900)" />
        <h2 class="widget-heading">Risk Assessment -- <router-link :to="this.resultLink()" class="color-secondary-400">Result</router-link></h2>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon/Icons.vue";
export default {
  name: "AssessmentResultWidget",
	props:["userId"],
	components: {
    Icon,
  },
	methods: {
		resultLink(){

			if(this.userId !== undefined && this.userId !== null) return `/assessment/user/${this.userId}/result/show?continue=false`

			return '/assessment/result/show?continue=false';
		}
	}
};
</script>