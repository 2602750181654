import { createRouter, createWebHistory } from 'vue-router';

import config from "@/config/index.js";
import store from './../store/index.js';
import helper from './../helpers/index'


/**
 * ===== Layouts
 */

import WebLayout from "@/layouts/Web.vue";
import AccountLayout from "@/layouts/Account.vue";

/**
 * ===== Components
 */

import RegisterForm from "@/components/RegisterForm.vue";
import LoginForm from "@/components/LoginForm.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import CompleteProfile from "@/components/CompleteProfile.vue";
import About from "@/components/About.vue";
import Terms from "@/components/Terms.vue";
import Profile from "@/components/Profile";
import Resources from "@/components/Resources.vue";
import ResourcesGoal1 from "@/components/ResourcesGoal1.vue";
import ResourcesGoal2 from "@/components/ResourcesGoal2.vue";
import ResourcesGoal2a from "@/components/ResourcesGoal2a.vue";
import ResourcesGoal2b from "@/components/ResourcesGoal2b.vue";
import ResourcesGoal2c from "@/components/ResourcesGoal2c.vue";
import ResourcesGoal3 from "@/components/ResourcesGoal3.vue";
import ResourcesGoal3a from "@/components/ResourcesGoal3a.vue";
import ResourcesGoal3b from "@/components/ResourcesGoal3b.vue";
import ResourcesGoal3c from "@/components/ResourcesGoal3c.vue";
import ResourcesGoal3d from "@/components/ResourcesGoal3d.vue";
import ResourcesGoal3e from "@/components/ResourcesGoal3e.vue";
import ResourcesGoal4 from "@/components/ResourcesGoal4.vue";
import ResourcesGoal4a from "@/components/ResourcesGoal4a.vue";
import ResourcesGoal4b from "@/components/ResourcesGoal4b.vue";
import ResourcesGoal4c from "@/components/ResourcesGoal4c.vue";
import ResourcesGoal4d from "@/components/ResourcesGoal4d.vue";
import ResourcesGoal5 from "@/components/ResourcesGoal5.vue";
import ResourcesGoal5a from "@/components/ResourcesGoal5a.vue";
import ResourcesGoal5b from "@/components/ResourcesGoal5b.vue";
import ResourcesGoal5c from "@/components/ResourcesGoal5c.vue";

import IndividualDashboard from "@/components/Individual/Dashboard.vue";

import CaregiverDashboard from "@/components/Caregiver/Dashboard.vue";
import CaregiverAssign from "@/components/Caregiver/Assign.vue";

import GroupCreate from "@/components/Group/Create.vue";
import GroupShow from "@/components/Group/Show.vue";
import GroupAdd from "@/components/Group/AddGroup.vue";

import AssessmentInitial from "@/components/Assessment/Initial.vue";
import Assessment from "@/components/Assessment/Assessment.vue";
import AssessmentResut from "@/components/Assessment/Result.vue";

import ActIndex from "@/components/Act/Index.vue";
import ActSurvey from "@/components/Act/ActSurvey.vue";
import ActPostSurvey from "@/components/Act/ActPostSurvey.vue";
import ActCompleted from "@/components/Act/ActCompleted.vue";

import AccessCode from "@/components/AccessCode/AccessCode.vue";
import ActionPlanPrint from "@/components/ActionPlan/Print.vue";

import AdminDashboard from "@/components/Admin/Dashboard.vue";
import ManageAccessCode from "@/components/AccessCode/ManageAccessCode.vue";
import UsersManage from "@/components/User/ManageUser.vue";


/**
 * ===== Routes
 */

const routes = [
	{
		path: "/",
		name: "WebLayout",
		component: WebLayout,
		children: [
			{
				path: "/account",
				name: "AccountLayout",
				component: AccountLayout,
				children: [
					{
						path: "/register",
						name: "RegisterForm",
						component: RegisterForm,
						meta: {
							tabTitle: "Register Account | " + config.appName,
							pageSlug: "register",
						}
					},
					{
						path: "/login",
						name: "LoginForm",
						component: LoginForm,
						meta: {
							tabTitle: "Login Account | " + config.appName,
							pageSlug: "login"
						}
					},
					{
						path: "/",
						name: "LoginForm2",
						component: LoginForm,
						meta: {
							tabTitle: "Login Account | " + config.appName,
							pageSlug: "login"
						}
					},
					{
						path: "/forgot-password",
						name: "ForgotPassword",
						component: ForgotPassword,
						meta: {
							tabTitle: "Forgot Password | " + config.appName,
							pageSlug: "forgot-password"
						}
					},

				]
			},
			{
				path: "/resources",
				name: "resources",
				component: Resources,
				meta: {
					tabTitle: "Resources | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-1",
				name: "resources-goal-1",
				component: ResourcesGoal1,
				meta: {
					tabTitle: "Visit Your Dentist | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-2",
				name: "resources-goal-2",
				component: ResourcesGoal2,
				meta: {
					tabTitle: "Brush Your Teeth | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-2a",
				name: "resources-goal-2a",
				component: ResourcesGoal2a,
				meta: {
					tabTitle: "Brush Twice a Day | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-2b",
				name: "resources-goal-2b",
				component: ResourcesGoal2b,
				meta: {
					tabTitle: "Brush Twice Every Day | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-2c",
				name: "resources-goal-2c",
				component: ResourcesGoal2c,
				meta: {
					tabTitle: "Brush All of Your Teeth | " + config.appName,
					pageSlug: 'resources'
				}
			},
			{
				path: "/resources/goal-3",
				name: "resources-goal-3",
				component: ResourcesGoal3,
				meta: {
					tabTitle: "Choose Healthy Foods | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-3a",
				name: "resources-goal-3a",
				component: ResourcesGoal3a,
				meta: {
					tabTitle: "Make Healthy Food Choices for your teeth | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-3b",
				name: "resources-goal-3b",
				component: ResourcesGoal3b,
				meta: {
					tabTitle: "Snacking | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-3c",
				name: "resources-goal-3c",
				component: ResourcesGoal3c,
				meta: {
					tabTitle: "Why some foods are bad for our teeth | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-3d",
				name: "resources-goal-3d",
				component: ResourcesGoal3d,
				meta: {
					tabTitle: "Food Groups | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-3e",
				name: "resources-goal-3e",
				component: ResourcesGoal3e,
				meta: {
					tabTitle: "Snap Resources | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-4",
				name: "resources-goal-4",
				component: ResourcesGoal4,
				meta: {
					tabTitle: "Make Water Your Go-to Drink | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-4a",
				name: "resources-goal-4a",
				component: ResourcesGoal4a,
				meta: {
					tabTitle: "Make Healthy Drink Choices for your teeth | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-4b",
				name: "resources-goal-4b",
				component: ResourcesGoal4b,
				meta: {
					tabTitle: "Make Healthy Drink Choices for your teeth | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-4c",
				name: "resources-goal-4c",
				component: ResourcesGoal4c,
				meta: {
					tabTitle: "Limiting Sugary Drinks | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-4d",
				name: "resources-goal-4d",
				component: ResourcesGoal4d,
				meta: {
					tabTitle: "What to Drink | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-5",
				name: "resources-goal-5",
				component: ResourcesGoal5,
				meta: {
					tabTitle: "Eat Thoughtfully | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-5a",
				name: "resources-goal-5a",
				component: ResourcesGoal5a,
				meta: {
					tabTitle: "Thoughtful Eating | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-5b",
				name: "resources-goal-5b",
				component: ResourcesGoal5b,
				meta: {
					tabTitle: "Thoughtful Eating 1 | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/resources/goal-5c",
				name: "resources-goal-5c",
				component: ResourcesGoal5c,
				meta: {
					tabTitle: "Thoughtful Eating 2 | " + config.appName,
					pageSlug: 'resources',
					abilities: []
				}
			},
			{
				path: "/about",
				name: "about",
				component: About,
				meta: {
					tabTitle: "About Us | " + config.appName,
					pageSlug: 'about'
				}
			},
			{
				path: "/terms-of-services",
				name: "terms",
				component: Terms,
				meta: {
					tabTitle: "Terms of Service | " + config.appName,
					pageSlug: 'terms'
				}
			},
			{
				path: "/complete-profile/:id",
				name: "CompleteProfile",
				component: CompleteProfile,
				meta: {
					tabTitle: "Complete Profile | " + config.appName,
					pageSlug: "complete-profile"
				}
			},
			{
				path: "/profile",
				name: "profile",
				component: Profile
			},
			{
				path: "/individual",
				name: "individual",
				children: [
					{
						path: 'dashboard',
						name: 'IndividualDashboard',
						component: IndividualDashboard,
						meta: {
							tabTitle: "Dashboard | " + config.appName,
							pageSlug: "individual-dashboard",
							abilities: ["individual-dashboard"]
						}
					},
					{
						path: 'dashboard/:userId',
						name: 'IndividualDashboard2',
						component: IndividualDashboard,
						meta: {
							tabTitle: "Dashboard | " + config.appName,
							pageSlug: "individual-dashboard",
							abilities: ["individual-dashboard"]
						}
					}
				]
			},
			{
				path: "/caregiver",
				name: "caregiver",
				children: [
					{
						path: 'dashboard',
						name: 'CaregiverDashboard',
						component: CaregiverDashboard,
						meta: {
							tabTitle: "Dashboard | " + config.appName,
							pageSlug: "caregiver-dashboard",
							abilities: ["caregiver-dashboard"]
						}
					},
					{
						path: 'assign',
						name: 'CaregiverAssign',
						component: CaregiverAssign,
						meta: {
							tabTitle: "Add New Member | " + config.appName,
							pageSlug: "assign",
						}
					}
				]
			},
			{
				path: "/group",
				name: "group",
				children: [
					{
						path: "add",
						name: "GroupAdd",
						component: GroupAdd,
						meta: {
							tabTitle: "Add Group | " + config.appName,
							pageSlug: "add-group",
							abilities: ["group"]
						}
					},
					{
						path: 'create',
						name: 'GroupCreate',
						component: GroupCreate,
						meta: {
							tabTitle: "Create Group | " + config.appName,
							pageSlug: "create-group",
							abilities: ["group"]
						}
					},
					{
						path: 'edit/:groupId',
						name: 'GroupEdit',
						component: GroupCreate,
						meta: {
							tabTitle: "Edit Group | " + config.appName,
							pageSlug: "edit-group",
							abilities: ["group"]
						}
					},
					{
						path: 'show/:id',
						name: 'GroupShow',
						component: GroupShow,
						meta: {
							tabTitle: "Diamond Group | " + config.appName,
							pageSlug: "group",
							abilities: ["group"]
						}
					}
				]
			},
			{
				path: "/assessment/:assessmentId",
				name: "Assessment",
				component: Assessment,
				meta: {
					tabTitle: "Assessment | " + config.appName,
					pageSlug: "assessment",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/assessment/:assessmentId/:userId",
				name: "AssessmentUser",
				component: Assessment,
				meta: {
					tabTitle: "Assessment | " + config.appName,
					pageSlug: "assessment",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/assessment/initial",
				name: "AssessmentInitial",
				component: AssessmentInitial,
				meta: {
					tabTitle: "Assessment | " + config.appName,
					pageSlug: "assessment",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/assessment/initial/:userId",
				name: "AssessmentInitialUser",
				component: AssessmentInitial,
				meta: {
					tabTitle: "Assessment | " + config.appName,
					pageSlug: "assessment",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/assessment/result/show",
				name: "AssessmentResut",
				component: AssessmentResut,
				meta: {
					tabTitle: "Assessment Result | " + config.appName,
					pageSlug: "assessment",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/assessment/user/:userId/result/show",
				name: "AssessmentUserResut",
				component: AssessmentResut,
				meta: {
					tabTitle: "Assessment Result | " + config.appName,
					pageSlug: "assessment",
					abilities: ["caregiver-dashboard"]
				}
			},
			{
				path: "/act",
				name: "ActIndex",
				component: ActIndex,
				meta: {
					tabTitle: "Act | " + config.appName,
					pageSlug: "act",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/act/:userId",
				name: "ActIndexUser",
				component: ActIndex,
				meta: {
					tabTitle: "Act | " + config.appName,
					pageSlug: "act",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/act/completed",
				name: "ActCompleted",
				component: ActCompleted,
				meta: {
					tabTitle: "Congratulations | " + config.appName,
					pageSlug: "act",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: '/act/survey',
				name: 'ActSurvey',
				component: ActSurvey,
				meta: {
					tabTitle: "Act Survey | " + config.appName,
					pageSlug: "act-survey",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: '/act/survey/:userId',
				name: 'ActSurveyUser',
				component: ActSurvey,
				meta: {
					tabTitle: "Act Survey | " + config.appName,
					pageSlug: "act-survey",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: '/act/post-survey/:actionSurveyId/:goalId',
				name: 'ActPostSurvey',
				component: ActPostSurvey,
				meta: {
					tabTitle: "Action Post Survey | " + config.appName,
					pageSlug: "act-post-survey",
					abilities: ["take-risk-assessment"]
				}
			},
			{
				path: "/admin",
				name: "admin",
				children: [
					{
						path: "dashboard",
						name: "AdminDashboard",
						component: AdminDashboard,
						meta: {
							tabTitle: "Admin Dashboard | " + config.appName,
							pageSlug: "admin-dashboard",
							abilities: ["admin-dashboard"]
						}
					}
				]
			},
			{
				path: "/access-codes/manage",
				name: "ManageAccessCode",
				component: ManageAccessCode,
				meta: {
					tabTitle: "Manage Access Codes | " + config.appName,
					pageSlug: "access-codes",
					abilities: ["manage-access-code"]
				}
			},
			{
				path: "/access-codes/code/:accessCodeId",
				name: "AccessCode",
				component: AccessCode,
				meta: {
					tabTitle: "Access Code | " + config.appName,
					pageSlug: "access-code",
					abilities: ["manage-access-code"]
				}
			},
			{
				path: "/users/manage",
				name: "UsersManage",
				component: UsersManage,
				meta: {
					tabTitle: "Manage Users | " + config.appName,
					pageSlug: "manage-users",
					abilities: ["manage-users"]
				}
			},
			{
				path: "/action-plan/print/:goalId",
				name: "ActionPlanPrint",
				component: ActionPlanPrint,
				meta: {
					tabTitle: "Print Options | " + config.appName,
					pageSlug: "access-code",
					abilities: ["individual-dashboard"]
				}
			}
		]
	}
];

/**
 * ===== Settings
 */

const router = createRouter({
	history: createWebHistory(config.build.dir),
	scrollBehavior() {
		return { top: 0 }
	},
	routes,
});

/**
 * ===== Methods
 */


function checkAbilities(abilitiesRequired) {

	let me = store.getters.user;
	let redirect = null;

	if (me.token === null) redirect = "/login";

	let myAbilities = me.abilities;

	abilitiesRequired.forEach((ability) => {
		if (myAbilities.includes(ability) === false) redirect = "/login";
	});

	if (redirect !== null) router.push(redirect);
}


/**
 * ===== Hooks
 */

router.beforeEach(async () => {

	// localstorage
	if (store.getters.user.token === null) {
		let me = helper.storage.get("user");
		if (me !== null) store.commit("auth", me);
	}

	// fetch goals
	if (store.getters.user.token !== null && store.getters.goals.length <= 0) await store.dispatch("goals");

});

router.afterEach((to) => {

	// ability
	let abilitiesRequired = (to.meta !== undefined && to.meta.abilities !== undefined) ? to.meta.abilities : null;
	if (abilitiesRequired !== null) checkAbilities(abilitiesRequired);


	// meta
	store.commit("pageSlug", to.meta === undefined ? null : to.meta.pageSlug);
	helper.DOM.setPageMeta(to.meta !== undefined ? to.meta : {});

});


/**
 * ===== Exports
 */

export default router;