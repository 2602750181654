<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Brush Twice Every Day</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-4.jpg" alt="img1" />
          <ul>
            <li>Find a soft toothbrush that you like</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/resource-5.jpg" alt="img2" />
          <ul>
            <li>Find a fluoride toothpaste that you like</li>
          </ul>
        </div>
      </div>
    </div>
		<div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-6.jpg" alt="img1" />
          <ul>
            <li>Start by brushing as much as you can and get help doing more until you can do most brushing by yourself</li>
          </ul>
        </div>
      </div>
    </div>
			<div class="margin-top-3">
				<router-link to="/resources/goal-2" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
		name:"ResurcesGoal2b"
	};
</script>