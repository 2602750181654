<template>
  <div>
    <p class="section-text section-text-1 fw-500 | margin-bottom-0-5">Welcome back to {{ this.$config.appName }}!</p>
    <h1 class="section-heading section-heading-1 color-primary-400">Continue your dental health journey</h1>

    <form @submit.prevent="login" id="account-form" action="#" class="margin-top-3">
      <div class="form-group">
        <label for="email" class="label-style-1">Email</label>
        <div class="position-relative">
          <input v-model="this.auth.email" tabindex="1" name="email" type="email" class="input-style-1 has-icon" required />
          <svg class="icon">
            <use xlink:href="@/assets/icons.svg#outline-mail" />
          </svg>
        </div>
      </div>

      <div class="form-group">
        <label for="password" class="label-style-1">Password</label>
        <div class="position-relative">
          <input v-model="this.auth.password" tabindex="2" name="password" type="password" class="input-style-1 has-icon" autocomplete="new-password" required />
          <svg class="icon">
            <use xlink:href="@/assets/icons.svg#outline-lock" />
          </svg>
        </div>
      </div>

      <!-- <div class="form-group">
        <label class="label-container label-style-1">
          <input v-model="this.auth.agreement" tabindex="3" type="checkbox" required />
          <span class="checkmark"></span>
          I agree to the
          <router-link :to="'/terms-of-services' + this.redirectURL" data-redirect="/terms-of-services">terms of services</router-link>
        </label>
      </div> -->

      <div class="form-group margin-top-4">
        <button class="button button-primary button-block">Sign in</button>
      </div>

      <div class="form-group">
        <p class="section-text section-text-1 color-neutral-600 fw-500">
          If you don't have an account, please <router-link to="/register">sign up here?</router-link>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'LoginForm',
    data() {
      return {
        redirectBackURL: '/',
        auth: {
          email: '',
          password: '',
          agreement: false,
        },
      };
    },

    /**
     * ===== Computed
     */

    computed: {
      redirectURL() {
        let emailEnc = this.$helper.Encryption.encrypt(this.auth.email);
        let passwordEnc = this.$helper.Encryption.encrypt(this.auth.password);

        return `?redirect=login&e=${emailEnc}&p=${passwordEnc}`;
      },
    },

    /**
     * ===== Methods
     */

    methods: {
      fillForm(query) {
        if (query.e !== undefined && query.e !== '') this.auth.email = this.$helper.Encryption.decrypt(query.e);
        if (query.p !== undefined && query.p !== '') this.auth.password = this.$helper.Encryption.decrypt(query.p);
      },

      async login() {
				
				let n = this.$helper.Notification.show({
					text: 'Authenticating, please wait...',
					classes: [],
					time:0
				});

				let response = await this.$store.dispatch('auth', this.auth);

				this.$helper.Notification.hide(n.data.id);

				if(response.data.status !== 'success') {
					this.$helper.Notification.show({
						text: response.data.msg,
						classes: [response.data.status]
					});
					return;
				}


				let roleTitle = response.data.user.role_title;
				if(response.data.user.first_name === null) this.$router.push(`/complete-profile/${response.data.user.id}`);
				else this.$router.push(`/${roleTitle}/dashboard`);

      },
    },

    /**
     * ===== Hooks
     */

    mounted() {
      this.fillForm(this.$route.query);
			if(this.$store.getters.isLoggedIn() === true) this.$router.push(this.$store.getters.dashboardURL);
    },
  };
</script>
