import axios from "axios";
import config from "@/config/index.js";


const designation = {

	state: {},

	mutations: {},

	actions: {
		async designations() {
			return await axios.get(config.serverBaseURL + "/api/designations");
		}
	},

	getters: {}

};

export default designation;