<template>
  <div class="margin-top-1">
    <div class="container">
      <div class="margin-bottom-5">
        <div class="d-flex align-items-center justify-content-space-between | margin-bottom-2">
          <h2 class="section-heading section-heading-3">Dashboard</h2>
          <p class="page-user-id">ID# {{ this.$store.getters.user.uid }}</p>
        </div>

        <div class="data-table-container">
          <div class="data-table-toolbar sticky">
            <div class="data-table-toolbar-section right">
              <div class="data-table-toolbar-section-option d-flex align-items-center gap-1">
                <router-link to="/group/add" class="button button-primary">
                  <svg class="icon">
                    <use xlink:href="@/assets/icons.svg#solid-plus" />
                  </svg>
                  Add Group
                </router-link>
              </div>

              <div class="data-table-toolbar-section-option d-flex align-items-center gap-1">
                <router-link to="/caregiver/assign" class="button button-primary">
                  <svg class="icon">
                    <use xlink:href="@/assets/icons.svg#solid-plus" />
                  </svg>
                  Add New Member
                </router-link>
              </div>
            </div>
          </div>

          <table id="page-table" class="data-table">
            <thead class="sticky">
              <tr>
                <th data-uid="true" class="hide"></th>
                <th></th>
                <th>ID</th>
                <th>Name</th>
                <th>Progress</th>
                <th>Last Access</th>
              </tr>
            </thead>

            <tbody>
              <tr @click="this.$router.push(`/individual/dashboard/${user.id}`)" v-for="(user, index) in this.individuals" :key="index" class="cursor-pointer">
                <td class="">
                  <Icon name="solid-user" :class="{ 'icon-round': true, 'icon-primary': user.added_by === null, 'icon-secondary': user.added_by !== null }" />
                </td>
                <td>{{ user.uid }}</td>
                <td>{{ user.first_name + ' ' + user.last_name }}</td>
                <td>
                  <span class="tag" :class="{ 'tag-secondary': this.$store.getters.progressName(user.action_surveys[0].action_survey_goals) !== 'Completed', 'tag-success': this.$store.getters.progressName(user.action_surveys[0].action_survey_goals) === 'Completed' }" v-if="user.action_surveys[0]">{{ this.$store.getters.progressName(user.action_surveys[0].action_survey_goals) }}</span>
                  <span class="tag tag-danger" v-else>Not Started</span>
                </td>
                <td>{{ user.last_access }}</td>
              </tr>
              <tr @click="this.$router.push(`/group/show/${group.id}`)" v-for="(group, index) in this.groups" :key="index" class="cursor-pointer">
                <td class="">
                  <Icon name="solid-users" :classes="['icon-primary', 'icon-round']" />
                </td>
                <td>{{ group.uid }}</td>
                <td>{{ group.title }}</td>
                <td><span class="tag tag-secondary-alt">Group</span></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';

  export default {
    name: 'CaregiverDashboard',
    components: { Icon },
    data() {
      return {
        groups: [],
        individuals: [],
      };
    },

    /**
     * ===== methods
     */

    methods: {},

    /**
     * ===== mounted
     */

    async mounted() {
      let me = await this.$store.dispatch('me');
      this.groups = me.data.groups;

      let localIndividuals = me.data.individuals;
      this.individuals = localIndividuals.map((u) => u.individual);
    },
  };
</script>
