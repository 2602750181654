/* eslint-disable no-unused-vars */
function getFullYear() {
    let date = new Date();
    return date.getFullYear();
}
function timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
function addDaysToDate(dateString, daysToAdd) {
    let date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear().toString();
    if (month < 10)
        month = "0" + month.toString();
    if (day < 10)
        day = "0" + day.toString();
    return {
        month: month,
        day: day,
        year: year
    };
}
export default {
    getFullYear,
    timeZone,
    addDaysToDate
};
/* eslint-enable no-unused-vars */
