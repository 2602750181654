import axios from "axios";
import config from "@/config/index.js";
import helper from "@/helpers/index.js";
import router from "@/routes/index.js";

/**
 * configuration
 */

axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
	config.headers.Authorization = `Bearer ${user.state.token}`;
	return config;
})

axios.interceptors.response.use(response => {
	return response;
}, () => {
	return {
		user: null,
		token: null
	}
})

const user = {

	state: {
		id: null,
		uid: null,
		firstName: null,
		lastName: null,
		email: null,
		phone: null,
		dob: null,
		roleTitle: null,
		abilities: [],
		token: null,
	},

	mutations: {
		auth(state, user) {
			state.id = user.id;
			state.uid = user.uid;
			state.firstName = user.firstName;
			state.lastName = user.lastName;
			state.email = user.email;
			state.phone = user.phone;
			state.roleTitle = user.roleTitle;
			state.abilities = user.abilities;
			state.dob = user.dob;
			state.token = user.token;

			helper.storage.set('user', user);
		},
		logout(state) {
			state.firstName = null;
			state.lastName = null;
			state.email = null;
			state.phone = null;
			state.roleTitle = null;
			state.abilities = [];
			state.dob = null;
			state.token = null;

			helper.storage.set('user', null);
			router.push('/');
		}
	},

	actions: {

		async auth({ commit }, user) {
			let response = await axios.post(config.serverBaseURL + '/api/users/auth', user);

			if (response.data.status == 'success') {
				commit('auth', {
					id: response.data.user.id,
					uid: response.data.user.uid,
					firstName: response.data.user.first_name,
					lastName: response.data.user.last_name,
					phone: response.data.user.phone,
					email: response.data.user.email,
					roleTitle: response.data.user.role_title,
					abilities: response.data.user.abilities,
					dob: response.data.user.dob,
					token: response.data.user.token
				});
			}
			return response;
		},

		async register({ commit }, user) {
			let response = await axios.post(config.serverBaseURL + '/api/users/register', user);
			if (response.data.status == 'success') {
				commit('auth', {
					id: response.data.user.id,
					uid: response.data.user.uid,
					firstName: null,
					lastName: null,
					phone: null,
					email: response.data.user.email,
					roleTitle: response.data.user.role_title,
					abilities: response.data.user.abilities,
					dob: null,
					token: response.data.user.token
				});
			}
			return response;
		},

		async completeProfile({ commit, state }, user) {
			let response = await axios.post(config.serverBaseURL + '/api/users/complete-profile', user);
			if (response.data.status === "success") commit("auth", { ...state, firstName: user.firstName, lastName: user.lastName, phone: user.phone, dob: user.dob });
			return response;
		},

		async saveProfile({ state, commit }, profile) {
			let response = await axios.put(config.serverBaseURL + '/api/users', profile);

			if (response.data.status == "success" && profile.id === undefined) {
				delete profile["password"];
				commit("auth", { ...state, ...profile })
			}

			return response;
		},

		async me() {
			return await axios.get(config.serverBaseURL + '/api/users/me');
		},

		async user(action, userId) {
			return await axios.get(config.serverBaseURL + '/api/users/one/' + userId);
		},

		async myCaregivers() {
			return await axios.get(config.serverBaseURL + '/api/caregivers/me');
		},

		async userCaregivers(action, userId) {
			return await axios.get(config.serverBaseURL + '/api/caregivers/user/' + userId);
		},

		async users() {
			return await axios.get(config.serverBaseURL + '/api/users/all');
		},

		async deleteUser(action, userId) {
			return await axios.delete(config.serverBaseURL + '/api/users/delete/' + userId);
		}

	},

	getters: {

		user: (state) => {
			return state;
		},

		isLoggedIn: (state) => (role = null) => {
			if (state.token !== null && (state.roleTitle === role || role === null)) return true;
			return false;
		},

		profileComplete: (state) => {
			if (state.firstName === null) return false;
			else return true;
		},

		fullName: (state) => {
			return `${state.firstName} ${state.lastName}`
		},

		dob: (state) => {
			return state.dob;
		},

		roleTitle: (state) => {
			return state.roleTitle;
		},

		dashboardURL: (state) => {

			if (state.roleTitle === null) return '/';

			return `/${state.roleTitle}/dashboard`;
		}

	}

};

export default user;