<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Brush All of Your Teeth</h1>
    <div class="pre-survey-grids-2 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-7.jpg" alt="img1" />
          <ul>
            <li>To clean food and germs off your teeth so cavities don’t happen</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>

          <p class="section-heading-5">
            <b>Make sure ALL of your teeth are brushed:</b>
          </p>

					<ul>
						<li>Start with your upper teeth. Brush the outside (cheek side) and then the inside (tongue side) all the way around.</li>
						<li>Next, brush the tongue side of all your top and bottom teeth</li>
						<li>Finish by brushing the tops of your back teeth</li>
					</ul>

					<p class="section-heading-5 | margin-top-3">
            <b>There, now you are done!</b>
          </p>

					<img class="margin-top-2" src="@/assets/resource-8.jpg" alt="img1" />

        </div>
      </div>
    </div>
    <div class=" | margin-top-3" style="justify-content:flex-start;">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border d-flex break-md-100 gap-3">
          <img src="@/assets/resource-9.jpg" alt="img1" style="max-width:30rem;" />
          <div>
						<h2 class="pre-survey-vertical-section-heading | color-secondary-400">Tips</h2>
						<ul>
							<li>Brush as many teeth as you can</li>
							<li>Get help brushing the rest of your teeth</li>
						</ul>
					</div>
        </div>
      </div>
    </div>
			<div class="margin-top-3">
				<router-link to="/resources/goal-2" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResurcesGoal2c',
  };
</script>
