<template>
  <div class="container">
    <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
      <ul>
        <li><a href="#">Assess</a></li>
        <li class="active"><a href="#">Act</a></li>
        <li><a href="#">Follow up</a></li>
      </ul>
    </div>
    <div v-if="this.pageNumber == 1">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-3">
        <div>&nbsp;</div>
        <h2 class="pre-survey-title | color-secondary-400 text-align-center">Well done!</h2>
        <div>&nbsp;</div>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <div class="d-flex justify-content-center">
        <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
          <b>You did a great job learning about <span class="color-primary-400">healthy drinking</span>. This week you will work on <span class="color-secondary-400">thoughtful eating.</span></b>
        </p>
      </div>

      <div class="margin-top-3">
        <ActCaptionCards complete="4" active="5" />
      </div>
    </div>
    <div v-if="this.pageNumber === 2">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-2">
        <h2 class="pre-survey-title | color-secondary-400">Week 4: Let’s work on eating thoughtfully</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <p class="section-heading-5 | light margin-top-1">
        <b>This week we will work on eating thoughtfully. What does that mean?</b>
      </p>
      <p class="section-heading-5 | light margin-top-1">
        <b>Thoughtful eating means eating foods and drinks that are healthy for our bodies. Thoughtful eating means eating regular meals and limiting snacks to healthy foods. Here are two things we will work on together this week:</b>
      </p>
      <div class="d-flex justify-content-center">
        <div class="grids grids-2 | grids-break-lg-100 gap-5 margin-top-3" style="max-width: 90%">
          <div class="grid">
            <ul class="assessment-result-list secondary-400 | margin-bottom-2">
              <li>
                <div class="count">1</div>
                <div class="info">Make mealtime a planned time to eat</div>
              </li>
              <li>
                <div class="count">2</div>
                <div class="info">Don’t eat just because you are bored or want a reward</div>
              </li>
            </ul>
          </div>
          <div class="grid">
            <img src="@/assets/pre-survey-23.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.pageNumber === 3">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-secondary-400">Week 4: Let’s work on eating thoughtfully</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>

      <div>
        <div class="pre-survey-grids-3 | margin-top-3">
          <div class="grid">
            <div class="pre-survey-vertical-section">
              <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why is it important to eat??</h2>
              <ul>
                <li>To get energy</li>
                <li>To get healthy</li>
                <li>To have fun with friends and family</li>
              </ul>
							<img class="margin-top-2" src="@/assets/pre-survey-24.jpg" alt="img1" />
            </div>
          </div>
          <div class="grid">
            <div class="pre-survey-vertical-section">
              <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Sometimes we eat because:</h2>
              
              <ul>
                <li>we are bored</li>
                <li>we are sad</li>
                <li>we want a reward</li>
              </ul>
							<img class="margin-top-2" src="@/assets/pre-survey-25.jpg" alt="img1" />
            </div>
          </div>
          <div class="grid">
            <div class="pre-survey-vertical-section">
              <h2 class="pre-survey-vertical-section-heading | color-secondary-400">That is ok! <br /> But instead we can try:</h2>
              
              <ul>
                <li>a fun exercise</li>
                <li>watching a show</li>
                <li>listening to music</li>
                <li>talking to a friend</li>
              </ul>
							<img class="margin-top-2" src="@/assets/pre-survey-26.jpg" alt="img1" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.pageNumber === 4">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">eating thoughtfully:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[0]" />
    </div>
    <div v-if="this.pageNumber === 5">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">eating thoughtfully:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[1]" />
    </div>
    <div v-if="this.pageNumber === 6">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">eating thoughtfully:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[2]" />
    </div>
    <div v-if="this.pageNumber === 7">
      <WizardBar active="5" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">eating thoughtfully:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>

      <div class="assessment-border">
        <div class="assessment-initial-grids">
          <div class="grid text-align-center">
						<div style="width: 100%" class="margin-top-3">
								<div class="yt-video-container center">
									<iframe class="yt-video yt-video-size-1"
										src="https://www.youtube.com/embed/1qZ9bOig6mI?rel=0&controls=0&iv_load_policy=3" frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
								</div>
						</div>
            <h2  class="section-heading-5 margin-top-2 margin-bottom-3" style="max-width: 55rem">We will send you reminders by email to help you reach your goals of eating <span class="color-secondary-400">thoughtfully about meals</span> and <span class="color-secondary-400">avoiding eating when you are bored or want a reward.</span></h2>
          </div>
          <div class="grid"><img src="@/assets/pre-survey-11.jpg" class="margin-left-auto-on-desktop margin-auto-on-mobile" alt="survey img" style="width: 220px" /></div>
        </div>
      </div>
    </div>
    <div class="margin-top-2 | text-align-right">
      <button @click="changePage()" class="button button-primary min-width-button | width-md-100">Next</button>
    </div>
  </div>
</template>

<script>
  // import Icon from '@/components/Icon/Icons.vue';
  import WizardBar from '@/components/Act/ActWizardBar.vue';
  import ActCaptionCards from '@/components/Act/ActCaptionCards.vue';
  import PreSurveyQuestion from '@/components/Act/PreSurveyQuestion.vue';

  import image1 from '@/assets/pre-survey-27.jpg';
  import image2 from '@/assets/pre-survey-28.jpg';
  import image3 from '@/assets/pre-survey-29.jpg';

  export default {
    name: 'ActGoal5',
    components: {
   
      WizardBar,
      ActCaptionCards,
      PreSurveyQuestion,
    },
    data() {
      return {
        pageNumber: 1,
        questions: [
          {
            title: '1. Who can help you eat good meals and avoid eating out of boredom or for a reward?',
            answerType: 'multiple',
            image: image1,
            answers: [
              {
                id: 1,
                title: 'Me',
              },
              {
                id: 2,
                title: 'Family/Caregiver',
              },
              {
                id: 3,
                title: 'Direct Support Professional (DSP)',
              },
              {
                id: 4,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
          {
            title: '2. What might make it hard for you to eat thoughtfully?',
            answerType: 'multiple',
            image: image2,
            answers: [
              {
                id: 1,
                title: "I like to eat when I'm bored",
              },
              {
                id: 2,
                title: 'I like sugary treats as a reward',
              },
              {
                id: 3,
                title: "I like to do other things while I'm eating",
              },
              {
                id: 4,
                title: 'I will not find it hard',
              },
              {
                id: 5,
                title: 'Other',
              },
      
            ],
          },
          {
            title: '3. What can help you eat thoughtfully?',
            answerType: 'multiple',
            image: image3,
            answers: [
              {
                id: 1,
                title: 'I can eat with other people',
              },
              {
                id: 2,
                title: 'I can plan to watch television after I eat',
              },
              {
                id: 3,
                title: "I can eat regular meals and cut down on snacking",
              },
              {
                id: 4,
                title: 'I can make meal times just time to eat',
              },
							{
								id:5,
								title:"I can try another fun activity when I am bored like playing a game or listening to music"
							},
              {
                id: 5,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
        ],
      };
    },
    methods: {
      changePage() {

				if(this.pageNumber === 4 && this.answerSelected(this.questions[0].answers) === false){
					this.$helper.Notification.show({
						text: "Please select your answer",
						classes: ['fail'],
					});
					return;
				} 

				if(this.pageNumber === 5 && this.answerSelected(this.questions[1].answers) === false){
					this.$helper.Notification.show({
						text: "Please select your answer",
						classes: ['fail'],
					});
					return;
				} 

				if(this.pageNumber === 6 && this.answerSelected(this.questions[2].answers) === false){
					this.$helper.Notification.show({
						text: "Please select your answer",
						classes: ['fail'],
					});
					return;
				} 

        this.pageNumber++;
        if (this.pageNumber === 8) {
          this.$emit('onComplete', this.questions);
        }
      },
			answerSelected(answers){
				return answers.reduce((acc, ans) => {
					if(ans.selected === true) acc = true;
					return acc;
				}, false)
			}
    },
  };
</script>
