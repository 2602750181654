<template>
  <div class="account-section margin-top-2">
    <div class="container">
      <div class="grids grids-2">
        <div class="grid">
          <router-view></router-view>
        </div>
        <div class="grid">
          <div class="account-actor">
            <iframe src="https://www.youtube.com/embed/tB9vSdMQeFI?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountLayout',
    methods: {
     
    },
  };
</script>
