import DateTime from "@/helpers/datetime.js";
import DOM from "@/helpers/dom.js";
import Encryption from "@/helpers/encryption.js";
import Notification from "@/helpers/notification.js";
import storage from "@/helpers/localstorage";

import moment from 'moment';
import "moment-timezone";

function toLocalDateTime(dateTime, dateOnly = false) {

	if (dateTime == "" || dateTime == null || dateTime == false || dateTime == undefined) return "";

	let timezone = DateTime.timeZone();
	let localDateTime = moment(dateTime + " UTC-8").tz(timezone).format("MM/DD/YYYY h:mm a");
	if (dateOnly === false) return localDateTime;

	let chunks = localDateTime.split(" ");
	return chunks[0];
}

function addMinutesToDateTime(dateTimeString, minutesToAdd) {
	const dateTime = moment(dateTimeString, "MM/DD/YYYY h:mm a");
	dateTime.add(minutesToAdd, 'minutes');
	const formattedDateTimeString = dateTime.format("MM/DD/YYYY h:mm a");
	return formattedDateTimeString;
}

function now() {
	let timezone = DateTime.timeZone();
	return moment().tz(timezone).format("MM/DD/YYYY h:mm a");
}

function compareDateTime(dateTimeString1, dateTimeString2) {
	const dateTime1 = moment(dateTimeString1, "MM/DD/YYYY h:mm a");
	const dateTime2 = moment(dateTimeString2, "MM/DD/YYYY h:mm a");
	const isGreaterOrEqual = dateTime1.isSameOrAfter(dateTime2);
	return isGreaterOrEqual;
}

export default {
	DateTime,
	DOM,
	Encryption,
	Notification,
	storage,
	toLocalDateTime,
	addMinutesToDateTime,
	now,
	compareDateTime
}