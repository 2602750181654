/* eslint-disable no-unused-vars */
var _storage = function () { };
_storage.prototype.set = function (key, value) {
    if (typeof key !== "string") {
        console.error("localstorage: Invalid Key");
        return;
    }
    localStorage.setItem(key, JSON.stringify(value));
    return value;
};
_storage.prototype.get = function (key) {
    if (typeof key !== "string") {
        console.error("localstorage: Invalid Key");
        return;
    }
    let data = localStorage.getItem(key);
    if (data !== null)
        data = JSON.parse(data);
    return data;
};
//@ts-ignore
var storage = new _storage();
export default storage;
/* eslint-enable no-unused-vars */ 
