<template>
  <div class="margin-top-1">
    <div class="container">
      <div class="margin-bottom-5" style="min-height:60vh">
        <h2 class="section-heading section-heading-3 | margin-bottom-2">Add New Member</h2>

        <ToggleButton :buttons="['User has account', 'I will help create user account']">
          <template v-slot:0>
            <div>
              <form action="#" id="member-form" data-prevent="default">
                <div class="form-group">
                  <InputSuggestion @selectSuggestion="this.handleUserSelection" for="user" role="individual" placeholder="Search by user ID or email address" />
                </div>
              </form>

              <div :class="{ hide: this.users.length == 0 }" class="data-table-container | margin-top-5">
                <table id="page-table" class="data-table">
                  <thead class="sticky">
                    <tr>
                      <th data-uid="true" class="hide"></th>
                      <th></th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Last Access</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(user, index) in this.users" :key="index">
                      <td class="hide">1</td>
                      <td>
                        <Icon name="solid-user" :classes="['icon-primary', 'icon-round']" />
                      </td>
                      <td>{{ user.first_name + ' ' + (user.last_name == null ? '' : user.last_name) }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ this.$helper.toLocalDateTime(user.last_access) }}</td>
                      <td>
                        <div class="button-group">
                          <button @click="this.assignIndividual(user.id)" class="button button-primary">
                            <Icon name="solid-plus" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>

          <template v-slot:1>
            <div class="page-center-form">
              <AddMyIndividual />
            </div>
          </template>
        </ToggleButton>
      </div>
			<div class="margin-top-3">
        <router-link :to="this.$store.getters.dashboardURL" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
      </div>	
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  import ToggleButton from '@/components/Toggle/ToggleButton.vue';
  import InputSuggestion from '@/components/Form/InputSuggestion.vue';
  import AddMyIndividual from '@/components/Caregiver/AddMyIndividual.vue';

  export default {
    name: 'CaregiverAssign',
    components: { ToggleButton, InputSuggestion, Icon, AddMyIndividual },

    data() {
      return {
        users: [],
      };
    },


    methods: {
      handleUserSelection(data) {
        let alreadySelected = this.users.find((u) => u.id == data.id);
        if (alreadySelected !== undefined) return;
        this.users.push(data);
      },
      async assignIndividual(individualId) {
        let n = this.$helper.Notification.show({
          text: 'Processing, please wait...',
          classes: [],
          time: 0,
        });

        let response = await this.$store.dispatch('assignIndividual', individualId);

        this.$helper.Notification.hideAndShowDelayed(n.data.id, {
          text: response.data.msg,
          classes: [response.data.status],
        });

        if (response.data.status === 'success') this.users = [];
      },
    },

    mounted() {},
  };
</script>
