<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Make Healthy Drink Choices for your teeth</h1>
		<div class="d-flex justify-content-center">
			<img style="pointer-events:none;" class="margin-top-3 max-width-90" src="@/assets/pre-survey-19.jpg" alt="img">
		</div>
		<div class="margin-top-3">
				<router-link to="/resources/goal-4" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResourceGoal4',
  };
</script>
