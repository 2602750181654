<template>
  <div class="container | margin-top-2">
    <p class="section-heading-2">{{this.siteName}}</p>
    <p class="section-text section-text-1 | margin-top-1"><b>{{this.accessCode}}</b></p>
    <table id="page-table" class="data-table | margin-top-3">
      <thead class="sticky">
        <tr>
          <th data-uid="true" class="hide"></th>
          <th></th>
          <th>ID</th>
          <th>Name</th>
          <th>Type</th>
          <th>Last Access</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, userIndex) in this.users" :key="userIndex" class="cursor-pointer">
          <td>
            <Icon name="solid-user" :class="{ 'icon-round': true, 'icon-primary': user.added_by === null, 'icon-secondary': user.added_by !== null, 'icon-danger': user.role_title === 'caregiver' }" />
          </td>
          <td>{{ user.uid }}</td>
          <td>{{ user.first_name + ' ' + user.last_name }}</td>
          <td>
            <span :class="{ 'tag tag-danger-alt': user.role_title === 'caregiver' }">{{ user.role_title }}</span>
          </td>
          <td>{{ user.last_access }}</td>
        </tr>
      </tbody>
    </table>
    <div class="margin-top-3">
      <router-link to="/access-codes/manage" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  export default {
    name: 'AccessCode',
    components: { Icon },
    data() {
      return {
				siteName:'',
				accessCode:'',
        users: [],
      };
    },
    methods: {
     
			async fetchAccessCode(){
				let accessCodeId = this.$route.params.accessCodeId;
				let response = await this.$store.dispatch("accessCode", accessCodeId);
				if(response.data === null) return;

				this.users = response.data.users;
				this.siteName = response.data.site_name;
				this.accessCode = response.data.access_code;
			}
    },
    mounted() {
			this.fetchAccessCode();
    },
  };
</script>
