<template>
  <form @submit.prevent="this.registerUser" action="#">
    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">First Name: <span style="color:red;">*</span></label>
      <input v-model="this.user.firstName" name="first-name" type="text" class="input-style-1" required />
    </div>

    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">Last Name: <span style="color:red;">*</span></label>
      <input v-model="this.user.lastName" name="last-name" type="text" class="input-style-1" required />
    </div>

    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">Add to group:</label>
      <select v-model="this.user.group" class="input-style-1">
        <option value="">Select Group (Optional)</option>
        <option v-for="(group, groupIndex) in this.groups" :key="groupIndex" :value="group.id">{{ group.title }}</option>
      </select>
    </div>

    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">Add another caregiver:</label>
      <InputSuggestion @selectSuggestion="this.handleCaregiverSelect" placeholder="Search by ID or email address (optional)" for="user" role="caregiver" />
    </div>

    <div class="form-group" v-if="this.user.caregivers.length != 0">
      <p @click="removeCaregiver(caregiver.id)" v-for="(caregiver, caregiverIndex) in this.user.caregivers" :key="caregiverIndex" class="section-text-2 | cursor-pointer color-red-500">Delete: {{ caregiver.first_name + ' ' + (caregiver.last_name != null ? caregiver.last_name : '') }}</p>
    </div>

    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">Email address:</label>
      <input v-model="this.user.email" name="email" type="email" class="input-style-1" placeholder="Enter the individual’s email address (optional)" />
    </div>

    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">Password:</label>
      <input v-model="this.user.password" name="password" type="password" class="input-style-1" autocomplete="new-password" placeholder="Enter the individual’s password (optional)" />
    </div>

			<div>
			<p style="font-size:16px"><span style="color:red;">*</span> fields are required</p>
		</div>

    <div class="form-group | inline">
      <label class="label-style-1" style="min-width: 20rem">&nbsp;</label>
      <button class="button button-primary">Create</button>
    </div>
  </form>
</template>

<script>
  import InputSuggestion from '@/components/Form/InputSuggestion.vue';

  export default {
    name: 'AddMyIndividual',
    components: { InputSuggestion },
    data() {
      return {
        user: {
          firstName: '',
          lastName: '',
          group: '',
          caregivers: [],
          email: '',
          password: '',
        },
        groups: [],
      };
    },
    methods: {
      handleCaregiverSelect(data) {
        let user = this.user.caregivers.find((user) => user.id == data.id);
        if (user !== undefined) return;
        this.user.caregivers.push(data);
      },
      async fetchMyGroups() {
        let groups = await this.$store.dispatch('joinedGroups');
        this.groups = groups.data;
      },
      async registerUser() {
        let n = this.$helper.Notification.show({
          text: 'Creating user account, please wait...',
          time: 0,
        });

        let response = await this.$store.dispatch('registerCaregiverUser', this.user);

        this.$helper.Notification.hideAndShowDelayed(n.data.id, {
          text: response.data.msg,
          classes: [response.data.status],
        });

        if (response.data.status === 'success') {
					this.resetUser();
					this.$router.push(this.$store.getters.dashboardURL)
				}
      },
			removeCaregiver(caregiverId){
				this.user.caregivers = this.user.caregivers.filter(c => c.id != caregiverId);
			},
      resetUser() {
        this.user.firstName = '';
        this.user.lastName = '';
        this.user.group = '';
        this.user.caregivers = [];
        this.user.email = '';
        this.user.password = '';
      },
    },
    mounted() {
      this.fetchMyGroups();
    },
  };
</script>
