<template>
  <div class="margin-top-2">
    <div class="container">
      <h1 class="section-heading section-heading-2 color-primary-400">Resources</h1>

      <div class="margin-top-3">
        <ActCaptionCards active="all" resourceLinks="true" />
      </div>

      <div>
        <h2 class="section-heading section-heading-3">Videos</h2>

        <div class="resource-grids-2 | margin-top-3">
          <div class="grid">
            <h3 class="section-heading section-heading-4 color-secondary-400 text-align-center margin-bottom-2">The Surprising True Story of Cavities</h3>
            <div class="yt-video-container center">
              <iframe class="yt-video yt-video-size-1" src="https://www.youtube.com/embed/6NNJ891w0jg?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
            </div>
          </div>
          <div class="grid">
            <h3 class="section-heading section-heading-4 color-secondary-400 text-align-center margin-bottom-2">{{ this.$config.appName }} Toothbrushing Guide</h3>
            <div class="yt-video-container center">
              <iframe class="yt-video yt-video-size-1" src="https://www.youtube.com/embed/sovbqdm61tQ?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
            </div>
          </div>
        </div>

        <div class="resource-grids-2 | margin-top-5">
          <div class="grid">
            <h3 class="section-heading section-heading-4 color-secondary-400 text-align-center margin-bottom-2">Water is #1</h3>
            <div class="yt-video-container center">
              <iframe class="yt-video yt-video-size-1" src="https://www.youtube.com/embed/bG_rB67sqd4?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
            </div>
          </div>
          <div class="grid">
            <h3 class="section-heading section-heading-4 color-secondary-400 text-align-center margin-bottom-2">Make Healthy Food Choices</h3>
            <div class="yt-video-container center">
              <iframe class="yt-video yt-video-size-1" src="https://www.youtube.com/embed/mxGkqs7tAMI?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="margin-top-3">
        <router-link :to="this.$store.getters.dashboardURL" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import ActCaptionCards from '@/components/Act/ActCaptionCards.vue';

  export default {
    name: 'ResourcesPage',
    components: {
      ActCaptionCards,
    },
  };
</script>

<style></style>
