<template>
  <div class="margin-top-1">
    <div class="container">
      <div class="margin-bottom-3" style="min-height:60vh">
        <h2 class="section-heading section-heading-3 | margin-bottom-2">Add Group</h2>

        <ToggleButton :buttons="['Using Group ID', 'Create a New Group']">
          <template v-slot:0>
            <div>
              <InputSuggestion @selectSuggestion="this.handleSelectSuggestion" for="group" placeholder="Type group ID here" />
              <div class="data-table-container" :class="{ hide: this.groups.length === 0 }">
                <table id="page-table" class="data-table">
                  <thead class="sticky">
                    <tr>
                      <th data-uid="true" class="hide"></th>
                      <th></th>
                      <th>Group Name</th>
                      <th>Co-Managers</th>
                      <th>No. of Members</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(group, groupIndex) in this.groups" :key="groupIndex">
                      <td>
                        <Icon class="table-round-icon | clr-primary-600" name="solid-users" />
                      </td>
                      <td>{{ group.title }}</td>
                      <td>{{ names(group.categorizedUsers.caregiver) }}</td>
                      <td>{{ group.categorizedUsers.individual.length }}</td>
                      <td>{{ this.$helper.toLocalDateTime(group.create_datetime) }}</td>
                      <td>
                        <button @click="addUserToGroup(group.id)" class="button button-primary">
                          <Icon name="solid-plus" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>

          <template v-slot:1>
            <div><CreateGroup :childView="true" /></div>
          </template>
        </ToggleButton>
      </div>
			<div class="margin-top-3">
        <router-link :to="this.$store.getters.dashboardURL" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
      </div>	
    </div>
		
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  import ToggleButton from '@/components/Toggle/ToggleButton.vue';
  import InputSuggestion from '@/components/Form/InputSuggestion.vue';
  import CreateGroup from '@/components/Group/Create.vue';

  export default {
    name: 'AddGroup',
    components: { ToggleButton, InputSuggestion, CreateGroup, Icon },

    data() {
      return {
        groups: [],
      };
    },

    methods: {
      handleSelectSuggestion(data) {
        let users = data.group_users;

        let categorizedUsers = users.reduce(
          (acc, user) => {
            if (acc[user.user.role_title] === undefined) acc[user.user.role_title] = [];
            acc[user.user.role_title].push(user);
            return acc;
          },
          { caregiver: [], individual: [] }
        );

        data.categorizedUsers = categorizedUsers;

        this.groups.push(data);
      },
      async addUserToGroup(groupId) {
        let n = this.$helper.Notification.show({
          text: 'Assigning to group, please wait...',
          time: 0,
        });

        let response = await this.$store.dispatch('assignMeToGroup', groupId);

        this.$helper.Notification.hideAndShowDelayed(n.data.id, {
          text: response.data.msg,
          classes: [response.data.status],
        });

        if (response.data.status === 'success') this.groups = [];
      },
      names(users) {
        let n = users.map((u) => u.user.first_name + ' ' + (u.user.last_name !== null ? u.user.last_name : ''));
        return n.join(', ');
      },
    },

    mounted() {},
  };
</script>
