export default {

	/**
	 * ===== Server
	 */
	// appName: "WeSmile",
	// baseURL: "http://ec2-3-237-173-35.compute-1.amazonaws.com", //without trailing slash

	// serverBaseURL: "http://ec2-3-237-173-35.compute-1.amazonaws.com:3000",

	// build: {
	// 	dir: "/"
	// }

	/**
	 * ===== Test Server
	 */

	// appName: "WeSmile",
	// baseURL: "http://ec2-54-147-194-51.compute-1.amazonaws.com", //without trailing slash

	// serverBaseURL: "http://ec2-54-147-194-51.compute-1.amazonaws.com:3000",

	// build: {
	// 	dir: "/"
	// }


	/**
	 * ===== New Server
	 */

	appName: "WeSmile",
	baseURL: "https://wesmilehealth.org", //without trailing slash
	serverBaseURL: "https://wesmilehealth.org:3000",
	build: {
		dir: "/"
	}

	/**
	 * ====== Local
	 */

	// appName: "WeSmile",
	// baseURL: "http://localhost:8080", //without trailing slash

	// serverBaseURL: "http://localhost:3000",

	// build: {
	// 	dir: "/"
	// }

}