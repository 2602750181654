<template>
  <div class="wizard-bar | margin-top-3">
    <div class="wizard-bar-row">
      <div class="wizard-bar-step" :class="{'active': this.active == 1, 'complete' : this.active > 1}">
        <span class="radiomark"></span>
        <span class="text">Visit Your Dentist</span>
        <span class="gg-chevron-right"></span>
      </div>
      <div class="wizard-bar-step" :class="{'active': this.active == 2, 'complete' : this.active > 2}">
        <span class="radiomark"></span>
        <span class="text">Toothbrushing</span>
        <span class="gg-chevron-right"></span>
      </div>
      <div class="wizard-bar-step" :class="{'active': this.active == 3, 'complete' : this.active > 3}">
        <span class="radiomark"></span>
        <span class="text">Make Healthy Food Choices</span>
        <span class="gg-chevron-right"></span>
      </div>
      <div class="wizard-bar-step" :class="{'active': this.active == 4, 'complete' : this.active > 4}">
        <span class="radiomark"></span>
        <span class="text">Make Healthy Drink Choices</span>
        <span class="gg-chevron-right"></span>
      </div>
      <div class="wizard-bar-step" :class="{'active': this.active == 5, 'complete' : this.active > 5}">
        <span class="radiomark"></span>
        <span class="text">Thoughtful Eating</span>
        <span class="gg-chevron-right"></span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ActWizardBar',
		props:["active"]
  };
</script>
