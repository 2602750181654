<template>
  <div>
    <div class="container">
      <div class="breadcrumbs">
        <ul>
          <li><a href="#">Assess</a></li>
          <li><a href="#">Act</a></li>
          <li class="active"><a href="#">Follow up</a></li>
        </ul>
      </div>
    </div>
    <div>
      <div class="container">
        <WizardBar active="6" />

        <div v-if="this.pageNumber == 1">
          <h1 class="pre-survey-title | color-primary-400 text-align-center">Congratulations</h1>
          <div class="d-flex justify-content-center">
            <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
              <b>You completed all of the steps to learning about how to keep your mouth healthy by eating right, brushing your teeth and visiting the dentist.</b>
            </p>
          </div>

          <div>
            <CaptionCards complete="5" />
          </div>
        </div>
        <div v-if="this.pageNumber == 2">
          <h2 class="pre-survey-title | color-primary-400 text-align-center">Congratulations</h2>
          <div class="d-flex justify-content-center">
            <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
              <b>Keep up the good work! You can always revisit the program for reminders on how to keep your mouth healthy and cavity free!</b>
            </p>
          </div>

					<div class="text-align-center | margin-top-3">
						<router-link :to="this.$store.getters.dashboardURL" class="button button-primary min-width-button">Back to Dashboard</router-link>
					</div>
					
        </div>
        <div class="margin-top-2 | text-align-right" v-if="this.pageNumber < 2">
          <button @click="changePage()" class="button button-primary min-width-button | width-md-100">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import WizardBar from '@/components/Act/ActWizardBar.vue';
  import CaptionCards from '@/components/Act/ActCaptionCards.vue';

  export default {
    name: 'ActCompleted',
    components: {
      WizardBar,
      CaptionCards,
    },
    data() {
      return {
        pageNumber: 1,
      };
    },
    methods: {
      changePage() {
        this.pageNumber++;

        if (this.pageNumber === 3) {
          this.$emit('onComplete', null);
        }
      },
    },
  };
</script>
