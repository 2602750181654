<template>
	
  <div class="assessment assessment-border no-shadow">
    <div class="assessment-main width-70 | assessment-padding">
      <div class="assessment-heading-bar">
        <h2 class="assessment-heading">{{ this.question.title }}</h2>
      </div>

      <p v-if="this.question.answerType === 'multiple'" class="section-text section-text-1 | margin-top-1">(select all that apply)</p>

      <div class="assessment-answers inline-2 | margin-top-4">
        <div @click="this.chooseAnswer(answer)" v-for="(answer, index) in this.question.answers" :key="index" :class="{ 'assessment-answer': true, selected: answer.selected }">
          <Icon :class="{ square: this.question.answerType === 'multiple' }" :name="answer.selected === true ? 'solid-tick' : 'none'" />
          <span v-if="answer.weight !== 'other'">{{ answer.title }}</span>
          <input @input="this.handleOtherInput(answer)" @focus="this.handleOtherFocus(answer)" v-model="answer.title" v-if="answer.weight === 'other'" type="text" style="width: 100%; border: 2px dashed #ddd; padding: 10px" />
        </div>
      </div>
    </div>

    <div class="assessment-aside no-border" style="padding:0">
      <div style="height:100%">
				<img style="height:100%;max-height:60rem;object-fit:cover;" :src="this.question.image" alt="img">
			</div>
    </div>
  </div>
  
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  export default {
    name: 'PreSurveyQuestion',
    props: ['question'],
    components: { Icon },
    methods: {
      chooseAnswer(answer) {
        if (event && event.target.nodeName.toLowerCase() === 'input') return;

        if (answer.selected === undefined) answer.selected = false;
        answer.selected = !answer.selected;
      },
      handleOtherFocus(answer) {
        if (answer.title === 'Other') answer.title = '';
      },
      handleOtherInput(answer) {
        if (answer.title.toLowerCase() !== 'other' && answer.title.length > 0 && answer.selected !== true) {
          answer.selected = true;
        }
      },
    },
		mounted(){}
  };
</script>

<style></style>
