<template>
  <div class="container">
    <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
      <ul>
        <li><a href="#">Assess</a></li>
        <li class="active"><a href="#">Act</a></li>
        <li><a href="#">Follow up</a></li>
      </ul>
    </div>
    <div v-if="this.pageNumber == 1">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-3">
        <div>&nbsp;</div>
        <h2 class="pre-survey-title | color-secondary-400 text-align-center">Brush Your Teeth</h2>
        <div>&nbsp;</div>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <div class="d-flex justify-content-center">
        <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
          <b> Twice-a-day toothbrushing keeps your mouth clean and healthy. Watch this video to see the best way to brush your teeth. Then we can make a plan together to brush well.</b>
        </p>
      </div>

      <div class="margin-top-3">
        <div>
					<div class="yt-video-container center">
						<iframe class="yt-video yt-video-size-1" src="https://www.youtube.com/embed/sovbqdm61tQ?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
					</div>
        </div>
      </div>
    </div>
    <div v-if="this.pageNumber === 2">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-2">
        <h2 class="pre-survey-title | color-secondary-400">Week 1: Let’s work on toothbrushing</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <p class="section-heading-5 | light margin-top-1">
        <b>Now we need a plan to brush well. Here are three important things about brushing:</b>
      </p>
      <div class="d-flex justify-content-center">
        <div class="grids grids-2 | grids-break-lg-100 gap-4 margin-top-3" style="max-width: 90%">
          <div class="grid | d-flex align-items-center">
            <ul class="assessment-result-list secondary-400">
              <li>
                <div class="count">1</div>
                <div class="info">Make sure your toothpaste has flouride</div>
              </li>
              <li>
                <div class="count">2</div>
                <div class="info">Brush twice a day - every day</div>
              </li>
              <li>
                <div class="count">3</div>
                <div class="info">Be sure to brush all parts of all your teeth</div>
              </li>
            </ul>
          </div>
          <div class="grid">
            <img class="primary-border-image border-radius-10" src="@/assets/survey-img-10.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.pageNumber === 3">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your <span class="color-secondary-400">toothbrushing</span> goal:</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[0]" />
    </div>
    <div v-if="this.pageNumber === 4">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your <span class="color-secondary-400">toothbrushing</span> goal:</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[1]" />
    </div>
    <div v-if="this.pageNumber === 5">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your <span class="color-secondary-400">toothbrushing</span> goal:</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[2]" />
    </div>
    <div v-if="this.pageNumber === 6">
      <WizardBar active="2" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your <span class="color-secondary-400">toothbrushing</span> goal:</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>

      <div class="assessment-border">
        <div class="assessment-initial-grids">
          <div class="grid text-align-center">
						<div style="width: 100%" class="margin-top-3">
								<div class="yt-video-container center">
									<iframe class="yt-video yt-video-size-1"
										src="https://www.youtube.com/embed/aDcxM6142x0?rel=0&controls=0&iv_load_policy=3" frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
								</div>
						</div>
            <h2 class="section-heading-5 margin-top-2 margin-bottom-3" style="max-width:50rem">We will send you reminders by email to help you reach your <span class="color-secondary-400">toothbrushing</span> goal.</h2>
          </div>
          <div class="grid"><img src="@/assets/pre-survey-11.jpg" class="margin-left-auto-on-desktop margin-auto-on-mobile" alt="survey img" style="width: 200px" /></div>
        </div>
      </div>
    </div>
    <div class="margin-top-2 | text-align-right">
      <button @click="changePage()" class="button button-primary min-width-button | width-md-100">Next</button>
    </div>
  </div>
</template>

<script>
  // import Icon from '@/components/Icon/Icons.vue';
  import WizardBar from '@/components/Act/ActWizardBar.vue';
  import PreSurveyQuestion from '@/components/Act/PreSurveyQuestion.vue';

  import image1 from '@/assets/pre-survey-8.jpg';
  import image2 from '@/assets/pre-survey-9.jpg';
  import image3 from '@/assets/pre-survey-10.jpg';

  export default {
    name: 'ActGoal2',
    components: {
      
      WizardBar,
      PreSurveyQuestion,
    },
    data() {
      return {
        pageNumber: 1,
        questions: [
          {
            title: '1. Who can help you brush all your teeth twice a day with fluoride toothpaste?',
            answerType: 'multiple',
            image: image1,
            answers: [
              {
                id: 1,
                title: 'Me',
              },
              {
                id: 2,
                title: 'Family/Caregiver',
              },
              {
                id: 3,
                title: 'Direct Support Professional (DSP)',
              },
              {
                id: 4,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
          {
            title: '2. What might make it hard to reach your toothbrushing goal?',
            answerType: 'multiple',
            image: image2,
            answers: [
              {
                id: 1,
                title: "I don't like the feel or taste",
              },
              {
                id: 2,
                title: "I don't have brushing supplies",
              },
              {
                id: 3,
                title: 'I forget',
              },
              {
                id: 4,
                title: 'I will not find it hard',
              },
              {
                id: 5,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
          {
            title: '3. What can help you reach your toothbrushing goal?',
            answerType: 'multiple',
            image: image3,
            answers: [
              {
                id: 1,
                title: 'I can ask for help brushing',
              },
              {
                id: 2,
                title: 'I can use reminders',
              },
              {
                id: 3,
                title: 'I can brush anywhere I like',
              },
              {
                id: 4,
                title: 'I can get a toothbrush and toothpaste I like',
              },
              {
                id: 5,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
        ],
      };
    },

    methods: {
      changePage() {

				if(this.pageNumber === 3 && this.answerSelected(this.questions[0].answers) === false){
					this.$helper.Notification.show({
						text: "Please select your answer",
						classes: ['fail'],
					});
					return;
				} 

				if(this.pageNumber === 4 && this.answerSelected(this.questions[1].answers) === false){
					this.$helper.Notification.show({
						text: "Please select your answer",
						classes: ['fail'],
					});
					return;
				} 

				if(this.pageNumber === 5 && this.answerSelected(this.questions[2].answers) === false){
					this.$helper.Notification.show({
						text: "Please select your answer",
						classes: ['fail'],
					});
					return;
				}  

        this.pageNumber++;
				
        if (this.pageNumber === 7) {
          this.$emit('onComplete', this.questions);
        }
      },
			answerSelected(answers){
				return answers.reduce((acc, ans) => {
					if(ans.selected === true) acc = true;
					return acc;
				}, false)
			}
    },
  };
</script>
