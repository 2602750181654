<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Snacking</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-10.jpg" alt="img1" />
          <ul>
            <li>A few healthy snacks between meals can help us feel comfortable</li>
          </ul>
        </div>
      </div>
			<div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/resource-11.jpg" alt="img1" />
          <ul>
            <li>Always have healthy snacks available</li>
            <li>Make sure that snacks (and meals) have a beginning and end; don’t snack all day long!</li>
          </ul>
        </div>
      </div>
     
    </div>
    <div class="d-flex justify-content-center | margin-top-3">
      <div class="grid" style="max-width:97rem;width:100%;">
        <div class="pre-survey-vertical-section solid-border d-flex break-md-100 gap-3">
          <img src="@/assets/resource-12.jpg" alt="img1" style="max-width:30rem;" />
          <div>
						<h2 class="pre-survey-vertical-section-heading | color-secondary-400">Tips</h2>
						<ul>
							<li>Cavity causing snacks like candy, pretzels and chips may stick to your teeth</li>
							<li>Snacking all day, on cavity causing snacks, feeds the bad germs in your mouth</li>
						</ul>
					</div>
        </div>
      </div>
    </div>
		<div class="margin-top-3">
				<router-link to="/resources/goal-3" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResurcesGoal3b',
  };
</script>
