<template>
  <div class="container">
    <table id="page-table" class="data-table">
      <thead class="sticky">
        <tr>
          <th data-uid="true" class="hide"></th>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Status</th>
          <th>Registration Date</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, userIndex) in this.users" :key="userIndex" class="cursor-pointer">
          <td>{{ user.uid }}</td>
          <td>{{ user.first_name + ' ' + (user.last_name === null ? '' : user.last_name) }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.role_title }}</td>
          <td>{{ user.status }}</td>
          <td>{{ this.$helper.toLocalDateTime(user.create_datetime, true) }}</td>
          <td>
            <div class="button-group" :class="{'hide': user.role_title === 'admin'}">
              <button @click="deleteUser(user.id)" class="button button-danger">
                <Icon name="solid-trash" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="margin-top-3">
      <router-link :to="this.$store.getters.dashboardURL" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
    </div>
    <ConfirmationPopup />
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  import ConfirmationPopup from '@/components/Popup/Confirmation.vue';

  export default {
    name: 'ManageUser',
    components: { Icon, ConfirmationPopup },
    data() {
      return {
        users: [],
      };
    },
    methods: {
      async fetchUsers() {
        let response = await this.$store.dispatch('users');
        this.users = response.data;
      },
      async deleteUser(userId) {

				let user = this.users.find(u => u.id == userId);

				if(user.role_title === 'admin') return;

        let actionType = await this.$store.dispatch('showConfirmation', {
          id: userId,
        });
        if (actionType !== 'positive') return;

        let n = this.$helper.Notification.show({
          text: 'Deleting user, please wait...',
          classes: [],
          time: 0,
        });

        let response = await this.$store.dispatch('deleteUser', userId);

        this.$helper.Notification.hide(n.data.id);

        if (response.data.status === 'success') this.fetchUsers();

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });
      },
    },
    mounted() {
      this.fetchUsers();
    },
  };
</script>
