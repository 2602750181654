import axios from "axios";
import config from "@/config/index.js";

const goal = {
	state: {
		goals: []
	},

	mutations: {
		goals(state, goals) {
			state.goals = goals;
		}
	},

	actions: {
		async goals({ commit }) {
			let response = await axios.get(config.serverBaseURL + "/api/goals");
			if (response.data !== undefined && response.data.length > 0) commit('goals', response.data);
		}
	},

	getters: {
		goals(state) {
			return state.goals;
		},
		goal: (state) => (goalId) => {
			return state.goals.find(g => g.id === goalId);
		},
		progressName: (state, getters) => (goals) => {

			goals.sort((a, b) => a.goal_id > b.goal_id ? 1 : -1);
			if (goals.length <= 0) return '';

			goals = goals.filter(g => g.status == 'complete');

			let goalId = goals[goals.length - 1].goal_id;
			let goal = getters.goal(goalId + 1);
			if (goal === undefined) return 'Completed';
			return goal.title;
		},
	}
};

export default goal;