<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Visit Your Dentist</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/pre-survey-1.jpg" alt="img1" />
          <ul>
            <li>Dentists can help prevent mouth-problems and fix them if needed</li>
            <li>Your dental office welcomes you and helps you be comfortable</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/pre-survey-2.jpg" alt="img2" />
          <ul>
            <li>Visit your dentist at least twice a year or as recommended</li>
            <li>Don't wait to visit your dentist if you have mouth pain or sensitivity</li>
            <li>Between visits, eat healthy and brush your teeth</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Tips</h2>
          <img src="@/assets/pre-survey-3.jpg" alt="img3" />
          <ul>
            <li>Bring a comfort item with you</li>
            <li>Ask about what will happen during your visit</li>
            <li>Tell your office about any worries or concerns you have</li>
          </ul>
        </div>
      </div>
    </div>
			<div class="margin-top-3">
				<router-link to="/resources" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
		name:"ResurcesGoal1"
	};
</script>