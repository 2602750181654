<template>
  <div class="margin-top-2">
    <div class="container">
      <h1 class="section-heading section-heading-2 color-primary-400">About Us</h1>

      <div class="d-flex justify-content-center">
        <div style="max-width:85rem;">
          <div class="about-us-header">
            <div class="left">
              <img src="@/assets/logo.png" alt="logo" />
            </div>
            <div class="right">
              <h2 class="heading">Improving the Oral Health of People with Intellectual and Developmental Disabilities</h2>
            </div>
          </div>

          <p class="section-text section-text-1 color-neutral-600 | margin-top-5">
            <strong class="color-neutral-800">{{ this.$config.appName }} – Improving the Oral Health of People with Intellectual and Developmental Disabilities</strong> – is a web-based digital health platform that guides individuals and caregivers through the daily management of oral health.
          </p>

          <p class="section-text section-text-1 color-neutral-600 margin-top-2">
            Built on principals of motivational interviewing, individualization, and established theories of behavior-change, <strong class="color-neutral-800">{{ this.$config.appName }}</strong> provides
          </p>

          <ul class="section-list section-text section-text-1 | color-neutral-600 margin-top-2 margin-bottom-2">
            <li>education about oral health for individuals and their caregivers;</li>
            <li>assesses risk factors for preventable oral conditions;</li>
            <li>assists in setting behavioral goals and achieving them through tailored action plans; and provides support and reminders to help people reach their oral health goals.</li>
          </ul>
          <p class="section-text section-text-1 color-neutral-600 margin-top-2">{{ this.$config.appName }} answers the call by caregivers to deliver a highly-visual, engaging, and tailored approach to oral health promotion.</p>
          <p class="section-text section-text-1 color-neutral-600 margin-top-2">
            <strong class="color-neutral-800">{{ this.$config.appName }}</strong> was developed with support of Delta Dental of New York and the University of Buffalo Research Foundation by a team of oral health, behavior, nutrition, public health, and educational technology experts to meet the needs of people with ranging abilities. WeSmile’s technology, training materials, and support services were developed and vetted with experts in care and support of adults with a range of disabilities as well as individuals themselves.
          </p>

       

          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AboutPage',
  };
</script>

<style></style>
