<template>
  <div v-if="this.$store.getters.confirmationPopup.length > 0">
		<div :class="{popup:true, 'confirm-popup':true}" v-for="(popup, index) in this.$store.getters.confirmationPopup" :key="index" >

			<h2 class="popup-title">{{ popup.title !== undefined ? popup.title : 'Confirm deletion' }}</h2>
			<p class="popup-description | margin-top-3">{{ popup.description !== undefined ? popup.description : 'Are you sure you want to delete this? This action can not be undone.' }}</p>
			
			<div class="button-group | margin-top-3" v-if="popup.buttons === undefined || popup.buttons === true">
				<button class="button" @click="this.$store.dispatch('confirmationAction', {id: popup.id, type:'negative'})">{{ popup.negativeButton !== undefined ? popup.negativeButton.text : 'Cancel' }}</button>
				<button :class="{button:true, [this.resolveClasses(popup.positiveButton !== undefined ? popup.positiveButton.classes : null)]:true }" @click="this.$store.dispatch('confirmationAction',{id: popup.id, type:'positive'})">{{ popup.positiveButton !== undefined ? popup.positiveButton.text : 'Delete' }}</button>
			</div>

		</div>
	</div>
</template>

<script>
  export default {
		name:"ConfirmationPopup",

		/**
		 * ===== Methods
		 */

		methods: {
			resolveClasses(arr){
				if(arr !== undefined && arr !== null) return arr.join(' ');
				else return 'button-danger'
			}
		},

		mounted(){
			
		}
	};
</script>
