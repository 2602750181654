<template>
  <div class="margin-top-2">
    <div class="container">
      <h1 class="section-heading section-heading-3 color-primary-400"><span class="color-neutral-600">Resources >></span> Make Water Your Go-to Drink</h1>

      <div class="d-flex justify-content-center">
        <div class="grids grids-2 | grids-break-lg-100 gap-5 margin-top-3" style="max-width: 80%">
          <div class="grid | d-flex align-items-center">
            <ul class="assessment-result-list secondary-400">
              <li>
                <router-link to="/resources/goal-4a" class="info | no-padding-on-mobile">Make Healthy Drink Choices for your teeth</router-link>
              </li>
              <li>
                <router-link to="/resources/goal-4b" class="info | no-padding-on-mobile">Healthy Drinks</router-link>
              </li>
              <li>
                <router-link to="/resources/goal-4c" class="info | no-padding-on-mobile">Limiting Sugary Drinks</router-link>
              </li>
							<li>
                <router-link to="/resources/goal-4d" class="info | no-padding-on-mobile">What to Drink</router-link>
              </li>
            </ul>
          </div>
          <div class="grid"><img class="secondary-border-image border-radius-10" src="@/assets/resource-20.jpg" alt="img" /></div>
        </div>
      </div>

			<div class="margin-top-3">
				<router-link to="/resources" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'ResourceGoal4',
  };
</script>
