/* eslint-disable no-unused-vars */
const KEY = "123456";
const cipher = (salt) => {
    const textToChars = (text) => text.split('').map(c => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return (text) => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
};
const decipher = (salt) => {
    const textToChars = (text) => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) => encoded.match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join('');
};
let encrypter = cipher(KEY);
let decrypter = decipher(KEY);
function encrypt(text) {
    let encodedText = encodeURIComponent(text);
    return encrypter(encodedText);
}
function decrypt(secret) {
    let str = decrypter(secret);
    return decodeURIComponent(str);
}
export default {
    encrypt,
    decrypt
};
/* eslint-enable no-unused-vars */
