<template>
  <div class="container">
    <table id="page-table" class="data-table">
      <thead class="sticky">
        <tr>
          <th data-uid="true" class="hide"></th>
          <th></th>
          <th>Site Name</th>
          <th>Access Code</th>
          <th>Date Created</th>
          <th>No. of Users</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(accessCode, accessCodeIndex) in this.accessCodes" :key="accessCodeIndex" class="cursor-pointer">
          <td @click="this.openAccessCode(accessCode.id)">
            <Icon name="solid-location-pin" style="width: 2.6rem; height: 2.6rem; fill: var(--clr-neutral-800)" />
          </td>
          <td @click="this.openAccessCode(accessCode.id)">{{ accessCode.site_name }}</td>
          <td @click="this.openAccessCode(accessCode.id)">{{ accessCode.access_code }}</td>
          <td @click="this.openAccessCode(accessCode.id)">{{ this.$helper.toLocalDateTime(accessCode.create_datetime, true) }}</td>
          <td @click="this.openAccessCode(accessCode.id)" v-html="this.userCountStr(accessCode.users)"></td>
          <td>
            <div class="button-group">
              <button @click="this.deleteAccessCode(accessCode.id)" class="button button-danger">
                <Icon name="solid-trash" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="generate-access-code-container | margin-top-5">
      <div>
        <div class="generate-access-code-heading-container">
					<p class="section-heading-5 | text-align-center light" style="max-width:45rem"><b>Please enter the site name to generate access code:</b></p>
				</div>
        <form class="generate-access-code-form | margin-top-3">
          <div>
            <label class="label-style-1" style="margin-bottom: 0">Site Name:</label>
          </div>
          <div>
            <input v-model="this.siteName" type="text" class="input-style-1" />
          </div>
          <div>
            <label class="label-style-1 required" style="color: red; margin-bottom: 0; font-size: 21px">*</label>
          </div>
        </form>
        <div class="generate-access-code-button-container | margin-top-3">
          <button @click="this.generateAccessCode()" class="button button-block button-primary" >Generate Access Code</button>
        </div>
      </div>
    </div>

		<div class="margin-top-3">
      <router-link :to="this.$store.getters.dashboardURL" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
    </div>

    <ConfirmationPopup />
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  import ConfirmationPopup from '@/components/Popup/Confirmation.vue';

  export default {
    name: 'ManageAccessCode',
    components: { Icon, ConfirmationPopup },
    data() {
      return {
				siteName: '',
        accessCodes: [],
      };
    },
    methods: {
      async fetchAccessCodes() {
        let response = await this.$store.dispatch('fetchAccessCodes');
        this.accessCodes = response.data;
      },
      async deleteAccessCode(accessCodeId) {
        let actionType = await this.$store.dispatch('showConfirmation', {
          id: accessCodeId,
        });

        if (actionType !== 'positive') return;

        let n = this.$helper.Notification.show({
          text: 'Deleting access code, please wait...',
          classes: [],
          time: 0,
        });

        let response = await this.$store.dispatch('deleteAccessCode', accessCodeId);

        this.$helper.Notification.hide(n.data.id);

        if (response.data.status === 'success') this.fetchAccessCodes();

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });
      },
			async generateAccessCode(){

				let n = this.$helper.Notification.show({
          text: 'Generating access code, please wait...',
          classes: [],
          time: 0,
        });

				let response = await this.$store.dispatch("generateAccessCode", this.siteName);

				this.$helper.Notification.hide(n.data.id);

        if (response.data.status === 'success') {
					this.siteName = '';
					this.fetchAccessCodes();
				}

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });

			},
			openAccessCode(accessCodeId){
				this.$router.push('/access-codes/code/' + accessCodeId);
			},
      countUsersByRole(users) {
        let group = users.reduce((acc, user) => {
          let userRole = user.role_title;
          if (acc[userRole] === undefined) acc[userRole] = 0;
          acc[userRole]++;
          return acc;
        }, {individual:0, caregiver:0});
        return group;
      },
      userCountStr(users) {
        let str = ``;

        let obj = this.countUsersByRole(users);
        for (let key in obj) {
          str += `<p style="margin-bottom:10px;">${obj[key]} ${key}${obj[key] == 1 ? '' : 's'}</p>`;
        }
			
        return str;
      },
    },
    mounted() {
      this.fetchAccessCodes();
    },
  };
</script>
