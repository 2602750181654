<template>
  <div class="account-section margin-top-1">
    <div class="container">
      <div class="">
        <h1 class="section-heading section-heading-2 color-primary-400">
          Complete your profile
        </h1>

        <p
          v-if="this.$store.getters.roleTitle === 'caregiver'"
          class="section-text section-text-1 fw-500 | margin-top-2"
        >
          Sign up to help others
        </p>

        <p class="section-text section-text-1 fw-500 | margin-top-2">
          #ID {{ this.$store.getters.user.uid }}
        </p>

        <div class="d-flex justify-content-center">
          <!-- For Individual -->
          <form
            @submit.prevent="this.completeProfile"
            v-if="this.$store.getters.roleTitle === 'individual'"
            id="profile-form"
            action="#"
            class="margin-top-3"
          >
            <div class="form-group">
              <label class="label-style-2">Full Name</label>
              <input
                name="first-name"
                type="text"
                class="input-style-1"
                placeholder="First Name"
                v-model="this.profile.firstName"
              />
            </div>
            <div class="form-group">
              <input
                name="last-name"
                type="text"
                class="input-style-1"
                placeholder="Last Name"
                v-model="this.profile.lastName"
              />
            </div>

            <div class="form-group">
              <label class="label-style-2">Associated Caregiver</label>
              <select
                @change="this.caregiverChange"
                name="caregiver"
                class="input-style-1"
                v-model="this.profile.caregiver"
              >
                <option value="-1" selected>Please Choose...</option>
                <option
                  v-for="(caregiver, index) in this.caregivers"
                  :key="index"
                  :value="caregiver.id"
                >
                  {{ caregiver.first_name + " " + caregiver.last_name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <input
                @input="this.manualCaregiverChange"
                @change="this.manualCaregiverChange"
                name="manual-caregiver"
                type="text"
                class="input-style-1"
                placeholder="Or type manually here"
                v-model="this.profile.manualCaregiver"
              />
            </div>

            <!-- <div class="form-group">
              <label class="label-style-2">Date of Birth</label>
              <input
                name="dob"
                type="date"
                class="input-style-1"
                v-model="this.profile.dob"
              />
            </div> -->

            <!-- <div class="form-group">
              <label class="label-style-2">Phone Number (Optional)</label>
              <input
                name="phone"
                type="text"
                class="input-style-1"
                placeholder="(+1) xxx-xxx-xxxx"
                v-model="this.profile.phone"
              />
            </div> -->

            <div class="form-group margin-top-4">
              <button class="button button-primary button-block">
                Complete
              </button>
            </div>
          </form>

          <!-- For Caregiver -->
          <form
            @submit.prevent="this.completeProfile"
            v-else
            id="profile-form"
            action="#"
            class="margin-top-3"
          >
            <div class="form-group">
              <label class="label-style-2">Full Name</label>
              <input
                name="first-name"
                type="text"
                class="input-style-1"
                placeholder="First Name"
                v-model="this.profile.firstName"
              />
            </div>
            <div class="form-group">
              <input
                name="last-name"
                type="text"
                class="input-style-1"
                placeholder="Last Name"
                v-model="this.profile.lastName"
              />
            </div>

            <div class="form-group">
              <label class="label-style-2">Your Role</label>
              <select
                name="designation"
                class="input-style-1"
                v-model="this.profile.designation"
              >
                <option
                  v-for="(designation, index) in this.designations"
                  :key="index"
                  :value="designation.title"
                >
                  {{ designation.title }}
                </option>
              </select>
            </div>

            <!-- <div class="form-group">
              <label class="label-style-2">Phone Number (Optional)</label>
              <input
                name="phone"
                type="text"
                class="input-style-1"
                placeholder="(+1) xxx-xxx-xxxx"
                v-model="this.profile.phone"
              />
            </div> -->

            <div class="form-group margin-top-4">
              <button class="button button-primary button-block">
                Complete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompleteProfile",
  data() {
    return {
      profile: {
        firstName: "",
        lastName: "",
        caregiver: "-1",
        manualCaregiver: "",
        dob: "",
        phone: "",
        designation: "",
      },
      caregivers: [],
      designations: [],
    };
  },

  /**
   * ===== Methods
   */

  methods: {
    async fetchCaregivers() {
      let c = await this.$store.dispatch("caregivers");
      this.caregivers = c.data;
    },

    async fetchDesignations() {
      let d = await this.$store.dispatch("designations");
      this.designations = d.data;
      if (this.designations.length > 0)
        this.profile.designation = this.designations[0].title;
    },

    caregiverChange() {
      this.profile.manualCaregiver = "";
    },

    manualCaregiverChange() {
      this.profile.caregiver = "-1";
    },

    async completeProfile() {
      let n = this.$helper.Notification.show({
        text: "Processing, please wait...",
        classes: [],
        time: 0,
      });

      let response = await this.$store.dispatch(
        "completeProfile",
        this.profile
      );

      this.$helper.Notification.hide(n.data.id);

      this.$helper.Notification.show({
        text: response.data.msg,
        classes: [response.data.status],
      });

      if (
        response.data.status === "success" &&
        this.$store.getters.user.roleTitle !== "individual"
      )
        this.$router.push(this.$store.getters.dashboardURL);
      else if (
        response.data.status === "success" &&
        this.$store.getters.user.roleTitle === "individual"
      )
        this.$router.push("/assessment/initial");
    },
  },

  /**
   * ===== Hooks
   */

  mounted() {
    if (this.$store.getters.profileComplete == true)
      this.$router.push(this.$store.getters.dashboardURL);
    else if (this.$store.getters.roleTitle === "individual")
      this.fetchCaregivers();
    else if (this.$store.getters.roleTitle === "caregiver")
      this.fetchDesignations();
  },
};
</script>
