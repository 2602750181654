<template>
  <div class="margin-top-1">
    <div class="container">
      <div class="margin-bottom-5">
        <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
          <ul>
            <li>
              <router-link :to="this.$store.getters.dashboardURL">Dashboard</router-link>
            </li>
            <li class="active">
              <router-link to="#">Group: {{ this.group.title }}</router-link>
            </li>
          </ul>
          <p class="page-user-id">ID# {{ this.group.uid }}</p>
        </div>

        <div class="data-table-container | margin-top-3">
          <div class="data-table-toolbar sticky">
            <!-- Hidden Table Search -->
            <div class="data-table-toolbar-section search-section hide">
              <input type="text" class="search input-style-1" />
              <svg class="icon search-icon">
                <use xlink:href="@/assets/icons.svg#search" />
              </svg>
              <svg class="icon cross-icon">
                <use xlink:href="@/assets/icons.svg#cross" />
              </svg>
            </div>

            <!-- Table Toolbar -->
            <div class="data-table-toolbar-section right">
              <div class="data-table-toolbar-section-option d-flex align-items-center gap-1">
                <router-link :to="'/group/edit/' + this.$route.params.id" class="button button-primary">
                  <svg class="icon">
                    <use xlink:href="@/assets/icons.svg#solid-edit" />
                  </svg>
                  Edit Group
                </router-link>
              </div>
            </div>
          </div>

          <table id="group-table" class="data-table">
            <thead class="sticky">
              <tr>
                <th data-uid="true" class="hide"></th>
                <th></th>
                <th>Name</th>
                <th>Progress</th>
                <th>Last Access</th>
              </tr>
            </thead>

            <tbody>
              <tr @click="handleUserClick(groupUser.user)" v-for="(groupUser, index) in this.group.group_users" :key="index" class="cursor-pointer">
                <td class="hide"></td>
                <td class="">
                  <Icon name="solid-user" :classes="['icon-round', groupUser.user.role_title == 'individual' ? 'icon-primary' : 'icon-danger']" />
                </td>
                <td>{{ groupUser.user.first_name + ' ' + groupUser.user.last_name }}</td>
                <td>
                  <span class="tag tag-secondary" v-if="groupUser.user.role_title === 'individual' && groupUser.user.action_surveys[0]">{{ this.$store.getters.progressName(groupUser.user.action_surveys[0].action_survey_goals) }}</span>
                  <span class="tag tag-secondary" v-else-if="groupUser.user.role_title === 'individual'">Not Started</span>
                  <span class="tag tag-secondary" v-else>Caregiver</span>
                </td>
                <td class="">{{ groupUser.user.last_access }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="margin-top-3">
        <router-link :to="this.$store.getters.dashboardURL" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';

  export default {
    name: 'GroupShow',
    components: { Icon },
    data() {
      return {
        group: {},
      };
    },

    /**
     * ===== Methods
     */

    methods: {
      async fetchGroup() {
        let groupId = this.$route.params.id;
        let g = await this.$store.dispatch('group', groupId);
        this.group = g.data;

        this.$helper.DOM.setPageMeta({
          tabTitle: this.group.title + ' Group | ' + this.$config.appName,
        });

        return this.group;
      },
      handleUserClick(user) {
        if (user.role_title === 'individual') this.$router.push(`/individual/dashboard/${user.id}`);
      },
    },

    /**
     * ===== Mounted
     */

    mounted() {
      this.fetchGroup();
    },
  };
</script>
