import axios from "axios";
import config from "@/config/index.js";

const assessment = {

	state: {},

	mutations: {},

	actions: {

		async saveAnswer(action, payload) {
			return await axios.post(config.serverBaseURL + "/api/assessments", payload);
		},

		async myAssessment() {
			return await axios.get(config.serverBaseURL + "/api/assessments/my");
		},

		async userAssessment(action, userId) {
			return await axios.get(config.serverBaseURL + "/api/assessments/user/" + userId);
		},

		async completeMyAssessment(action, payload) {
			return await axios.post(config.serverBaseURL + "/api/assessments/my/complete", payload);
		}
	},

	getters: {}

};

export default assessment;