<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Thoughtful Eating 1</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-10.jpg" alt="img1" />
					<ul>
            <li>Thoughtful eating starts with making mealtime time to eat.</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/pre-survey-25.jpg" alt="img1" />
          <ul>
            <li>Sit down at the table to eat.</li>
            <li>Avoid doing other things at mealtime. Try to put screens away like phones or sit away from the television when you eat.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center | margin-top-3">
      <div class="grid" style="max-width:97rem;width:100%;">
        <div class="pre-survey-vertical-section solid-border d-flex break-md-100 gap-3 ">
          <img src="@/assets/pre-survey-24.jpg" alt="img1" style="max-width: 30rem" />
          <div>
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400" style="text-align:left;padding-left:2rem;">Tips</h2>
            <ul>
              <li>Eat regular meals three times a day: breakfast, lunch and dinner and use mealtime to think about what are you eating and how foods make you healthy.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
		<div class="margin-top-3">
				<router-link to="/resources/goal-5" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResourceGoal5b',
  };
</script>
