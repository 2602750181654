import axios from "axios";
import config from "@/config/index.js";
// import helper from "@/helpers/index.js";
// import router from "@/routes/index.js";

const caregiver = {

	state: {},

	mutations: {},

	actions: {

		async caregivers() {
			return await axios.get(config.serverBaseURL + "/api/caregivers");
		},

		async assignCaregiver(action, payload) {
			return await axios.post(config.serverBaseURL + '/api/caregivers/assign', payload);
		},

		async disassociateCaregiver(action, id) {
			return await axios.delete(config.serverBaseURL + '/api/caregivers/assign/' + id);
		},

		async registerCaregiverUser(action, payload) {
			return axios.post(config.serverBaseURL + '/api/caregivers/register/user', payload);
		}
	},

	getters: {}

};

export default caregiver;