import axios from "axios";
import config from "@/config/index.js";

const actionSurvey = {

	state: {},

	mutations: {},

	actions: {

		async saveActionSurveyGoal(action, payload = {}) {
			return await axios.post(config.serverBaseURL + "/api/action-surveys/goals/save", payload);
		},

		async updateActionSurveyStatus(action, status) {
			return await axios.put(config.serverBaseURL + "/api/action-surveys/update/status", { status: status })
		},

		async myActionSurveys() {
			return await axios.get(config.serverBaseURL + "/api/action-surveys/my");
		},

		async userActionSurveys(action, userId) {
			return await axios.get(config.serverBaseURL + "/api/action-surveys/user/" + userId);
		},

		async saveActionSurveyAnswers(action, payload) {
			return await axios.post(config.serverBaseURL + "/api/action-surveys/answers/save", payload);
		},

		async saveActionPostSurvey(action, payload) {
			return await axios.post(config.serverBaseURL + "/api/action-surveys/post-surveys/save", payload);
		}

	},

	getters: {}

};

export default actionSurvey;