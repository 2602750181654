<template>
  <div class="container">
		
		<!-- Breadcrumbs -->
        <div class="breadcrumbs | d-flex align-items-center justify-content-space-between margin-bottom-3">
          <ul>
            <li class="active"><a href="#">Assess</a></li>
            <li><a href="#">Act</a></li>
            <li><a href="#">Follow up</a></li>
          </ul>
					<!-- <div class="assessment-audio" @click="this.audio">
            <Icon name="solid-volume" :classes="['fill-secondary-400']" />
            <span>Listen to text</span>
          </div> -->
        </div>
    <div class="assessment-container">
      <!-- Progress Bar -->
      <div class="assessment-bar | margin-top-1">
        <div
          v-for="(p, index) in this.progress.result"
          :key="index"
          :class="{ 'assessment-bar-category': true, active: p.active }"
          :style="{
            '--width': (p.totalQuestions / this.progress.totalQuestions) * 100 + '%',
            '--complete': (p.answeredQuestions / p.totalQuestions) * 100 + '%',
          }"
        ></div>
      </div>
      <div class="assessment-bar-name">
        <div
          v-for="(p, index) in this.progress.result"
          :key="index"
          :style="{ '--width': (p.totalQuestions / this.progress.totalQuestions) * 100 + '%' }"
        >
          <p>{{ p.category }}</p>
        </div>
      </div>

      <!-- Question -->
      <div class="assessment assessment-border-padding | margin-top-2">
        <div class="assessment-main" :class="{'d-flex flex-direction-column justify-content-center text-align-center': (this.currentQuestion.answers !== undefined && this.currentQuestion.answers.length == 0 )}">
          <div class="assessment-heading-bar">
            <h2 class="assessment-heading">{{ this.currentQuestion.title }} <Icon v-if="this.currentQuestion.help" name="solid-info" @click="this.showInfo" /></h2>
						
          </div>
					<button v-if="this.currentQuestion.id == 0" @click="this.showQuestion(this.currentQuestionNumber + 1, 'next')" class="button button-primary no-shadow margin-top-3">Let's Get Started</button>
					<p v-if="this.currentQuestion.answer_type === 'multiple'" class="section-text section-text-1 | margin-top-1">(select all that apply)</p>
					
          <div class="assessment-answers | margin-top-4">
            <div
              @click="this.chooseAnswer(index)"
              v-for="(answer, index) in this.currentQuestion.answers"
              :key="index"
              :class="{ 'assessment-answer': true, selected: answer.selected }"
            >
              <Icon :class="{square: this.currentQuestion.answer_type === 'multiple'}" :name="answer.selected === true ? 'solid-tick' : 'none'" />
              <span v-if="answer.weight !== 'other'">{{ answer.title }}</span>
							<input @input="this.postAnswer(index)" @focus="this.postAnswer(index)" v-model="answer.title" v-if="answer.weight === 'other'" type="text" style="width:100%;border:2px dashed #ddd;padding:10px;">
            </div>
						
          </div>
        </div>

        <div class="assessment-aside" :class="{'no-border': this.currentQuestion.id == 0}" v-if="this.currentQuestion.image">
          <div :class="{ frame: true, [this.currentQuestion.color]: true }">
            <img class="png-icon" :src="this.frameIcon(this.currentQuestion.icon)" />
            <img v-if="this.currentQuestion.id !== 0" class="img" :src="this.currentQuestion.image" alt="survey img" />
            <img v-else  :src="this.currentQuestion.image" alt="survey img" style="width:200px;margin-left:auto;" />
          </div>
        </div>
      </div>

      <div class="assessment-footer | margin-top-3">
        <div>
          <button v-if="this.currentQuestion.id !== 1" @click="this.showQuestion(this.currentQuestionNumber - 1, 'previous')" class="button no-shadow">Back</button>
        </div>
        <div>
          <div class="button-group">
            <button @click="this.saveAndExit" class="button button-primary-ghost no-shadow">Save & Exit</button>
            <button @click="this.showQuestion(this.currentQuestionNumber + 1, 'next')" class="button button-primary no-shadow">Next</button>
          </div>
        </div>
      </div>
    </div>

    <ConfirmationPopup />
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  import ConfirmationPopup from '@/components/Popup/Confirmation.vue';

  import AppleIcon from '@/assets/apple-icon.png';
  import ClockIcon from '@/assets/clock-icon.png';
  import CookieIcon from '@/assets/cookie-icon.png';
  import DrinkIcon from '@/assets/drink-icon.png';
  import SodaIcon from '@/assets/soda-icon.png';
  import SurveyImage1 from '@/assets/survey-img-1.jpg';
  import SurveyImage2 from '@/assets/survey-img-2.jpg';
  import SurveyImage3 from '@/assets/survey-img-3.jpg';
  import SurveyImage4 from '@/assets/survey-img-4.jpg';
  import SurveyImage5 from '@/assets/survey-img-5.jpg';
  import SurveyImage6 from '@/assets/survey-img-6.jpg';
  import SurveyImage7 from '@/assets/survey-img-7.jpg';
  import SurveyImage8 from '@/assets/survey-img-8.jpg';
  import SurveyImage9 from '@/assets/survey-img-9.jpg';
  import SurveyImage10 from '@/assets/survey-img-10.jpg';
  import SurveyImage11 from '@/assets/survey-img-11.jpg';
  import SurveyImage12 from '@/assets/survey-img-12.jpg';
  import SurveyImage13 from '@/assets/survey-img-13.jpg';
  import SurveyImage14 from '@/assets/survey-img-14.jpg';
  import SurveyImage15 from '@/assets/survey-img-15.jpg';
  import SurveyImageDryMouth from '@/assets/survey-img-dry-mouth.jpg';
  import SurveyImageMouthFresh from '@/assets/survey-img-mouth-fresh.jpg';
  import SurveyImageBrushStart from '@/assets/survey-img-brush-start.jpg';
  import SurveyImageBrushed from '@/assets/survey-img-brushed.jpg';
  import SurveyImageToothSides from '@/assets/survey-img-tooth-sides.jpg';
  import SurveyImageDentist from '@/assets/survey-img-dentist.jpg';
  import SurveyImageSupplies from '@/assets/survey-image-supplies.jpg';


  export default {
    name: 'AssessmentSurvey',
    components: { Icon, ConfirmationPopup },
    data() {
      return {
        currentQuestionNumber: 1,
        currentQuestion: {},
        answers: [],
        frameIcons: {
          apple: AppleIcon,
          clock: ClockIcon,
          cookie: CookieIcon,
          drink: DrinkIcon,
          soda: SodaIcon,
        },
        survey: [
          {
            id: 1,
            title: 'Assessment 1',
            categories: [
              {
                title: 'Dietary Habits',
                questions: [
                  {
                    id: 1,
                    title: 'Here are some pictures of foods, how many times a day do you eat any of these types of foods?',
                    answer_type: 'single',
                    goal: 'Choose Healthy Foods',
                    help: '',
                    items:
                      'Green: Raw, crunchy vegetables; Raw, leafy vegetables; Apples or other crunchy fruits; Cheese, Nuts, Eggs, Unsweetened Yogurt, Low fat Meat (turkey)',
                    image: SurveyImage1,
                    
                    audio: 'audio.mp3',
                    color: 'ocean-green',
                    prerequisite: false,
                    risk: [
                      {
                        id: 1,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Never or rarely',
                        weight: 'never',
                      },
                      {
                        id: 2,
                        title: ' 1-2 times a day',
                        weight: '1-2',
                      },
                      {
                        id: 3,
                        title: '3 or more times a day',
                        weight: '>=3',
                      }
                    ],
                  },
                  {
                    id: 2,
                    title: 'When do you usually eat these types of foods?',
                    answer_type: 'single',
                    goal: false,
                    help: '',
                    items:
                      'Green: Raw, crunchy vegetables; Raw, leafy vegetables; Apples or other crunchy fruits; Cheese, Nuts, Eggs, Unsweetened Yogurt, Low fat Meat (turkey)',
                    image: SurveyImage1,
                    
                    audio: 'audio.mp3',
                    color: 'ocean-green',
                    prerequisite: {
                      questionNumber: 1,
                      weight: 'never',
                      notEqual: true,
                    },
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Only at meal times',
                        weight: 'only-at-meal-times',
                      },
                      {
                        id: 2,
                        title: 'Only as between meal snacks',
                        weight: 'only-as-between-meal-snacks',
                      },
                      {
                        id: 3,
                        title: 'Both at meal times and for snacks',
                        weight: 'both-at-meal-times-and-for-snacks',
                      },
                    ],
                  },
                  {
                    id: 3,
                    title: "Why don't you eat these types of foods?",
                    answer_type: 'multiple',
                    goal: false,
                    help: '',
                    items:
                      'Green: Raw, crunchy vegetables; Raw, leafy vegetables; Apples or other crunchy fruits; Cheese, Nuts, Eggs, Unsweetened Yogurt, Low fat Meat (turkey)',
                    image: SurveyImage1,
                   
                    audio: 'audio.mp3',
                    color: 'ocean-green',
                    prerequisite: {
                      questionNumber: 1,
                      weight: 'never',
                    },
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: "I don't like how they feel in my mouth",
                        weight: 'i-dont-like-how-they-feel-in-my-mouth',
                      },
                      {
                        id: 2,
                        title: "I don't like how they taste",
                        weight: 'i-dont-like-how-they-taste',
                      },
                      {
                        id: 3,
                        title: 'They are hard for me to chew',
                        weight: 'they-are-hard-for-me-to-chew',
                      },
                      {
                        id: 4,
                        title: 'They cost too much',
                        weight: 'they-cost-too-much',
                      },
											{
                        id: 5,
                        title: 'They aren\'t available where I live',
                        weight: 'they-arent-available-where-i-live',
                      },
                      {
                        id: 6,
                        title: 'Other',
                        weight: 'other',
                      },
                    ],
                  },
                  {
                    id: 4,
                    title: 'Here are some pictures of foods, how many times a day do you eat any of these types of foods?',
                    answer_type: 'single',
                    goal: 'Choose Healthy Foods',
                    help: '',
                    items: 'Red: Candies, cookies/brownies, crackers, pretzels, chips, fruit snacks, sweetened cereal',
                    image: SurveyImage2,
                    audio: 'audio.mp3',
                    color: 'sunset-orange',
                    prerequisite: false,
                    risk: [
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Never or rarely',
                        weight: 'never',
                      },
                      {
                        id: 2,
                        title: '1-2 times a day',
                        weight: '1-2',
                      },
                      {
                        id: 3,
                        title: '3 or more times a day',
                        weight: '>=3',
                      },
                    ],
                  },
                  {
                    id: 5,
                    title: 'When do you usually eat them?',
                    answer_type: 'single',
                    goal: 'Choose Healthy Foods',
                    help: '',
                    items: 'Red: Candies, cookies/brownies, crackers, pretzels, chips, fruit snacks, sweetened cereal',
                    image: SurveyImage2,
                   
                    audio: 'audio.mp3',
                    color: 'sunset-orange',
                    prerequisite: {
                      questionNumber: 4,
                      weight: 'never',
                      notEqual: true,
                    },
                    risk: [
                      {
                        id: 2,
                        value: 'high',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Only at meal times',
                        weight: 'only-at-meal-times',
                      },
                      {
                        id: 2,
                        title: 'Only as between meal snacks',
                        weight: 'only-as-between-meal-snacks',
                      },
                      {
                        id: 3,
                        title: 'Both at meal times and for snacks',
                        weight: 'both-at-meal-times-and-for-snacks',
                      },
                    ],
                  },
                  {
                    id: 6,
                    title: 'How many times a day do you have these types of drinks?',
                    answer_type: 'single',
                    goal: 'Make Water Your Go-to Drink',
                    help: '',
                    items: 'Green: water, low fat milk, diet soda, sparkling water/seltzer',
                    image: SurveyImage3,
                   
                    audio: 'audio.mp3',
                    color: 'ocean-green',
                    prerequisite: false,
                    risk: [
                      {
                        id: 1,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Never or rarely',
                        weight: 'never-or-rarely',
                      },
                      {
                        id: 2,
                        title: '1-2 times a day',
                        weight: '1-2-times-a-day',
                      },
                      {
                        id: 3,
                        title: '3 or more times a day',
                        weight: '>=3',
                      },
                    ],
                  },
                  {
                    id: 7,
                    title: 'How many times a day do you have these drinks?',
                    answer_type: 'single',
                    goal: 'Make Water Your Go-to Drink',
                    help: '',
                    items: 'Red: (Drinks with Sugar) Soda, Juice, Chocolate milk, Sports drinks, Sugar sweetened iced tea or coffee, Lemonade',
                    image: SurveyImage4,
                    
                    audio: 'audio.mp3',
                    color: 'sunset-orange',
                    prerequisite: false,
                    risk: [
                      {
                        id: 2,
                        value: 'high',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Never or rarely',
                        weight: 'never-or-rarely',
                      },
                      {
                        id: 2,
                        title: '1-2 times a day',
                        weight: '1-2-times-a-day',
                      },
                      {
                        id: 3,
                        title: '3 or more times a day',
                        weight: '>=3',
                      },
                    ],
                  },
                  {
                    id: 8,
                    title: 'When do you usually drink them?',
                    answer_type: 'single',
                    goal: 'Make Water Your Go-to Drink',
                    help: '',
                    items: 'Red: (Drinks with Sugar) Soda, Juice, Chocolate milk, Sports drinks, Sugar sweetened iced tea or coffee, Lemonade',
                    image: SurveyImage4,
                   
                    audio: 'audio.mp3',
                    color: 'sunset-orange',
                    prerequisite: {
                      questionNumber: 7,
                      weight: 'never-or-rarely',
                      notEqual: true,
                    },
                    risk: [
                      {
                        id: 2,
                        value: 'hight',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'At mealtimes only',
                        weight: 'at-mealtimes-only',
                      },
                      {
                        id: 2,
                        title: 'Once a day between or after meals',
                        weight: 'once-a-day-between-or-after-meals',
                      },
                      {
                        id: 3,
                        title: 'More than once a day between or after meals',
                        weight: 'more-than-once-a-day-between-or-after-meals',
                      },
                    ],
                  },
                  {
                    id: 9,
                    title: 'Who usually makes/prepares your MEALS?',
                    answer_type: 'multiple',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage5,
                    
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Me',
                        weight: 'me',
                      },
                      {
                        id: 2,
                        title: 'Family/Caregiver',
                        weight: 'family-caregiver',
                      },
                      {
                        id: 3,
                        title: 'Direct Support Professional (DSP)',
                        weight: 'direct-support-professional',
                      },
                      {
                        id: 4,
                        title: 'Other',
                        weight: 'other',
                      },
                    ],
                  },
                  {
                    id: 10,
                    title: 'Who usually makes/prepares your SNACKS?',
                    answer_type: 'multiple',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage6,
                   
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Me',
                        weight: 'me',
                      },
                      {
                        id: 2,
                        title: 'Family/Caregiver',
                        weight: 'family-caregiver',
                      },
                      {
                        id: 3,
                        title: 'Direct Support Professional (DSP)',
                        weight: 'direct-support-professional',
                      },
                      {
                        id: 4,
                        title: 'Other',
                        weight: 'other',
                      },
                    ],
                  },
                  {
                    id: 11,
                    title: 'Do you have SNAP benefits?',
                    answer_type: 'single',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage7,
                   
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Yes',
                        weight: 'yes',
                      },
                      {
                        id: 2,
                        title: 'No',
                        weight: 'no',
                      },
                      {
                        id: 3,
                        title: " I don't know",
                        weight: 'idk',
                      },
                    ],
                  },
                  {
                    id: 12,
                    title: 'Do you get special foods/snacks/drinks as prizes for doing your chores or accomplishing a goal?',
                    answer_type: 'single',
                    goal: 'Eat Thoughtfully',
                    help: '',
                    items: '',
                    image: SurveyImage8,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: [
                      {
                        id: 1,
                        value: 'high',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Yes',
                        weight: 'yes',
                      },
                      {
                        id: 2,
                        title: 'No',
                        weight: 'no',
                      },
                      {
                        id: 3,
                        title: " I don't know",
                        weight: 'idk',
                      },
                    ],
                  },
                  {
                    id: 13,
                    title: 'How often does your mouth feel dry?',
                    answer_type: 'single',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImageDryMouth,
                   
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Never or rarely',
                        weight: 'never-or-rarely',
                      },
                      {
                        id: 2,
                        title: 'Frequently or almost all of the time',
                        weight: 'frequently-or-almost-all-of-the-time',
                      }
                    ],
                  },
                  {
                    id: 14,
                    title: 'How do you wet your mouth when it feels dry?',
                    answer_type: 'multiple',
                    goal: 'Eat Thoughtfully',
                    help: '',
                    items: '',
                    image: SurveyImageMouthFresh,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: {
                      questionNumber: 13,
                      weight: 'frequently-or-almost-all-of-the-time',
                    },
                    risk: [
                      {
                        id: 3,
                        value: 'high',
                      },
                      {
                        id: 5,
                        value: 'high',
                      },
											{
                        id: 6,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Mouthwash',
                        weight: 'mouthwash',
                      },
                      {
                        id: 2,
                        title: 'Water',
                        weight: 'water',
                      },
                      {
                        id: 3,
                        title: 'Candy or gum',
                        weight: 'candy-or-gum',
                      },
                      {
                        id: 4,
                        title: 'Sugar free candy or gum',
                        weight: 'sugar-free-candy-or-gum',
                      },
                      {
                        id: 5,
                        title: 'Nothing',
                        weight: 'nothing',
                      },
                      {
                        id: 6,
                        title: 'Other',
                        weight: 'other',
                      },
                     
                    ],
                  },
                ],
              },
              {
                title: 'Oral Hygiene',
                questions: [
									{
                    id: 15,
                    title: 'Thank you for telling me about what you eat and drink. Now let’s talk about brushing your teeth.',
                    answer_type: 'single',
                    goal: false,
                    help: false,
                    items: '',
                    image: SurveyImageBrushStart,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [],
                  },
                  {
                    id: 16,
                    title: 'Who usually brushes your teeth?',
                    answer_type: 'single',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage9,
                    
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Me',
                        weight: 'me',
                      },
                      {
                        id: 2,
                        title: 'Family/caregiver',
                        weight: 'family-caregiver',
                      },
                      {
                        id: 3,
                        title: 'Direct Support Professional (DSP)',
                        weight: 'direct-support-professional',
                      },
                      {
                        id: 4,
                        title: 'No one',
                        weight: 'no-one',
                      },
                    ],
                  },
                  {
                    id: 17,
                    title: 'How many times a day are your teeth brushed?',
                    answer_type: 'single',
                    goal: 'Brush Twice a Day',
                    help: '',
                    items: '',
                    image: SurveyImage10,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: [
                      {
                        id: 1,
                        value: 'high',
                      },
                      {
                        id: 2,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Not everyday',
                        weight: 'not-everyday',
                      },
                      {
                        id: 2,
                        title: 'One time',
                        weight: '1',
                      },
                      {
                        id: 3,
                        title: 'Two times or more',
                        weight: '>=2',
                      },
                    ],
                  },
                  {
                    id: 18,
                    title: 'Does anything make it hard for you when you brush your teeth?',
                    answer_type: 'multiple',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImageBrushed,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'I don\'t like the feel or taste',
                        weight: 'i-dont-like-the-feel-or-taste',
                      },
                      {
                        id: 2,
                        title: 'I don\'t have brushing supplies',
                        weight: 'i-dont-have-brushing-supplies',
                      },
                      {
                        id: 3,
                        title: 'I forget about brushing',
                        weight: 'i-forget-about-brushing',
                      },
                      {
                        id: 4,
                        title: 'Nothing make it hard',
                        weight: 'nothing-make-it-hard',
                      },
                      {
                        id: 5,
                        title: 'Other',
                        weight: 'other',
                      }
                    ],
                  },
                  {
                    id: 19,
                    title: 'Are your brushing supplies (toothbrush/toothpaste) available to you during the day?',
                    answer_type: 'single',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImageSupplies,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Yes',
                        weight: 'yes',
                      },
                      {
                        id: 2,
                        title: 'No',
                        weight: 'no',
                      },
                    ],
                  },
                  {
                    id: 20,
                    title: 'When you brush your teeth, what surfaces do you usually brush?',
                    answer_type: 'multiple',
                    goal: 'Brush all Tooth Surfaces',
                    help: '',
                    items: '',
                    image: SurveyImageToothSides,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: {
                      allRequired: 'high',
                    },
                    answers: [
                      {
                        id: 1,
                        title: 'The inside of all of your teeth',
                        weight: 'the-inside-of-all-of-your-teeth',
                      },
                      {
                        id: 2,
                        title: 'The outside of all of your teeth',
                        weight: 'the-outside-of-all-of-your-teeth',
                      },
                      {
                        id: 3,
                        title: 'The tops of all of your teeth',
                        weight: 'the-tops-of-all-of-your-teeth',
                      },
                    ],
                  },
                  {
                    id: 21,
                    title: 'Does your usual toothpaste have fluoride in it?',
                    answer_type: 'single',
                    goal: 'Use Fluoride Toothpaste',
                    help: '',
                    items: '',
                    image: SurveyImage11,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: [
                      {
                        id: 2,
                        value: 'high',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
											{
                        id: 4,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Yes',
                        weight: 'yes',
                      },
                      {
                        id: 2,
                        title: 'No',
                        weight: 'no',
                      },
                      {
                        id: 3,
                        title: "I don't know",
                        weight: 'idk',
                      },
											{
                        id: 4,
                        title: "I don't use toothpaste",
                        weight: 'i-dont-use-toothpaste',
                      },
                    ],
                  },
                  {
                    id: 22,
                    title: 'Where do you get water to drink?',
                    answer_type: 'single',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage12,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'From the faucet',
                        weight: 'from-the-faucet',
                      },
                      {
                        id: 2,
                        title: 'From bottled water',
                        weight: 'from-bottled-water',
                      },
                      {
                        id: 3,
                        title: 'From both the faucet and bottles',
                        weight: 'from-both-the-faucet-and-bottles',
                      },
                      {
                        id: 4,
                        title: 'I don\'t drink water',
                        weight: 'i-dont-drink-water',
                      },
                    ],
                  },
                ],
              },
              {
                title: 'Access to Dental Care',
                questions: [
									{
                    id: 23,
                    title: 'Thank you for telling me all about how you clean your teeth. Finally, please tell me about going to the dentist.',
                    answer_type: 'single',
                    goal: false,
                    help: false,
                    items: '',
                    image: SurveyImageDentist,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: false,
                    answers: [],
                  },
                  {
                    id: 24,
                    title: 'Do you have a dental provider (dentist or hygienist)?',
                    answer_type: 'single',
                    goal: 'Visit your Dentist',
                    help: '',
                    items: '',
                    image: SurveyImage13,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: [
                      {
                        id: 2,
                        value: 'high',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Yes',
                        weight: 'yes',
                      },
                      {
                        id: 2,
                        title: 'No',
                        weight: 'no',
                      },
                      {
                        id: 3,
                        title: "I don't know",
                        weight: 'idk',
                      },
                    ],
                  },
                  {
                    id: 25,
                    title: 'Have you been to your dental provider (dentist or hygienist) in the last year?',
                    answer_type: 'single',
                    goal: 'Visit your Dentist',
                    help: '',
                    items: '',
                    image: SurveyImage14,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: false,
                    risk: [
                      {
                        id: 2,
                        value: 'high',
                      },
                      {
                        id: 3,
                        value: 'high',
                      },
                    ],
                    answers: [
                      {
                        id: 1,
                        title: 'Yes',
                        weight: 'yes',
                      },
                      {
                        id: 2,
                        title: 'No',
                        weight: 'no',
                      },
                      {
                        id: 3,
                        title: "I don't know",
                        weight: 'idk',
                      },
                    ],
                  },
                  {
                    id: 26,
                    title: 'Why did you go to the dentist last year?',
                    answer_type: 'multiple',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage15,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: {
                      questionNumber: 25,
                      weight: 'yes',
                    },
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'Check-up/Cleaning',
                        weight: 'check-up-cleaning',
                      },
                      {
                        id: 2,
                        title: 'Toothache/pain',
                        weight: 'toothache-pain',
                      },
                      {
                        id: 3,
                        title: 'Bleeding in my gums or mouth',
                        weight: 'bleeding-in-my-gums-or-mouth',
                      },
                      {
                        id: 4,
                        title: 'Needing a filling',
                        weight: 'needing-a-filling',
                      },
                      {
                        id: 5,
                        title: 'Had a tooth pulled',
                        weight: 'had-a-tooth-pulled',
                      },
											{
                        id: 6,
                        title: 'Other',
                        weight: 'other',
                      },
                    ],
                  },
                  {
                    id: 27,
                    title: "Why did you not go to the dentist?",
                    answer_type: 'multiple',
                    goal: false,
                    help: '',
                    items: '',
                    image: SurveyImage15,
                    icon: false,
                    audio: 'audio.mp3',
                    color: '',
                    prerequisite: {
                      questionNumber: 25,
                      weight: 'no',
                    },
                    risk: false,
                    answers: [
                      {
                        id: 1,
                        title: 'I don\'t like going to the dentist',
                        weight: 'i-dont-like-going-to-the-dentist',
                      },
                      {
                        id: 2,
                        title: 'It hurts to go to the dentist',
                        weight: 'it-hurts-to-go-to-the-dentist',
                      },
                      {
                        id: 3,
                        title: 'I have no way to get to the dentist',
                        weight: 'i-have-no-way-to-get-to-the-dentist',
                      },
                      {
                        id: 4,
                        title: 'Going to the dentist costs too much money',
                        weight: 'going-to-the-dentist-costs-too-much-money',
                      },
                      {
                        id: 5,
                        title: 'I don\'t have insurance to pay for the dentist visit',
                        weight: 'i-dont-have-insurance-to-pay-for-the-dentist-visit',
                      },
											{
                        id: 6,
                        title: 'Other',
                        weight: 'other',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    },

    /**
     * ===== Computed
     */

    computed: {
      progress() {
        let result = this.survey[0].categories.map((category) => {
					
          let questionResult = category.questions.reduce(
            (acc, question) => {
              acc.totalQuestions++;
						
              let answered = question.answers.find((answer) => answer.selected === true);

              if (answered !== undefined || (this.currentQuestion.id > question.id && question.answers.length <= 0)) acc.answeredQuestions++;
              return acc;
            },
            { totalQuestions: 0, answeredQuestions: 0 }
          );

          return { ...questionResult, category: category.title };
        });

        let totalQuestions = 0;
        result = result.map((r, index) => {
          totalQuestions += r.totalQuestions;
          if (index === 0) r.firstQuestionNumber = 1;
          else r.firstQuestionNumber = totalQuestions;
          if (this.currentQuestionNumber >= (r.firstQuestionNumber - r.totalQuestions + 1)) r.active = true;
          return r;
        });

        return {
          totalQuestions: totalQuestions,
          result: result,
        };
      },
    },

    /**
     * ===== Methods
     */

    methods: {

		

      frameIcon(icon) {
        return this.frameIcons[icon];
      },
      audio() {
        this.$helper.Notification.show({
          text: 'Feature is not available yet',
        });
      },
      async showInfo() {
        await this.$store.dispatch('showConfirmation', { id: 'info', title: 'Information', description: this.currentQuestion.help, buttons: false });
      },

      async showQuestion(questionNumber, direction) {
        
        if (questionNumber > this.progress.totalQuestions) {
          await this.completeAssessment();

					let resultURL = '/assessment/result/show';
					if(this.$route.params.userId !== undefined) resultURL = '/assessment/user/'+this.$route.params.userId+'/result/show';

          this.$router.push(resultURL);
          return;
        }

        let localQuestion = this.findQuestion(questionNumber);
        if (localQuestion === null) return;
        if (
          direction === 'next' &&
          questionNumber !== 1 &&
					this.currentQuestion.answers.length > 0 &&
          (this.answers[questionNumber - 2] === undefined ||
            this.answers[questionNumber - 2] === null ||
            this.answers[questionNumber - 2].length == 0)
        ) {
          this.$helper.Notification.show({
            text: 'Please choose your answer',
            classes: ['fail'],
            time: 1500,
          });
          return;
        }

				

        if (localQuestion.prerequisite !== false) {
          let prerequisiteMatch = this.matchPrerequisite(localQuestion);


          //skip question
          if (prerequisiteMatch === undefined) {
            localQuestion.skip = true;

            if (direction === 'next') {
              localQuestion.answers[0].selected = true;
              this.showQuestion(questionNumber + 1);
            } else if (direction === 'previous') {
              localQuestion.answers[0].selected = false;
              this.showQuestion(questionNumber - 1);
            } else {
              localQuestion.answers[0].selected = true;
              this.showQuestion(questionNumber + 1);
            }

            return;
          }

          localQuestion.skip = false;
        }

				if(localQuestion.answers.length <= 0) {
					localQuestion.skip = true;
				}

        this.currentQuestion = localQuestion;
        this.currentQuestionNumber = questionNumber;
      },

      chooseAnswer(answerIndex) {
				
				if(event && event.target.nodeName.toLowerCase() === 'input' ) return;

        let isAnswered = this.questionAnswered(this.currentQuestion);
        let stopSelection = false;

        if (isAnswered === true) {
          stopSelection = this.deselectAnswer(this.currentQuestion, this.currentQuestionNumber - 1, answerIndex);
        }

        if (stopSelection === false) this.selectAnswer(this.currentQuestionNumber, answerIndex);

        this.postAnswer();
      },

			postAnswer(answerIndex = null){
				
				let category = this.findCategory(this.currentQuestionNumber);

				if(answerIndex !== null ) this.selectAnswer(this.currentQuestionNumber, answerIndex);

        let postData = {
          title: this.survey[0].title,
          category: category.category.title,
          uid: this.currentQuestion.id,
          question: this.currentQuestion.title,
          other: {
            items: this.currentQuestion.items,
          },
          answers: this.answers[this.currentQuestionNumber - 1],
        };

				if(this.$route.params.userId !== undefined) postData.userId = this.$route.params.userId;

        this.$store.dispatch('saveAnswer', postData);

			},

      findCategory(questionNumber) {
        let totalQuestions = 0;
        let skipped = 0;

        let category = this.survey[0].categories.filter((c) => {
          totalQuestions += c.questions.length;

          if (questionNumber <= totalQuestions) return c;
          else skipped += c.questions.length;
        });

        if (category.length > 0) category = category[0];
        else return (category = null);

        return {
          category: category,
          skipped: skipped,
        };
      },

      findQuestion(questionNumber) {
        let c = this.findCategory(questionNumber);

        let category = c.category;
        let skipped = c.skipped;

        let questionIndex = questionNumber - skipped - 1;
        let localQuestion = category.questions[questionIndex];

        return localQuestion !== undefined ? localQuestion : null;
      },

      matchPrerequisite(question) {
        let prerequisiteQuestionIndex = question.prerequisite.questionNumber - 1;
        if (question.prerequisite.notEqual === true)
          return this.answers[prerequisiteQuestionIndex].find((a) => a.weight != question.prerequisite.weight);
        else return this.answers[prerequisiteQuestionIndex].find((a) => a.weight == question.prerequisite.weight);
      },

      selectAnswer(questionNumber, answerIndex, title = null) {
        let question = this.findQuestion(questionNumber);
        question.answers[answerIndex].selected = true;

				if(title !== null) question.answers[answerIndex].title = title;

        if (this.answers[questionNumber - 1] === undefined) this.answers[questionNumber - 1] = [];
       // this.answers[questionNumber - 1].push(this.currentQuestion.answers[answerIndex]);
        this.answers[questionNumber - 1].push(question.answers[answerIndex]);

					
				if(question.answers[answerIndex].weight === 'other' && event !== undefined && event.target !== undefined && event.target.nodeName) {

					let container = null;
				
					if(event.target !== null && event.target.classList.contains('assessment-answer')){
						container = event.target;
					}
					else if(event.target !== null && !event.target.classList.contains('assessment-answer')){
						container = event.target.closest('.assessment-answer');
					}

					
					if(container !== null) {
						let input = container.querySelector('input');
						if(input.value === 'Other') question.answers[answerIndex].title = '';
						input.focus();
					}

				}
      },

      deselectAnswer(question, questionIndex, answerIndex) {
        let stopSelection = false;
				
        if (question.answer_type === 'single') {
          question.answers.forEach((q) => (q.selected = false));
          this.answers[questionIndex] = [];
        } else {
          let theAnswer = question.answers[answerIndex];
					
          if (theAnswer.selected === true) {
            theAnswer.selected = false;
            this.answers[questionIndex] = this.answers[questionIndex].filter((a) => a.id !== theAnswer.id);
            stopSelection = true;
          }
        }

        return stopSelection;
      },

      questionAnswered(question) {
        let match = question.answers.find((a) => a.selected === true);
        if (match !== undefined) return true;
        return false;
      },

      findAnswerIndex(dbAnswer, answers) {
        let indexes = [];
        dbAnswer.forEach((dba) => {
          answers.forEach((a, aIndex) => {
            if (a.weight == dba.weight) indexes.push(aIndex);
          });
        });

        return indexes;
      },

      setSkippedQuestions() {
        for (let i = 0; i < this.answers.length; i++) {
          if (this.answers[i] === undefined) {
            let question = this.findQuestion(i + 1);
            question.skip = true;
						if(question.answers[0] !== undefined) question.answers[0].selected = true; 
          }
        }
      },

      checkResult(question, answer) {
        if (question.risk === undefined || question.risk === false) return [];

        let risks = [];
        let good = [];

        if (question.risk.allRequired !== undefined) {
          if (question.answers.length != answer.length) risks.push(question.goal);
          else good.push(question.goal);
        } else {
          question.risk.forEach((risk) => {
            answer.forEach((a) => {
              if (a !== undefined && a.id == risk.id) risks.push(question.goal);
              else good.push(question.goal);
            });
          });
        }

        return {
          risks: risks,
          good: good,
        };
      },

      async saveAndExit() {
        let response = await this.$store.dispatch('showConfirmation', {
          id: 'save-and-exit',
          title: 'Save & Exit',
          description: 'Are you sure you want to save and exit?',
          positiveButton: {
            text: 'Yes',
            classes: ['button-primary'],
          },
        });

        if (response === 'positive') this.$router.push(this.$store.getters.dashboardURL);
      },

      async fetchAssessment() {

				let response = null;
				if(this.$route.params.userId === undefined) response = await this.$store.dispatch('myAssessment');
				else   response = await this.$store.dispatch('userAssessment', this.$route.params.userId);

        let assessment = response.data;

        if (assessment.length <= 0) return;
        let linearAnswers = assessment[0].assessment_categories.reduce((acc, category) => {

			
          let arr = category.assessment_answers.map((a) => {
            let answer = JSON.parse(a.answer);
            answer = answer.map((aa) => {
              aa.uid = a.uid;
              return aa;
            });
            return answer;
          });

          acc = [...acc, ...arr];
          return acc;
        }, []);
			
	
        linearAnswers.forEach((dbAnswer) => {
          let question = this.findQuestion(dbAnswer[0].uid);
          let answerIndexes = this.findAnswerIndex(dbAnswer, question.answers);
					
          answerIndexes.forEach((ai, i) => {
            this.selectAnswer(parseFloat(dbAnswer[i].uid), ai, dbAnswer[i].title);
          });
        });

        this.currentQuestionNumber = this.answers.length + 1;
        this.showQuestion(this.currentQuestionNumber, 'next');
        this.setSkippedQuestions();
      },

      async completeAssessment() {
        let result = {
          risks: [],
          good: [],
        };
        for (let i = 0; i < this.answers.length; i++) {
          let answer = this.answers[i];

          if (answer !== undefined) {
            let question = this.findQuestion(i + 1);
            let response = this.checkResult(question, answer);

            let risks = [...new Set(response.risks)];
            let good = [...new Set(response.good)];

            result.risks = [...risks, ...result.risks];
            result.good = [...good, ...result.good];
          }
        }

        result.risks = [...new Set(result.risks)];
        result.good = [...new Set(result.good)];

        result.good = result.good.filter((g) => !result.risks.includes(g));

				if(this.$route.params.userId !== undefined) result.userId = this.$route.params.userId;

        await this.$store.dispatch('completeMyAssessment', result);

				return true;
      },

			onFocusOther(){
				
			}

    },

  //  mounted
    mounted() {
      this.showQuestion(this.currentQuestionNumber, 'next');
      this.fetchAssessment();
    },
  };
</script>
