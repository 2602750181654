<template>
  <div>
    <p class="section-text section-text-1 fw-500 | margin-bottom-0-5">Welcome to {{ this.$config.appName }}!</p>
    <h1 class="section-heading section-heading-1 color-primary-400">Sign up to start your dental health journey</h1>

    <div v-if="this.correctAccessCode">
      <p class="section-text section-text-1 fw-500 | margin-top-3">Select your role</p>

      <div class="d-flex gap-2 | break-md-100 margin-top-3">
        <label class="label-container label-style-1 no-mark" style="--color: #2e7aff; --selected-label-color: #2e7aff">
          <input v-model="this.register.role" v-bind="{ checked: this.register.role === 'individual' }" tabindex="1" name="role" type="radio" value="individual" />
          <span class="checkmark"></span>
          <span class="label color-neutral-600">Signing up for yourself</span>
        </label>

        <label class="label-container label-style-1 no-mark" style="--color: #2e7aff; --selected-label-color: #2e7aff">
          <input v-model="this.register.role" v-bind="{ checked: this.register.role === 'caregiver' }" name="role" type="radio" value="caregiver" />
          <span class="checkmark"></span>
          <span class="label color-neutral-600">Signing up to help others</span>
        </label>
      </div>

      <form @submit.prevent="this.registerAccount" id="account-form" action="#" class="margin-top-3">
        <div class="form-group">
          <label for="email" class="label-style-1">Email</label>
          <div class="position-relative">
            <input v-model="this.register.email" tabindex="2" name="email" type="email" class="input-style-1 has-icon" required />
            <svg class="icon">
              <use xlink:href="@/assets/icons.svg#outline-mail" />
            </svg>
          </div>
        </div>

        <div class="form-group">
          <label for="password" class="label-style-1">Password</label>
          <div class="position-relative">
            <input v-model="this.register.password" tabindex="3" name="password" type="password" class="input-style-1 has-icon" autocomplete="new-password" required />
            <svg class="icon">
              <use xlink:href="@/assets/icons.svg#outline-lock" />
            </svg>
          </div>
        </div>

        <div class="form-group">
          <label for="password" class="label-style-1">Confirm your password</label>
          <div class="position-relative">
            <input v-model="this.register.confirmPassword" tabindex="4" name="confirm-password" type="password" class="input-style-1 has-icon" required />
            <svg class="icon">
              <use xlink:href="@/assets/icons.svg#outline-lock" />
            </svg>
          </div>
        </div>

        <!-- <div class="form-group">
          <label class="label-container label-style-1">
            <input v-model="this.register.agreement" tabindex="5" type="checkbox" required />
            <span class="checkmark"></span>
            I agree to the
            <router-link :to="'/terms-of-services' + this.redirectURL" data-redirect="/terms-of-services">terms of services</router-link>
          </label>
        </div> -->

        <div class="form-group margin-top-4">
          <button class="button button-primary button-block">Register</button>
        </div>

        <!-- <div class="form-group">
          <p class="section-text section-text-1 color-neutral-600 fw-500">Forgot password? <router-link to="/forgot-password">reset here</router-link></p>
        </div> -->

        <div class="form-group">
          <p class="section-text section-text-1 color-neutral-600 fw-500">If you have an account, please <router-link to="/">login here?</router-link></p>
        </div>
      </form>
    </div>
    <div v-else>
      <form @submit.prevent="this.checkAccessCode" id="account-form" action="#" class="margin-top-3">
        <div class="form-group">
          <p class="section-text section-text-1 fw-500 | margin-top-3" style="max-width: 50rem">Please enter your Access Code to be able to create an account with us:</p>
        </div>
        <div class="form-group">
          <label for="access-code" class="label-style-1">Access Code</label>
          <div class="position-relative">
            <input v-model="this.register.accessCode" type="text" id="access-code" class="input-style-1 has-icon" required />
            <svg class="icon">
              <use xlink:href="@/assets/icons.svg#outline-lock" />
            </svg>
          </div>
        </div>

        <div class="form-group">
          <button class="button button-primary button-block">Submit</button>
        </div>

        <div class="form-group">
          <p class="section-text section-text-1 color-neutral-600 fw-500">If you have an account, please <router-link to="/">login here?</router-link></p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RegisterForm',
    data() {
      return {
        correctAccessCode: false,
        register: {
					accessCode: '',
          role: 'individual',
          email: '',
          password: '',
          confirmPassword: '',
          agreement: false,
        },
      };
    },

    /**
     * ===== Computed
     */

    computed: {
      redirectURL() {
        let emailEnc = this.$helper.Encryption.encrypt(this.register.email);
        let passwordEnc = this.$helper.Encryption.encrypt(this.register.password);
        let confirmPasswordEnc = this.$helper.Encryption.encrypt(this.register.confirmPassword);
        let roleEnc = this.$helper.Encryption.encrypt(this.register.role);

        return `?redirect=register&e=${emailEnc}&p=${passwordEnc}&cp=${confirmPasswordEnc}&r=${roleEnc}`;
      },
    },

    /**
     * ===== Methods
     */

    methods: {
      fillForm(query) {
        if (query.e !== undefined && query.e !== '') this.register.email = this.$helper.Encryption.decrypt(query.e);
        if (query.p !== undefined && query.p !== '') this.register.password = this.$helper.Encryption.decrypt(query.p);
        if (query.cp !== undefined && query.cp !== '') this.register.confirmPassword = this.$helper.Encryption.decrypt(query.cp);
        if (query.r !== undefined && query.r !== '') this.register.role = this.$helper.Encryption.decrypt(query.r);
      },
      async registerAccount() {
        let n = this.$helper.Notification.show({
          text: 'Registring account, please wait...',
          classes: [],
        });

        let response = await this.$store.dispatch('register', this.register);
        this.$helper.Notification.hide(n.data.id);

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });

        if (response.data.status === 'success') {
          this.$router.push('/complete-profile/' + response.data.user.id);
        }
      },
      async checkAccessCode() {
        let n = this.$helper.Notification.show({
          text: 'Registring account, please wait...',
          classes: [],
        });

        let response = await this.$store.dispatch('checkAccessCode', this.register.accessCode);
        this.$helper.Notification.hide(n.data.id);

        if (response.data.status !== 'success') {
          this.$helper.Notification.show({
            text: response.data.msg,
            classes: [response.data.status],
          });
          this.correctAccessCode = false;
        }

        if (response.data.status === 'success') this.correctAccessCode = true;
      },
    },

    /**
     * ===== Hooks
     */

    mounted() {
      this.fillForm(this.$route.query);
    },
  };
</script>
