<template>
  <div class="container">
    <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
      <ul>
        <li><a href="#">Assess</a></li>
        <li class="active"><a href="#">Act</a></li>
        <li><a href="#">Follow up</a></li>
      </ul>
    </div>
    <div v-if="this.pageNumber == 1">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-3">
        <div>&nbsp;</div>
        <h2 class="pre-survey-title | color-secondary-400 text-align-center">Well done!</h2>
        <div>&nbsp;</div>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <div class="d-flex justify-content-center">
        <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
          <b>You did a great job learning about <span class="color-primary-400">healthy eating</span>. This week you will work on <span class="color-secondary-400">healthy drinking.</span></b>
        </p>
      </div>

      <div class="margin-top-3">
        <ActCaptionCards complete="3" active="4" />
      </div>
    </div>
		<div v-if="this.pageNumber === 2">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2">
        <h2 class="pre-survey-title | color-secondary-400">Week 3: Let’s work on healthy drinking</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <p class="section-heading-5 | light margin-top-1">
        <b>Now let’s talk about healthy drinks.</b>
      </p>
      <div class="margin-top-3">
        <div class="yt-video-container center">
					<iframe class="yt-video yt-video-size-1"
						src="https://www.youtube.com/embed/bG_rB67sqd4?rel=0&controls=0&iv_load_policy=3" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
				</div>
      </div>
    </div>
    <div v-if="this.pageNumber === 3">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2">
        <h2 class="pre-survey-title | color-secondary-400">Week 3: Let’s work on healthy drinking</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <p class="section-heading-5 | light margin-top-1">
        <b>Here are two things to do:</b>
      </p>
      <div class="d-flex justify-content-center">
        <div class="grids grids-2 | grids-break-lg-100 gap-5 margin-top-3" style="max-width: 90%">
          <div class="grid">
            <ul class="assessment-result-list secondary-400 | margin-bottom-2">
              <li>
                <div class="count">1</div>
                <div class="info">Make water your go-to drink</div>
              </li>
            </ul>
            <img class="" src="@/assets/pre-survey-17.jpg" alt="img" />
          </div>
          <div class="grid">
            <ul class="assessment-result-list secondary-400 | margin-bottom-2">
              <li>
                <div class="count">2</div>
                <div class="info">Limit sugary drinks to mealtime and special occasions/parties</div>
              </li>
            </ul>
            <img src="@/assets/pre-survey-18.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.pageNumber === 4">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-secondary-400">Week 3: Let’s work on healthy drinking</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>

      <div class="pre-survey-image-container">
        <img style="pointer-events: none" src="@/assets/pre-survey-19.jpg" alt="img" />
      </div>
    </div>
    <div v-if="this.pageNumber === 5">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">healthy drinking:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[0]" />
    </div>
    <div v-if="this.pageNumber === 6">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">healthy drinking:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[1]" />
    </div>
    <div v-if="this.pageNumber === 7">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">healthy drinking:</span></h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>
      <PreSurveyQuestion :question="this.questions[2]" />
    </div>
    <div v-if="this.pageNumber === 8">
      <WizardBar active="4" />
      <div class="pre-survey-heading-bar | margin-top-2 margin-bottom-2">
        <h2 class="pre-survey-title | color-primary-400">Let’s plan how to reach your goal of <span class="color-secondary-400">healthy drinking</span>:</h2>
        <!-- <div class="assessment-audio" @click="this.audio">
          <Icon name="solid-volume" :classes="['fill-secondary-400']" />
          <span>Listen to text</span>
        </div> -->
      </div>

      <div class="assessment-border">
        <div class="assessment-initial-grids">
          <div class="grid text-align-center">
						<div style="width: 100%" class="margin-top-3">
								<div class="yt-video-container center">
									<iframe class="yt-video yt-video-size-1"
										src="https://www.youtube.com/embed/lWQ2YfWj1kM?rel=0&controls=0&iv_load_policy=3" frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
								</div>
						</div>
						
            <h2 class="section-heading-5 margin-top-2 margin-bottom-3" style="max-width: 55rem">We will send you reminders by email to help you reach your goals of <span class="color-secondary-400">making water your go-to drink</span> and <span class="color-secondary-400">limiting soda and juice to mealtime and special occasions/parties.</span></h2>
          </div>
          <div class="grid"><img src="@/assets/pre-survey-11.jpg" class="margin-left-auto-on-desktop margin-auto-on-mobile" alt="survey img" style="width: 220px" /></div>
        </div>
      </div>
    </div>
    <div class="margin-top-2 | text-align-right">
      <button @click="changePage()" class="button button-primary min-width-button | width-md-100">Next</button>
    </div>
  </div>
</template>

<script>
  // import Icon from '@/components/Icon/Icons.vue';
  import WizardBar from '@/components/Act/ActWizardBar.vue';
  import ActCaptionCards from '@/components/Act/ActCaptionCards.vue';
  import PreSurveyQuestion from '@/components/Act/PreSurveyQuestion.vue';

  import image1 from '@/assets/pre-survey-20.jpg';
  import image2 from '@/assets/pre-survey-21.jpg';
  import image3 from '@/assets/pre-survey-22.jpg';

  export default {
    name: 'ActGoal4',
    components: {
      WizardBar,
      ActCaptionCards,
      PreSurveyQuestion,
    },
    data() {
      return {
        pageNumber: 1,
        questions: [
          {
            title: '1. Who can help you choose healthy drinks?',
            answerType: 'multiple',
            image: image1,
            answers: [
              {
                id: 1,
                title: 'Me',
              },
              {
                id: 2,
                title: 'Family/Caregiver',
              },
              {
                id: 3,
                title: 'Direct Support Professional (DSP)',
              },
              {
                id: 4,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
          {
            title: '2. What might make it hard to choose healthy drinks?',
            answerType: 'multiple',
            image: image2,
            answers: [
              {
                id: 1,
                title: "I don't like to drink water",
              },
              {
                id: 2,
                title: 'I only like to drink sweet drinks',
              },
              {
                id: 3,
                title: "I don't have a water bottle",
              },
              {
                id: 4,
                title: 'I like sugary drinks as a reward or prize',
              },
              {
                id: 5,
                title: 'I will not find it hard',
              },
              {
                id: 6,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
          {
            title: '3. What can help you choose healthy drinks?',
            answerType: 'multiple',
            image: image3,
            answers: [
              {
                id: 1,
                title: 'I can get a water bottle to use all day',
              },
              {
                id: 2,
                title: 'I can try sugar-free drinks or sparkling water',
              },
              {
                id: 3,
                title: "I won't buy sugary drinks",
              },
              {
                id: 4,
                title: 'I will have sugary drinks only at meals',
              },
              {
                id: 5,
                title: 'Other',
                weight: 'other',
              },
            ],
          },
        ],
      };
    },
    methods: {
      changePage() {
        if (this.pageNumber === 5 && this.answerSelected(this.questions[0].answers) === false) {
          this.$helper.Notification.show({
            text: 'Please select your answer',
            classes: ['fail'],
          });
          return;
        }

        if (this.pageNumber === 6 && this.answerSelected(this.questions[1].answers) === false) {
          this.$helper.Notification.show({
            text: 'Please select your answer',
            classes: ['fail'],
          });
          return;
        }

        if (this.pageNumber === 7 && this.answerSelected(this.questions[2].answers) === false) {
          this.$helper.Notification.show({
            text: 'Please select your answer',
            classes: ['fail'],
          });
          return;
        }

        this.pageNumber++;
        if (this.pageNumber === 9) {
          this.$emit('onComplete', this.questions);
        }
      },
      answerSelected(answers) {
        return answers.reduce((acc, ans) => {
          if (ans.selected === true) acc = true;
          return acc;
        }, false);
      },
    },
  };
</script>
