<template>
	<div class="margin-top-2">
		<div class="container">
			<h1 class="section-heading section-heading-2 color-primary-400">Terms of Service</h1>

			<div class="section-text-box margin-top-3">
				<p class="section-text section-text-1 color-neutral-600">
					Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum, voluptates vel sint ad rerum debitis ipsam sit eius blanditiis eos
					assumenda beatae voluptate earum deserunt consectetur! Neque eius maiores facilis vero? Commodi explicabo quam sequi accusantium ipsum quod
					voluptas repellat ab dolore, eius iste fugiat, laudantium rem cumque debitis placeat qui! Est non veniam in optio itaque sequi
					exercitationem, cum, ipsa culpa vero provident quaerat, consequatur libero quam maxime eligendi id dicta sed voluptatibus animi voluptas
					tenetur earum ipsum cumque. Iste delectus totam laboriosam consequuntur id similique tempora quaerat vero itaque possimus mollitia dolorem
					saepe laudantium quae harum, dolorum odit.
				</p>

				<p class="section-text section-text-1 color-neutral-600 margin-top-2">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente vel libero placeat aperiam cum maiores reprehenderit tempore. Iusto optio
					ducimus hic recusandae vel harum consequuntur aliquam praesentium odio molestias ratione, perspiciatis facilis. Tempora itaque sed libero
					quam quidem provident. Cupiditate impedit necessitatibus doloremque nulla ducimus iure magnam provident quibusdam, dolor quidem numquam
					architecto, et quam quos vero ipsum corrupti eius.
				</p>

				<p class="section-text section-text-1 color-neutral-600 margin-top-2">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente vel libero placeat aperiam cum maiores reprehenderit tempore. Iusto optio
					ducimus hic recusandae vel harum consequuntur aliquam praesentium odio molestias ratione, perspiciatis facilis. Tempora itaque sed libero
					quam quidem provident. Cupiditate impedit necessitatibus doloremque nulla ducimus iure magnam provident quibusdam, dolor quidem numquam
					architecto, et quam quos vero ipsum corrupti eius.
				</p>

				<p class="section-text section-text-1 color-neutral-600 margin-top-2">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente vel libero placeat aperiam cum maiores reprehenderit tempore. Iusto optio
					ducimus hic recusandae vel harum consequuntur aliquam praesentium odio molestias ratione, perspiciatis facilis. Tempora itaque sed libero
					quam quidem provident. Cupiditate impedit necessitatibus doloremque nulla ducimus iure magnam provident quibusdam, dolor quidem numquam
					architecto, et quam quos vero ipsum corrupti eius.
				</p>

				<p class="section-text section-text-1 color-neutral-600 margin-top-2">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente vel libero placeat aperiam cum maiores reprehenderit tempore. Iusto optio
					ducimus hic recusandae vel harum consequuntur aliquam praesentium odio molestias ratione, perspiciatis facilis. Tempora itaque sed libero
					quam quidem provident. Cupiditate impedit necessitatibus doloremque nulla ducimus iure magnam provident quibusdam, dolor quidem numquam
					architecto, et quam quos vero ipsum corrupti eius.
				</p>
			</div>

			<div class="margin-top-3 text-align-center">
				<router-link :to="this.redirectURL" class="button button-primary button-long">I agree</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TermsPage',
		data () {
			return {
				redirectURL: '/'
			}
		},

		/**
		 * ===== Methods
		 */

		methods: {

			setRedirectLink(query){		
				let link  = ``;
				for(let key in query){
					if(key == 'redirect') link += `${query[key]}?`;
					else link += `${key}=${query[key]}&`;
				}
				if(link[link.length - 1] == '&') link = link.substring(0, link.length - 1);
				this.redirectURL = link;
			}

		},

		/**
		 * ===== Hooks
		 */

		mounted(){
			this.setRedirectLink(this.$route.query);
		}
	};
</script>

<style></style>
