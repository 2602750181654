<template>
  <div class="margin-top-1">
    <div class="secondary-navigation">
      <div class="container">
        <div class="print-options">
          <router-link class="option" to="/access-codes/manage">
            <h2 class="option-heading">Manage Access Codes</h2>
            <img class="option-icon" src="@/assets/lock-icon.png" alt="icon" />
          </router-link>
          <router-link class="option" to="/users/manage">
            <h2 class="option-heading">Manage Users</h2>
						<img class="option-icon" src="@/assets/users-icon.png" alt="icon" />
          </router-link>
        </div>
      </div>
    </div>

    <!-- <ManageAccessCode /> -->
  </div>
</template>

<script>
  // import ManageAccessCode from '@/components/AccessCode/ManageAccessCode.vue';

  export default {
    name: 'AdminDashboard',
    // components: {ManageAccessCode },
  };
</script>
