<template>
  <div class="container | margin-top-2">
    <div class="d-flex gap-2">
      <Icon name="outline-printer" class="icon" style="stroke: var(--clr-neutral-800); width: 3.6rem; height: 3.6rem" />
      <h1 class="section-heading-3">Print</h1>
    </div>
    <div class="print-options">
      <a v-if="this.activeGoal() !== undefined" class="option" target="_blank" :href="this.$config.serverBaseURL + '/WeSmilePlan' + this.$route.params.goalId + '.pdf'">
        <h2 class="option-heading">Print Active Plan</h2>
        <img class="option-icon" src="@/assets/doc.png" alt="icon" />
        <p class="option-text | section-text section-text-1">Print educational resources related to your current active plan: <span class="color-secondary-400" >{{ this.activeGoal().title }}</span></p>
      </a>
      <a class="option" target="_blank" :href="this.$config.serverBaseURL + '/WeSmileOffline.pdf' ">
        <h2 class="option-heading">Print WeSmile Offline</h2>
        <img class="option-icon" src="@/assets/docs.png" alt="icon" style="width: 7rem" />
        <p class="option-text | section-text section-text-1">Print all WeSmile educational resources, schedule, and follow-up materials to keep track of your progress offline.</p>
      </a>
    </div>
    <div class="margin-top-3">
      <router-link :to="this.$store.getters.dashboardURL" class="button button-md-100 min-width-button | no-shadow">Back</router-link>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  export default {
    name: 'ActionPlanPrint',
	
    components: {
      Icon,
    },
		methods: {
			activeGoal(){
				let goalId = this.$route.params.goalId;
				let goals = this.$store.getters.goals;
				let activeGoal = goals.find(g => g.id == goalId);
				return activeGoal;
			}
		}
  };
</script>
