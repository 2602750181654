<template>
  <div>
    <div class="caption-cards caption-cards-small | margin-top-3">
      <router-link :to="this.resourceLinks == 'true' ? '/resources/goal-1' : '#'" class="caption-card" :class="{ 'caption-card-secondary-500': this.active == 1 || this.active == 'all', 'caption-card-primary-400': this.complete >= 1,'no-hover': this.resourceLinks != 'true' }">
        <div class="caption-card-header">
          <img class="caption-card-image" src="@/assets/survey-img-13.jpg" alt="img" />
        </div>
        <div class="caption-card-body">
          <p class="caption-card-text">Visit your dentist</p>
        </div>
        <span class="status-icon" v-if="this.complete >= 1">
          <Icon name="solid-tick-2" />
        </span>
      </router-link>

      <router-link :to="this.resourceLinks == 'true' ? '/resources/goal-2' : '#'" class="caption-card" :class="{ 'caption-card-secondary-500': this.active == 2 || this.active == 'all', 'caption-card-primary-400': this.complete >= 2, 'no-hover': this.resourceLinks != 'true' }">
        <div class="caption-card-header">
          <img class="caption-card-image" src="@/assets/survey-img-10.jpg" alt="img" />
        </div>
        <div class="caption-card-body">
          <p class="caption-card-text">Brush your teeth</p>
        </div>
        <span class="status-icon" v-if="this.complete >= 2">
          <Icon name="solid-tick-2" />
        </span>
      </router-link>

      <router-link :to="this.resourceLinks == 'true' ? '/resources/goal-3' : '#'" class="caption-card" :class="{ 'caption-card-secondary-500': this.active == 3 || this.active == 'all', 'caption-card-primary-400': this.complete >= 3, 'no-hover': this.resourceLinks != 'true' }">
        <div class="caption-card-header">
          <img class="caption-card-image" src="@/assets/pre-survey-4.jpg" alt="img" />
        </div>
        <div class="caption-card-body">
          <p class="caption-card-text">Choose healthy foods</p>
        </div>
        <span class="status-icon" v-if="this.complete >= 3">
          <Icon name="solid-tick-2" />
        </span>
      </router-link>
    </div>

    <div class="caption-cards caption-cards-small | margin-top-1 margin-bottom-3">
      <router-link :to="this.resourceLinks == 'true' ? '/resources/goal-4' : '#'" class="caption-card" :class="{ 'caption-card-secondary-500': this.active == 4 || this.active == 'all', 'caption-card-primary-400': this.complete >= 4, 'no-hover': this.resourceLinks != 'true' }">
        <div class="caption-card-header">
          <img class="caption-card-image" src="@/assets/pre-survey-5.jpg" alt="img" />
        </div>
        <div class="caption-card-body">
          <p class="caption-card-text">Make water your go-to drink</p>
        </div>
        <span class="status-icon" v-if="this.complete >= 4">
          <Icon name="solid-tick-2" />
        </span>
      </router-link>

      <router-link :to="this.resourceLinks == 'true' ? '/resources/goal-5' : '#'" class="caption-card" :class="{ 'caption-card-secondary-500': this.active == 5 || this.active == 'all', 'caption-card-primary-400': this.complete >= 5, 'no-hover': this.resourceLinks != 'true'}">
        <div class="caption-card-header">
          <img class="caption-card-image" src="@/assets/pre-survey-6.jpg" alt="img" />
        </div>
        <div class="caption-card-body">
          <p class="caption-card-text">Eat thoughtfully</p>
        </div>
        <span class="status-icon" v-if="this.complete >= 5">
          <Icon name="solid-tick-2" />
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  export default {
    name: 'ActCaptionCards',
    props: ['active', 'complete', 'resourceLinks'],
    components: { Icon },
    data() {},
  };
</script>

<style></style>
