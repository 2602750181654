const app = {

	state: {
		pageSlug: null,
		documentEvent: {
			click: null,
			resize: null
		},
		overlay: false,
		confirmationPopup: []
	},

	mutations: {

		pageSlug(state, slug) {
			state.pageSlug = slug;
		},

		documentEventClick(state, func) {
			state.documentEvent.click = func
		},

		documentEventResize(state, func) {
			state.documentEvent.resize = func;
		}

	},

	actions: {
		showConfirmation({ state }, payload) {
			return new Promise((resolve) => {
				payload.resolve = resolve;
				state.confirmationPopup.push(payload);
				if (state.confirmationPopup.length > 0) state.overlay = true;
			});
		},

		hideConfirmation({ state }, payload = {}) {

			let stackLength = state.confirmationPopup.length;

			if (payload.id === undefined) {
				state.confirmationPopup[stackLength - 1].resolve(null);
				state.confirmationPopup.pop();
			}

			state.confirmationPopup = state.confirmationPopup.filter((obj) => {
				if (obj.id !== payload.id) return obj;
			});
			if (state.confirmationPopup.length <= 0) state.overlay = false;
		},

		confirmationAction({ state }, payload = {}) {

			let { id, type } = payload;

			let confirmation = state.confirmationPopup.filter((obj) => obj.id === id);

			if (confirmation.length > 0 && confirmation[0].positiveActions) {
				let positiveActions = confirmation[0].positiveActions;
				positiveActions.forEach((action) => action());
			}

			this.dispatch("hideConfirmation", { id: id });
			confirmation[0].resolve(type);
		}
	},

	getters: {
		pageSlug(state) {
			return state.pageSlug;
		},
		documentEvent(state) {
			return state.documentEvent;
		},
		overlay(state) {
			return state.overlay;
		},
		confirmationPopup(state) {
			return state.confirmationPopup
		}
	}

};

export default app;