<template>
  <div>
    <div class="container | margin-bottom-2" v-if="this.user !== null">
      <p class="section-heading-2">{{ this.user.first_name + ' ' + (this.user.last_name == null ? '' : this.user.last_name) }}'s Dashboard</p>
    </div>

    <div :class="{'container assessment-border': this.$route.params.userId !== undefined}">
      <div class="account-section margin-top-2">
        <div class="container">
          <div class="breadcrumbs | d-flex justify-content-space-between align-items-center">
            <ul>
              <li :class="{ active: this.breadcrumbs.access }">
                <a href="#">Assess</a>
              </li>
              <li :class="{ active: this.breadcrumbs.act }">
                <a href="#">Act</a>
              </li>
              <li :class="{ active: this.breadcrumbs.followUp }">
                <a href="#">Follow up</a>
              </li>
            </ul>

            <p class="page-user-id" v-if="this.$route.params.userId === undefined">ID# {{ this.$store.getters.user.uid }}</p>
            <p class="page-user-id" v-else-if="this.user !== null">ID# {{ this.user.uid }}</p>
          </div>
        </div>
      </div>

      <div class="margin-top-3">
        <div class="container">
          <!-- Action Plan Button -->

          <div v-if="this.sections.takeAssessment">
            <router-link :to="this.assessmentURL(this.assessments[0])" class="button button-primary | button-md-100">
              {{ (this.assessments[0] !== undefined && this.assessments[0].status) === 'pending' ? 'Click here to continue risk assessment' : 'Click here to start risk assessment' }}
            </router-link>

            <div class="dashboard-timeline timeline | margin-top-3 margin-bottom-3">
              <div class="timeline-item">
                <p class="section-text section-text-1">
                  <b
                    >Risk Assessment -- <span class="color-primary-400" style="white-space: nowrap">{{ this.riskAssessmentText }}</span></b
                  >
                </p>
              </div>
              <div class="timeline-item">
                <p class="section-text section-text-1">
                  <b>Active Action Plan -- <span class="color-primary-400" style="white-space: nowrap">Not active action plan</span></b>
                </p>
              </div>
              <div class="timeline-item">
                <p class="section-text section-text-1">
                  <b>Progress -- <span class="color-primary-400" style="white-space: nowrap">Action plan not started yet</span></b>
                </p>
              </div>
            </div>
          </div>

          <!-- Action Survey Button -->
          <div class="margin-bottom-3" v-else-if="this.actionSurveys[0] === undefined || this.actionSurveys[0].status !== 'complete'">
            <router-link :to=" this.$route.params.userId === undefined ? '/act/survey' : '/act/survey/' +  this.$route.params.userId" class="button button-primary" v-if="this.hideActionPlanningButton === false">Click here to continue action planning</router-link>
          </div>

          <div class="margin-bottom-3" v-if="this.postSurveyContinue !== null">
            <router-link :to="'/act/post-survey/' + this.postSurveyContinue.actionSurveyId + '/' + this.postSurveyContinue.goalId" class="button button-primary">{{ this.postSurveyContinue.title }}</router-link>
          </div>

          <div v-if="this.sections.assessmentsResult">
            <div class="margin-bottom-3">
              <ActionPlanWidget :goals="this.goals" :plan="this.activePlanText" />
              <ActionPlanProgressWidget :goals="this.goals" class="margin-top-2" />
              <AssessmentResultWidget :userId="this.$route.params.userId" class="margin-top-2" />
              <ActionPlanPrintWidget :goals="this.goals" class="margin-top-2" />
            </div>
						
          </div>

					<Profile v-if="this.$route.params.userId !== undefined" />

        </div>
      </div>
    </div>

    <!-- Confirmation Popup -->
    <ConfirmationPopup />
  </div>
</template>

<script>
  import ConfirmationPopup from '@/components/Popup/Confirmation.vue';
  import ActionPlanWidget from '@/components/ActionPlan/Widget.vue';
  import ActionPlanPrintWidget from '@/components/ActionPlan/PrintWidget.vue';
  import ActionPlanProgressWidget from '@/components/ActionPlan/ProgressWidget.vue';
  import AssessmentResultWidget from '@/components/Assessment/ResultWidget.vue';
  import Profile from '@/components/Profile.vue';

  export default {
    name: 'IndividualDashboard',
    components: {
      ConfirmationPopup,
      ActionPlanWidget,
			ActionPlanPrintWidget,
      ActionPlanProgressWidget,
      AssessmentResultWidget,
			Profile
    },
    data() {
      return {
        breadcrumbs: {
          access: false,
          act: false,
          followUp: false,
        },

        user: null,

        sections: {
          takeAssessment: false,
          assessmentsResult: false,
        },

        /**
         * ===== Assessments
         */

        assessments: [],
        riskAssessmentText: '',

        /**
         * ===== Goals, Progress, Action Survey
         */

        goals: JSON.parse(JSON.stringify(this.$store.getters.goals)),
        activePlanText: 'No Active Plans',
        progress: [],
        actionSurveys: [],
        postSurveyContinue: null,
        hideActionPlanningButton: null,
      };
    },

    /**
     * ===== Methods
     */

    methods: {
      async populatePage() {
        this.fetchActionSurvey();
        this.populateAssessment();
      },

      async populateAssessment() {
        let action = 'myAssessment';
        if (this.$route.params.userId !== undefined && this.$route.params.userId != null) action = 'userAssessment';

        let response = await this.$store.dispatch(action, this.$route.params.userId);
        if (response.data.length <= 0) {
          this.sections.takeAssessment = true;
          this.riskAssessmentText = 'Not started yet';
          return;
        }

        this.assessments = response.data;

        let assessment = response.data[0];
        if (assessment.status === 'pending') this.riskAssessmentText = 'Pending';
        else if (assessment.status === 'not-started') this.riskAssessmentText = 'Not started yet';
        else if (assessment.status === 'complete') {
          this.riskAssessmentText = 'Complete';
          this.sections.assessmentsResult = true;
          this.updateBreadCrumbs('act');
        }

        if (this.assessments[0] === undefined || this.assessments[0].status !== 'complete') this.sections.takeAssessment = true;
      },

      updateBreadCrumbs(value) {
        this.breadcrumbs.access = false;
        this.breadcrumbs.act = false;
        this.breadcrumbs.followUp = false;

        this.breadcrumbs[value] = true;
      },

			assessmentURL(assessment){

				let url = '';

				if(assessment !== undefined && assessment.status === 'pending') url = '/assessment/1';
				else url = '/assessment/initial';

				if(this.$route.params.userId !== undefined && this.$route.params.userId != null) url = url += '/' + this.$route.params.userId;

				return url;
			},

      async fetchActionSurvey() {
        let action = 'myActionSurveys';
        if (this.$route.params.userId !== undefined && this.$route.params.userId != null) action = 'userActionSurveys';

        let response = await this.$store.dispatch(action, this.$route.params.userId);
        this.actionSurveys = response.data;

        let postSurveyButtonDisappearTimeInMinutes = 2;
        let postSurveyNotFilledGoals = [];
        let completedGoalIds = [];
        let completedGoals = {};
        let anchor = null;
        let lastGoalCompleted = null;

        if (this.actionSurveys[0] !== undefined) {
          completedGoalIds = this.actionSurveys[0].action_survey_goals.reduce((acc, g) => {
            if (g.status === 'complete') {
              let completedGoalId = g.goal_id;
              acc.push(completedGoalId);
              let postSurveyFilled = this.isPostSurveyFilledForGoal(completedGoalId, this.actionSurveys[0].action_survey_answers);
              if (!postSurveyFilled) postSurveyNotFilledGoals.push(g);
              if (g.goal_id == 2) anchor = g;
              lastGoalCompleted = g;
            } else if (g.status === 'incomplete') {
              this.hideActionPlanningButton = true;
            }
            return acc;
          }, []);

          this.actionSurveys[0].action_survey_goals.map((g) => {
            completedGoals[g.goal_id] = g;
          });
        }

        if (this.hideActionPlanningButton === null) this.hideActionPlanningButton = false;

        if (lastGoalCompleted !== null) {
          let lastGoalCompleteDateTime = this.$helper.toLocalDateTime(lastGoalCompleted.update_datetime);
          let deadline = this.$helper.addMinutesToDateTime(lastGoalCompleteDateTime, postSurveyButtonDisappearTimeInMinutes);
          let now = this.$helper.now();

          let deadlinePassed = this.$helper.compareDateTime(now, deadline);
          if (deadlinePassed === true) this.hideActionPlanningButton = true;
        }

        this.goals = this.goals.map((g) => {
          if (completedGoalIds.includes(g.id)) g.complete = true;

          if (completedGoals[g.id] !== undefined) g.create_datetime = this.$helper.toLocalDateTime(completedGoals[g.id].create_datetime, true);
          else g.create_datetime = '';

          return g;
        });

        if (this.goals[completedGoalIds.length] !== undefined) {
          this.goals[completedGoalIds.length].active = true;
          this.activePlanText = this.goals[completedGoalIds.length].title;
        }

        postSurveyNotFilledGoals.forEach((notFilledGoal) => {
          if (notFilledGoal.update_datetime == null) notFilledGoal.update_datetime = notFilledGoal.create_datetime;

          let goalId = notFilledGoal.goal_id;
          let now = this.$helper.now();
          let goalDateTime = this.$helper.toLocalDateTime(notFilledGoal.update_datetime);
          let deadline = this.$helper.addMinutesToDateTime(goalDateTime, postSurveyButtonDisappearTimeInMinutes);

          let deadlinePassed = this.$helper.compareDateTime(now, deadline);

          if (deadlinePassed == false) {
            let goal = this.goals.find((g) => g.id == goalId);
            this.postSurveyContinue = {
              title: 'Tell us how you did with ' + goal.title,
              goalId: goal.id,
              actionSurveyId: anchor.action_survey_id,
            };
          }
        });
      },

      isPostSurveyFilledForGoal(goalId, answers) {
        let match = answers.find((answer) => {
          if (answer.goal_id == goalId && answer.action_survey_type == 'post-survey') return answer;
        });

        if (goalId == 1) return true;
        if (match === undefined) return false;

        return true;
      },

      async fetchUser() {
        let user = await this.$store.dispatch('user', this.$route.params.userId);
        this.user = user.data;
      },
    },

    /**
     * ===== Hooks
     */

    mounted() {
      this.populatePage();
      this.updateBreadCrumbs('access');
      if (this.$route.params.userId !== undefined && this.$route.params.userId != null) this.fetchUser();
    },
  };
</script>
