<template>
  <div class="toggle-button-container">
    <!-- Buttons -->
    <div class="toggle-buttons">
      <button
        @click="this.selectTab(index)"
        v-for="(button, index) in this.buttons"
        :key="index"
        :class="{ button: true, active: index === this.selectedIndex }"
        data-toggle="has-account"
      >
        {{ button }}
      </button>
    </div>

    <!-- Content -->
    <div class="toggle-content-container | margin-top-3">
      <slot v-for="(index) in this.filteredContent" :key="index" :name="index"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ToggleButton',
    props: ['buttons'],
    data() {
      return {
        filteredItems: [],
        selectedIndex: 0,
      };
    },

    /**
     * ===== Computed
     */

    computed: {
			filteredContent() {
      return this.filteredItems;
    },
		},

    /**
     * ===== Methods
     */

    methods: {
      selectTab(index) {
				this.filteredItems = [index];
        this.selectedIndex = index;
      },
    },

    /**
     * ===== Hooks
     */

		mounted(){
			this.filteredItems = [0]
		}
  };
</script>
