<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Thoughtful Eating 2</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-17.jpg" alt="img1" />
					<ul>
            <li>Thoughtful eating means eating good foods for good health--not eating just to do something or just because you did something good.</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/resource-18.jpg" alt="img1" />
          <ul>
            <li>Instead of snacking when you are bored, try a craft or game with a friend!</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center | margin-top-3">
      <div class="grid" style="max-width:97rem;width:100%;">
        <div class="pre-survey-vertical-section solid-border d-flex break-md-100 gap-3 ">
          <img src="@/assets/resource-19.jpg" alt="img1" style="max-width: 30rem" />
          <div>
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400" style="text-align:left;padding-left:2rem;">Tips</h2>
            <ul>
              <li>Don’t eat just for something to do.</li>
              <li>Don’t ask for food just because you did something good.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
	<div class="margin-top-3">
				<router-link to="/resources/goal-5" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResourceGoal5c',
  };
</script>
