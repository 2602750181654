<template>
  <header class="primary-navigation">
    <div class="container flex-1">

      <!-- Left -->
      <div class="left">
        <router-link :to="this.$store.getters.dashboardURL"><img class="logo" src="@/assets/logo.png" alt="logo" width="72" height="72" /></router-link>

        <div class="hide-on-lg" v-if="this.$store.getters.isLoggedIn('individual') && this.$store.getters.profileComplete">
          <!-- <p class="primary-navigation-heading" data-user="name">{{ this.$store.getters.fullName }}</p>
          <p class="primary-navigation-sub">
            DoB: <span data-user="dob">{{ this.$store.getters.user.dob }}</span>
          </p> -->
        </div>

      </div>

      <!-- Right -->
      <div :class="{ right: true, hide: this.$store.getters.pageSlug === 'complete-profile' }">
        <ul class="primary-navigation-list">
          <li
            v-if="this.$store.getters.isLoggedIn() && this.$store.getters.profileComplete"
            :class="{ active: this.$store.getters.pageSlug === this.$store.getters.roleTitle + '-dashboard' }"
          >
            <router-link :to="this.$store.getters.dashboardURL">Dashboard</router-link>
          </li>
          <li v-else :class="{ active: this.$store.getters.pageSlug === 'login' }"><router-link to="/">Home</router-link></li>

          <li v-if="this.$store.getters.isLoggedIn()" :class="{ active: this.$store.getters.pageSlug === 'resources' }"><router-link to="/resources">Resources</router-link></li>
          <li :class="{ active: this.$store.getters.pageSlug === 'about' }"><router-link to="/about">About us</router-link></li>

          <li v-if="this.$store.getters.isLoggedIn()" class="hide-on-desktop"><router-link to="/profile">Account</router-link></li>
          <li v-if="this.$store.getters.isLoggedIn()" class="hide-on-desktop"><router-link @click.prevent="logout" to="#">Logout</router-link></li>
        </ul>
			
        <router-link v-if="!this.$store.getters.isLoggedIn() || this.$store.getters.profileComplete === false" to="/register" class="button button-primary hide-on-lg" :class="{'hide': this.$route.name === 'RegisterForm'}">Sign up</router-link>
        <router-link v-if="!this.$store.getters.isLoggedIn() || this.$store.getters.profileComplete === false" to="/login" class="button button-primary hide-on-lg" :class="{'hide': this.$route.name !== 'RegisterForm'}" style="min-width:117px">Log in</router-link>

        <div v-else-if="this.$store.getters.profileComplete === true" class="profile-badge" @click="profileBadgeDropDown">
          <span class="profile-badge-dp">{{ this.$store.getters.fullName.split('')[0] }}</span>
          <span class="profile-badge-text" data-user="name">{{ this.$store.getters.fullName }}</span>
          <svg class="caret">
            <use xlink:href="@/assets/icons.svg#solid-arrow-down" />
          </svg>

          <div class="profile-badge-dropdown">
						<p>
              <router-link to="/profile">
                <svg class="icon user">
                  <use xlink:href="@/assets/icons.svg#solid-user" />
                </svg>
                Account
              </router-link>
            </p>
            <p>
              <router-link @click.prevent="logout" to="#">
                <svg class="icon">
                  <use xlink:href="@/assets/icons.svg#solid-logout" />
                </svg>
                Logout
              </router-link>
            </p>
          </div>
        </div>

        <svg class="mobile-icon hide-on-desktop" @click="toggleNavigation">
          <use xlink:href="@/assets/icons.svg#hamburger" />
        </svg>
      </div>
			
    </div>
  </header>
</template>

<script>

  export default {
    name: 'PrimaryNavigation',
    data() {
      return {};
    },

    /**
     * ===== Methods
     */

    methods: {
      toggleNavigation() {
        let primaryNavigationList = document.querySelector('.primary-navigation-list');
        this.$helper.DOM.toggleClass(primaryNavigationList, 'active');
      },

      profileBadgeDropDown() {
        let badge = document.querySelector('.profile-badge');
        this.$helper.DOM.toggleClass(badge, 'active');
      },

			logout() {
				this.$store.commit('logout');
			}

    },

		/**
		 * ===== Hooks
		 */

		mounted(){
			
		}

  };
</script>
