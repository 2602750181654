<template>
  <div class="action-plan-widget | section-border">
    <div class="widget-header">
      <div class="widget-icon-text-header left">
        <Icon name="outline-target" class="icon" />
        <h2 class="widget-heading">Active Action Plan</h2>
      </div>
      <div class="right"></div>
    </div>

    <div class="action-plan-widget-body">
      <div class="left">
        <h2 class="widget-title">{{ this.plan }}</h2>
        <template v-if="this.activeGoal">
          <p v-for="(text, index) in this.activeGoal.shortDescription" :key="index" class="widget-text | margin-top-2">{{ text }}</p>
        </template>
        <div v-if="this.activeGoal" class="widget-icon-text-header | margin-top-3">
          <Icon name="outline-img-text" class="icon" />
          <h2 class="widget-heading">Resources</h2>
        </div>
        <div class="margin-top-2">
          <ul class="widget-list">
            <li v-for="(row, index) in this.resources" :key="index">
              <router-link :to="'/' + row.link">{{ row.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <img v-if="this.activeGoal" :src="this.$config.serverBaseURL + '/' + this.image" class="action-plan-widget-image" alt="action plan" />
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  export default {
    name: 'ActionPlanWidget',
    components: {
      Icon,
    },
    props: ['plan', 'goals'],
    data() {
      return {
        activeGoal: null,
        completeGoal: null,
        image: null,
        resources: [],
      };
    },
    mounted() {
      let completeGoal = null;
      this.goals.forEach((g) => {
        if (g.complete == true) {
          completeGoal = g;
        }
      });
      let activeGoal = this.goals.find((g) => g.active == true);
      this.activeGoal = activeGoal;
			if(activeGoal !== undefined) this.image = activeGoal.smallImage;
      this.completeGoal = completeGoal;

      if (this.completeGoal !== null) {
        this.completeGoal.followUpEmails.forEach((email) => {
          if (email.type === 'material') {
            this.resources.push({
              title: email.title,
              link: email.resourceLink,
            });
          }
        });
      }

	

    },
  };
</script>
