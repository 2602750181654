<template>
  <div class="container">
    <div class="breadcrumbs | d-flex align-items-center justify-content-space-between margin-bottom-3">
      <ul>
        <li class="active"><a href="#">Assess</a></li>
        <li><a href="#">Act</a></li>
        <li><a href="#">Follow up</a></li>
      </ul>
      <!-- <div class="assessment-audio" @click="this.audio">
        <Icon name="solid-volume" :classes="['fill-secondary-400']" />
        <span>Listen to text</span>
      </div> -->
    </div>

    <div v-if="this.pageNumber === 1">
			<h1 class="pre-survey-title | color-secondary-400 text-align-center">Welcome to {{ this.$config.appName }}!</h1>
			<div class="d-flex justify-content-center">
        <p class="section-heading-5 | text-align-center max-width-80 light margin-top-1">
          <b>Let's watch The Surprising True Story of Cavities</b>
        </p>
      </div>
			<div class="yt-video-container center margin-top-4">
				<iframe class="yt-video yt-video-size-1" src="https://www.youtube.com/embed/6NNJ891w0jg?rel=0&controls=0&iv_load_policy=3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
			</div>
			<div class="text-align-right margin-top-3">
				<button @click="changePage(2)" class="button button-primary min-width-button">Next</button>
			</div>
		</div>
    <div v-if="this.pageNumber === 2">
      <div class="assessment-container">
        <div class="assessment assessment-border | margin-top-2" style="padding: 0">
          <div class="assessment-initial-grids">
            <div class="grid text-align-center">
              <h2 class="assessment-initial-heading">To find out about your risks for tooth decay, please tell me about your diet, tooth brushing, and dental care.</h2>
              <router-link :to="this.$route.params.userId === undefined ? '/assessment/1' : '/assessment/1/' + this.$route.params.userId " class="button button-primary | button-md-100 margin-top-3">Let's Get Started</router-link>
            </div>
            <div class="grid">
              <img src="@/assets/ra-start.jpg" class="margin-left-auto-on-desktop margin-auto-on-mobile" alt="survey img" style="width: 200px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  // import Icon from '@/components/Icon/Icons.vue';

  export default {
    name: 'AssessmentInitial',
    components: {  },
    data() {
      return {
        pageNumber: 1,
      };
    },
    methods: {
      changePage(number) {
        this.pageNumber = number;
      },
    }
  };
</script>
