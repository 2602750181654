/* eslint-disable no-unused-vars */
function toggleClass(element, className, checkOnly = false) {
    let hasClass = false;
    if (element !== null && element.classList.contains(className)) {
        if (checkOnly == false)
            element.classList.remove(className);
        hasClass = true;
    }
    else if (element !== null)
        if (checkOnly == false)
            element.classList.add(className);
    return hasClass;
}
function setPageMeta(meta) {
    const { tabTitle = "WeSmile | Continue Your Dental Health Journey", description = "" } = meta;
    const titleElement = document.querySelector("head title");
    const descriptionElement = document.querySelector("head meta[name='description']");
    if (titleElement !== null)
        titleElement.innerHTML = tabTitle;
    if (descriptionElement !== null)
        descriptionElement.setAttribute("content", description);
}
export default {
    toggleClass,
    setPageMeta
};
/* eslint-enable no-unused-vars */ 
