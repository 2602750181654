<template>
  <div >
    <!-- Breadcrubmbs -->
    <div class="container">
      <div class="breadcrumbs">
        <ul>
          <li><a href="#">Assess</a></li>
          <li><a href="#">Act</a></li>
          <li class="active"><a href="#">Follow up</a></li>
        </ul>
      </div>
    </div>

    <!-- Main -->

    <div class="container" v-if="this.postSurveyAnswers !== null && this.postSurveyAnswers.length === 0">
      <!-- Wizard Bar -->
      <div class="wizard-bar | margin-top-3">
        <div class="wizard-bar-row">
          <div class="wizard-bar-step" v-for="(goal, index) in this.goals" :key="index" :class="{ active: goal.active === true, complete: goal.complete === true }">
            <span class="radiomark"></span>
            <span class="text">{{ goal.title }}</span>
            <span class="gg-chevron-right"></span>
          </div>
        </div>
      </div>

      <!-- Wizard Content -->

      <div class="wizard-content | margin-top-3">
        <div class="wizard-content-step">
          <!-- Linear Action List Layout -->

          <div v-if="this.survey[this.currentScreenIndex] !== undefined && this.survey[this.currentScreenIndex].layout === 'linear-actions'">
            <div class="pre-survey-heading-bar | margin-top-3">
              <div>&nbsp;</div>
              <h2 class="pre-survey-title | text-align-center max-width-80" v-html="this.survey[this.currentScreenIndex].title"></h2>
              <!-- <div class="assessment-audio" @click="this.audio">
                <Icon name="solid-volume" :classes="['fill-secondary-400']" />
                <span>Listen to text</span>
              </div> -->
							<div></div>
            </div>

            <div class="d-flex justify-content-center">
              <ul class="assessment-result-list secondary-400 max-width-50 | margin-top-2">
                <li v-for="(item, index) in this.survey[this.currentScreenIndex].list" :key="index" class="primary-400 no-shadow">
                  <span class="count">{{ index + 1 }}</span>
                  <div class="info">{{ item }}</div>
                </li>
              </ul>
            </div>

            <p v-for="(description, index) in this.survey[this.currentScreenIndex].description" :key="index" class="section-text section-text-1 | margin-top-4 text-align-center">
              <b>{{ description }}</b>
            </p>

            <div class="d-flex justify-content-center | margin-top-3">
              <button @click="this.nextScreen" class="button button-primary min-width-button">Start Survey</button>
            </div>
          </div>

          <!-- Question Layout -->

          <div v-if="this.survey[this.currentScreenIndex] !== undefined && this.survey[this.currentScreenIndex].layout === 'question'">
            <!-- Question -->

            <h2 class="section-heading section-heading-3 | margin-bottom-2">Tell us how you did with the following goal:</h2>
            <div class="caption-cards caption-cards-small | margin-top-3 margin-bottom-3">
              <div class="caption-card caption-card-secondary-500 caption-card-horizontal">
                <div class="caption-card-header">
                  <img class="caption-card-image" :src="this.survey[this.currentScreenIndex].image" alt="img" style="height:10rem;width:15rem;"/>
                </div>
                <div class="caption-card-body">
                  <p class="caption-card-text">{{this.survey[this.currentScreenIndex].title}}</p>
                </div>
              </div>
            </div>
            <div class="assessment no-shadow">
              <div class="assessment-main">
                <h2 class="section-heading section-heading-3 | text-align-center">I met this goal:</h2>
                <div class="assessment-answers | margin-top-4">
                  <div @click="this.chooseAnswer(this.survey[this.currentScreenIndex].questions[0], answerIndex)" v-for="(answer, answerIndex) in this.survey[this.currentScreenIndex].questions[0].choices" :key="answerIndex" :class="{ 'assessment-answer': true, selected: answer.selected }">
                    <Icon :name="answer.selected === true ? '' : 'none'" />
                    <span>{{ answer.title }}</span>
										<img class="smiley-icon" :src="this.smileyImage(answerIndex)" />
                  </div>
                </div>
              </div>

              <div class="assessment-aside" style="display: unset">
                <div>
                  <h2 class="section-heading section-heading-3 | text-align-center">This goal was:</h2>
                  <div class="assessment-answers | margin-top-4">
                    <div @click="this.chooseAnswer(this.survey[this.currentScreenIndex].questions[1], answerIndex)" v-for="(answer, answerIndex) in this.survey[this.currentScreenIndex].questions[1].choices" :key="answerIndex" :class="{ 'assessment-answer': true, selected: answer.selected }">
                      <Icon :name="answer.selected === true ? '' : 'none'" />
                      <span>{{ answer.title }}</span>
											<img class="smiley-icon" :src="this.smileyImage(answerIndex)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Next -->

            <div class="assessment-footer | margin-top-3">
              &nbsp;
              <button @click="this.nextScreen" class="button button-primary">
                {{ this.currentScreenIndex == this.survey.length - 1 ? 'Finish' : 'Next' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="this.postSurveyAnswers !== null">
      <h2 class="section-heading section-heading-3 | margin-top-5 text-align-center">You have already completed this survey!</h2>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';

	import smiley1 from '@/assets/smiley-1.png';
	import smiley2 from '@/assets/smiley-2.png';
	import smiley3 from '@/assets/smiley-3.png';
	import smiley4 from '@/assets/smiley-4.png';

	import image2 from '@/assets/post-survey-2.jpg';
	import image3 from '@/assets/post-survey-3.jpg';
	import image4 from '@/assets/post-survey-4.jpg';
	import image5 from '@/assets/post-survey-5.jpg';
	import image6 from '@/assets/post-survey-6.jpg';
	import image7 from '@/assets/post-survey-7.jpg';
	import image8 from '@/assets/post-survey-8.jpg';
	import image9 from '@/assets/post-survey-9.jpg';

  export default {
    name: 'ActPostSurvey',
    components: { Icon },
    data() {
      return {
        goalId: null,
        actionSurveyId: null,
        goals: JSON.parse(JSON.stringify(this.$store.getters.goals)),
        goalSurveys: [
          {
            goalId: 2,
            survey: [
              {
                title: 'Last week we focused on <span class="color-secondary-400">toothbrushing</span>. Let\'s see how you did on each part of that goal!',
                description: [],
                audio: '',
                list: ['1. Using fluoride toothpaste', '2. Brush Twice a day', '3. Brush all Tooth Surfaces'],
                layout: 'linear-actions',
              },
              {
								title:"Make sure your toothpaste has fluoride",
								image:image2,
                questions: [
                  {
                    title: 'Make sure your toothpaste has fluoride: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Make sure your toothpaste has fluoride: The goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
              {
								title:"Brush twice a day - every day",
								image:image3,
                questions: [
                  {
                    title: 'Brushing Two Times each Day  I met this goal:',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Brushing Two Times each Day This goal was:',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
              {
								title:"Be sure to brush all parts of all your teeth",
								image:image4,
                questions: [
                  {
                    title: 'Brushing all Tooth Surfaces  I met this goal:',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Brush all Tooth Surfaces This goal was:',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
            ],
          },
          {
            goalId: 3,
            survey: [
              {
                title: 'Last week we focused on <span class="color-secondary-400">healthy eating</span>. Let\'s see how you did on each part of that goal!',
                description: [],
                audio: '',
                list: ['Choosing healthy foods', 'Limiting, sticky, sweet, starchy snacks'],
                layout: 'linear-actions',
              },
              {
								title:"Choosing healthy foods",
								image:image5,
                questions: [
                  {
                    title: 'Choosing healthy foods: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Choosing healthy foods: This goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
							{
								title:"Limiting sticky, sweet, starchy snacks",
								image:image6,
                questions: [
                  {
                    title: 'Limiting sticky, sweet, starchy snacks: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Limiting sticky, sweet, starchy snacks: This goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
            ],
          },
          {
            goalId: 4,
            survey: [
              {
                title: 'Last week we focused on <span class="color-secondary-400">healthy drinking</span>. Let\'s see how you did on each part of that goal!',
                description: [],
                audio: '',
                list: ['Make Water My Go-To Drink', 'Limiting sugary drinks to mealtime and special occasions/parties'],
                layout: 'linear-actions',
              },
              {
								title:"Making water my go-to drink",
								image:image7,
                questions: [
                  {
                    title: 'Make Water My Go-To Drink: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Make Water My Go-To Drink: This goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
							{
								title:"Limiting sugary drinks to mealtime and special occasions/parties",
								image:image8,
                questions: [
                  {
                    title: 'Limiting sugary drinks to mealtime and special occasions/parties: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Limiting sugary drinks to mealtime and special occasions/parties: This goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
            ],
          },
          {
            goalId: 5,
            survey: [
              {
                title: 'Last week we focused on <span class="color-secondary-400">eating thoughtfully</span>. Let\'s see how you did on each part of that goal!',
                description: [],
                audio: '',
                list: ['Make mealtime time to eat', 'Don’t eat just because you are bored or want a reward'],
                layout: 'linear-actions',
              },
              {
								title:"Making mealtime time to eat",
								image:image9,
                questions: [
                  {
                    title: 'Making mealtime time to eat: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Making mealtime time to eat: This goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
							{
								title:"Not eating just because you are bored or want a reward",
								image:image9,
                questions: [
                  {
                    title: 'Not eating just because you are bored or want a reward: I met this goal',
                    audio: '',
                    choices: [
                      {
                        title: 'Every day',
                      },
                      {
                        title: 'Most days',
                      },
                      {
                        title: 'Some days',
                      },
                      {
                        title: 'No days',
                      },
                    ],
                  },
                  {
                    title: 'Not eating just because you are bored or want a reward: This goal was',
                    audio: '',
                    choices: [
                      {
                        title: 'Easy to meet',
                      },
                      {
                        title: 'Somewhat easy to meet',
                      },
                      {
                        title: 'Hard to meet',
                      },
                    ],
                  },
                ],
                layout: 'question',
              },
            ],
          },
        ],
        survey: [],
        currentScreenIndex: 0,
        postSurveyAnswers: null,
      };
    },

    methods: {
      nextScreen() {
        let questionAnswered = this.isQuestionAnswered(this.currentScreenIndex);

        if (questionAnswered === false) {
          this.$helper.Notification.show({
            text: 'Please choose all answers to continue.',
            classes: ['fail'],
          });
          return;
        }

        this.currentScreenIndex++;
        if (this.currentScreenIndex > this.survey.length - 1) this.completeSurvey();
      },

			smileyImage(index){

				if(index == 0) return smiley1;
				else if(index == 1) return smiley2;
				else if(index == 2) return smiley3;
				else if(index == 3) return smiley4;

			},

      /**
       * ===== Survey & Goals
       */

      async fetchPostSurvey(actionSurveyId, goalId) {
        let response = await this.$store.dispatch('myActionSurveys');
        let actionSurveys = response.data;

        let actionSurvey = actionSurveys.find((s) => s.id == actionSurveyId);
        let actionSurveyAnswers = actionSurvey.action_survey_answers;

        let match = actionSurveyAnswers.filter((answer) => {
          if (answer.goal_id == goalId && answer.action_survey_id == actionSurveyId && answer.action_survey_type == 'post-survey') return answer;
        });

        if (match.length > 0) this.postSurveyAnswers = match;
        else this.postSurveyAnswers = [];
      },

      completeSurvey() {
        let answers = this.survey
          .map((screen) => {
            let questions = screen.questions;
            if (questions === undefined) return null;
            return questions;
          })
          .filter((q) => q !== null);

        answers = answers.reduce((acc, row) => {
          acc = acc.concat(row);
          return acc;
        }, []);

        this.$store.dispatch('saveActionPostSurvey', {
          actionSurveyId: this.actionSurveyId,
          goalId: this.goalId,
          answers: answers,
        });

        this.$helper.Notification.show({
          text: 'Thank you for the completing the survey.',
          classes: ['success'],
        });

				if(this.goalId != 5) this.$router.push(this.$store.getters.dashboardURL);
				else this.$router.push('/act/completed');
        
      },

      chooseAnswer(question, answerIndex) {
        let choices = question.choices;

        choices.forEach((choice, choiceIndex) => {
          if (choiceIndex == answerIndex) choice.selected = true;
          else choice.selected = false;
        });
      },

      highlightWizardBar(goalId) {
        goalId = +goalId;

        this.goals.forEach((goal) => {
          if (goal.id == goalId) goal.active = true;
          else if (goal.id < goalId) goal.complete = true;
        });
      },

      isQuestionAnswered(index) {
        let screen = this.survey[index];
        let questions = screen.questions;
        if (questions === undefined) return true;

        let answered = questions.every((question) => {
          let match = question.choices.some((choice) => choice.selected == true);
          if (match == true) return true;
        }, false);

        return answered;
      },

      findGoalSurvey(goalId) {
        let data = this.goalSurveys.find((s) => s.goalId == goalId);
        return data.survey;
      },

      findGoal(goalId) {
        return this.goals.find((g) => g.id == goalId);
      },
    },

    /**
     * ===== Hooks
     */

    mounted() {
      this.goalId = this.$route.params.goalId;
      this.actionSurveyId = this.$route.params.actionSurveyId;
      this.survey = this.findGoalSurvey(this.goalId);
      this.highlightWizardBar(this.goalId);
      this.fetchPostSurvey(this.actionSurveyId, this.goalId);
    },
  };
</script>
