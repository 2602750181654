<template>
  <div :class="{'margin-top-1': this.childView !== true}">
    <div :class="{'container': this.childView !== true}">

			<h2 v-if="this.childView !== true" class="section-heading section-heading-3 | margin-bottom-3">Edit Group</h2>

      <div>
        <div class="grids grids-2 grids-break-lg-100">
          <div class="grid">
            <form action="#" id="member-form" data-prevent="default">
              <div class="form-group">
                <label for="name" class="label-style-1">Group name</label>
                <input id="name" type="text" class="input-style-1" v-model="this.groupName" />
              </div>

              <div class="form-group">
                <label for="member" class="label-style-1">Add members</label>
                <div class="position-relative">
                  <InputSuggestion @selectSuggestion="this.handleIndividualSelectSuggestion" for="user" role="individual" placeholder="Search individual’s ID or email address" />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="data-table-container | margin-top-1" v-if="this.individuals.length > 0">
          <label for="#" class="label-style-1">Group members</label>

          <table id="individual-table" class="data-table no-header">
            <thead class="sticky hide">
              <tr>
                <th data-uid="true"></th>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(individual, index) in this.individuals" :key="index">
                <td class="hide">5</td>
                <td class="">
                  <Icon name="solid-user" :classes="['icon-primary', 'icon-round']" />
                </td>
                <td class="">{{ individual.first_name }}</td>
                <td class="">{{ individual.email }}</td>
                <td class="">{{ individual.status }}</td>
                <td><span class="tag tag-success">Added</span></td>
                <td>
                  <div class="button-group">
                    <button @click="this.removeIndividual(individual.id)" class="button button-danger">
                      <Icon name="solid-trash" :classes="[]" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <div class="grids grids-2 grids-break-lg-100 | margin-top-1">
          <div class="grid">
            <form action="#" id="manager-form" data-prevent="default">
              <div class="form-group">
                <label for="member" class="label-style-1">Add manager</label>
                <div class="position-relative">
                  <InputSuggestion @selectSuggestion="this.handleManagerSelectSuggestion" for="user" role="caregiver" placeholder="Search manager’s ID or email address" />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="data-table-container | margin-top-3" v-if="this.managers.length > 0">
          <label for="#" class="label-style-1">Group managers</label>

          <div class="data-table-toolbar sticky hide">
            <div class="data-table-toolbar-section search-section hide">
              <input type="text" class="search input-style-1" />
              <svg class="icon search-icon">
                <use xlink:href="@/assets/icons.svg#search" />
              </svg>
              <svg class="icon cross-icon">
                <use xlink:href="@/assets/icons.svg#cross" />
              </svg>
            </div>
            <div class="data-table-toolbar-section right"></div>
          </div>

          <table id="manager-table" class="data-table no-header">
            <thead class="sticky hide">
              <tr>
                <th data-uid="true"></th>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Registration</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(manager, index) in this.managers" :key="index">
                <td class="hide">5</td>
                <td class="">
                  <Icon name="solid-user" :classes="['icon-secondary', 'icon-round']" />
                </td>
                <td class="">{{ manager.first_name }}</td>
                <td class="">{{ manager.email }}</td>
                <td class="">{{ manager.designation }}</td>
                <td><span class="tag tag-success">Added</span></td>
                <td>
                  <div class="button-group">
                    <button @click="this.removeManager(manager.id)" class="button button-danger">
                      <Icon name="solid-trash" :classes="[]" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="margin-top-3">
        <button v-if="this.$store.getters.pageSlug === 'edit-group'" class="button button-primary min-width-button width-md-100" @click="this.saveGroup">Save</button>
        <button v-else class="button button-primary min-width-button width-md-100" @click="this.saveGroup">Create</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons';
  import InputSuggestion from '@/components/Form/InputSuggestion.vue';

  export default {
    name: 'GroupCreate',
		props:["childView"],
    data() {
      return {
        groupId: this.$route.params.groupId,
        groupName: '',
        managers: [],
        individuals: [],
      };
    },
    components: { InputSuggestion, Icon },

    /**
     * ===== Methods
     */

    methods: {
      handleIndividualSelectSuggestion(data) {
        let find = this.individuals.find((row) => row.id == data.id);
        if (find !== undefined) return;

        this.individuals.push(data);
      },

      handleManagerSelectSuggestion(data) {
				let find = this.managers.find((row) => row.id == data.id);
			
        if (find !== undefined) return;

        this.managers.push(data);
			},

      removeManager(id) {
        this.managers = this.managers.filter((m) => m.id !== id);
      },
      removeIndividual(id) {
        this.individuals = this.individuals.filter((m) => m.id !== id);
      },
      async saveGroup() {
        let n = this.$helper.Notification.show({
          text: 'Saving group, please wait...',
          classes: [],
          time: 0,
        });

        let group = {
          id: this.groupId,
          name: this.groupName,
          managers: this.managers.map((m) => m.id),
          individuals: this.individuals.map((i) => i.id),
        };

        let response = await this.$store.dispatch('saveGroup', group);

        this.$helper.Notification.hideAndShowDelayed(n.data.id, {
          text: response.data.msg,
          classes: [response.data.status],
        });

        if (response.data.status === 'success') this.$router.push(`/group/show/${response.data.groupId}`);
      },
      async fetchGroup() {
        let groupId = this.$route.params.groupId;
        let g = await this.$store.dispatch('group', groupId);
        let group = g.data;
        this.groupName = group.title;
        group.group_users.forEach((groupUser) => {
          if (groupUser.user.role_title === 'individual') this.individuals.push(groupUser.user);
          else if (groupUser.user.role_title === 'caregiver') this.managers.push(groupUser.user);
        });
      },
    },

    /**
     * ===== Mounted
     */

    mounted() {
      if (this.$route.name === 'GroupEdit') this.fetchGroup();
    },
  };
</script>
