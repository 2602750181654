<template>
  <div class="section-border">
    <div class="widget-header">
      <div class="widget-icon-text-header left">
        <Icon name="outline-tick-circle" class="icon" />
        <h2 class="widget-heading">Progress</h2>
      </div>
      <div class="right"></div>
    </div>

    <div class="action-plan-progress-widget-body">
      <div class="action-plan-progress-widget-table">
        <div class="header">
          <div class="column">
            <p>Goal</p>
          </div>
          <div class="column">
            <p>Target Completion Date</p>
          </div>
        </div>

        <div class="body">
          <div class="row" v-for="(result, index) in this.goals" :key="index">
            <div class="column">
							<div class="status-icon-container" :class="{'complete': result.complete === true, active: result.active === true}" >
								<Icon :name="result.complete === true ? 'solid-tick' : ''" />
							</div>
						</div>
            <div class="column">
							<img class="progress-image" :src="this.$config.serverBaseURL +'/'+result.smallImage" :alt="result.title" >
						</div>
            <div class="column">{{ result.title }}</div>
            <div class="column">
              <span v-if="index <= 0">{{ result.create_datetime }}</span>
              <span v-else>
                {{
                  this.addDaysToDate(
                    this.goals[0].create_datetime,
                    7 * (index)
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon/Icons.vue";

export default {
  name: "ActionPlanProgressWidget",
  components: {
    Icon,
  },
  props: ["goals"],
  methods: {
    addDaysToDate(date, days) {
      if (date === "" || date === null || date === false) return "";
      let response = this.$helper.DateTime.addDaysToDate(date, days);
      return response.month + "/" + response.day + "/" + response.year;
    },
  },
};
</script>