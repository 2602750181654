<template>
  <div class="margin-top-1">
    <div class="container">
      <div class="breadcrumbs | d-flex align-items-center justify-content-space-between margin-bottom-3">
        <ul>
          <li class="active"><a href="#">Assess</a></li>
          <li><a href="#">Act</a></li>
          <li><a href="#">Follow up</a></li>
        </ul>
      </div>

      <div class="assessment-border-padding">
        <div class="d-flex flex-direction-column align-items-center" style="width: 100%">
          <h1 class="section-heading section-heading-2 color-secondary-400 | text-align-center">Risk Assessment Results</h1>

          <div style="width: 100%; max-width: 530px" :class="{'hide': this.good.length == 0}">
            <p class="section-heading-5 | light text-align-center margin-top-1">
							<b>You are protecting your smile through these helpful behaviors:</b>
						</p>
            <div class="d-flex justify-content-center margin-top-4">
              <ul class="assessment-result-list secondary-400">
                <li v-for="(item, index) in this.good" :key="index">
                  <div>
                    <img class="item-image" :src="this.$config.serverBaseURL + '/' + this.goalImage(item.title)" :alt="item.title" />
                  </div>
                  <div class="info">
                    {{ item.title }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </div>

        <div :class="{'hide': this.risks.length == 0}" class="d-flex flex-direction-column align-items-center | margin-top-3" style="width: 100%">
          <p v-if="this.good.length > 0" class="section-heading-5 | light text-align-center margin-top-1" style="max-width: 530px"><b>Here are some important things you can do to promote a healthy mouth:</b></p>
          <p v-else class="section-heading-5 | light text-align-center margin-top-1" style="max-width: 530px"><b>You may be at risk of cavities.<br /> Here are some ways you can keep your teeth healthy!</b></p>

          <div class="d-flex justify-content-center margin-top-4" style="width: 100%; max-width: 530px">
            <ul class="assessment-result-list buring-orange-400">
              <li v-for="(item, index) in this.risks" :key="index">
                <div>
                  <img class="item-image" :src="this.$config.serverBaseURL + '/' + this.goalImage(item.title)" :alt="item.title" />
                </div>
                <div class="info">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <div class="assessment-footer | margin-top-3" >
        <div>
					<router-link v-if="this.$route.query.continue === 'false' " :to="this.$store.getters.dashboardURL" class="button | no-shadow">Back</router-link>
				</div>
        <div>
          <button v-if="this.$route.query.continue !== 'false' " @click="this.$router.push(this.$route.params.userId === undefined ? '/act' : '/act/' + this.$route.params.userId)" class="button button-primary no-shadow">Let's Get Started!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AssessmentResut',
		props: ["userId"],
    data() {
      return {
        assessment: [],
        good: [],
        risks: [],
        images: {
          'Choose Healthy Foods': 'goal-choose-healthy-foods.jpg',
          'Make Water Your Go-to Drink': 'goal-make-water-your-go-to-drink.jpg',
          'Eat Thoughtfully': 'goal-eat-thoughtfully.jpg',
          'Brush Twice a Day': 'goal-brush-twice-a-day.jpg',
          'Brush all Tooth Surfaces': 'goal-brush-all-tooth-surfaces.jpg',
          'Use Fluoride Toothpaste': 'goal-use-fluoride-toothpaste.jpg',
          'Visit your Dentist': 'goal-visit-your-dentist.jpg',
        },
      };
    },

    methods: {
      async fetchAssessment() {

				let action = 'myAssessment';
				if(this.$route.params.userId !== undefined && this.$route.params.userId != null) action = 'userAssessment';

        let response = await this.$store.dispatch(action, this.$route.params.userId);
        if (response.data.length <= 0) this.$router.push(this.$store.getters.dashboardURL);
        this.assessment = response.data[0];

        this.assessment.assessment_results.forEach((result) => {
          if (result.status === 'good') this.good.push(result);
          else if (result.status === 'risk') this.risks.push(result);
        });

	
      },

      goalImage(name) {
        let image = '';
        for (let key in this.images) {
          if (key.toLowerCase() == name.toLowerCase()) image = this.images[key];
        }

        return image;
      },
    },

    mounted() {
      this.fetchAssessment();
    },
  };
</script>
