<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Limiting Sugary Drinks</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-14.jpg" alt="img1" />
          <ul>
            <li>Mouth bacteria turn sugars in drinks into acid that harms teeth</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/resource-15.jpg" alt="img1" />
          <ul>
            <li>Remember that sugary drinks like soda and juice are special drinks for meal times or special occasions only and water is always best!</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center | margin-top-3">
      <div class="grid" style="max-width:97rem;width:100%;">
        <div class="pre-survey-vertical-section solid-border d-flex break-md-100 gap-3">
          <img src="@/assets/resource-16.jpg" alt="img1" style="max-width: 30rem" />
          <div>
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Tips</h2>
            <ul>
              <li>Keep water handy for drinking all day-at mealtime and for snacks</li>
              <li>Keep soda and juice out of sight and out of mind</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
			<div class="margin-top-3">
				<router-link to="/resources/goal-4" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResourceGoal4c',
  };
</script>
