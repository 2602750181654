import axios from "axios";
import config from "@/config/index.js";


const group = {

	state: {},

	mutations: {},

	actions: {
		async joinedGroups() {
			return await axios.get(config.serverBaseURL + "/api/groups/joined");
		},

		async saveGroup(action, group) {
			return await axios.post(config.serverBaseURL + "/api/groups", group);
		},

		async group(action, groupId) {
			return await axios.get(config.serverBaseURL + "/api/groups/one/" + groupId);
		},

		async groupByUid(action, uid) {
			return await axios.get(config.serverBaseURL + "/api/groups/one/uid/" + uid);
		},

		async assignMeToGroup(action, groupId) {
			return await axios.get(config.serverBaseURL + "/api/groups/assign/me/" + groupId);
		}

	},

	getters: {}

};

export default group;