import { createApp } from 'vue'
import App from './App.vue'

import style from "@/css/style.css";

import config from "@/config/index.js";
import helper from "@/helpers/index.js";
import router from "@/routes/index.js";
import store from "@/store/index.js";


let app = createApp(App);

app.use(router).use(store).use(style).mount('#app');

app.config.globalProperties.$config = config;
app.config.globalProperties.$helper = helper;