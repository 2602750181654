<template>
  <footer class="footer-1">
    <div class="container | flex-1">
      <p>© {{this.$helper.DateTime.getFullYear() }} {{ this.$config.appName }}</p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'PrimaryFooter',
  };
</script>
