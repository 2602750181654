/* eslint-disable no-unused-vars */
//@ts-ignore

import icons from "./../assets/icons.svg";


var Notification = function () {
	let notificationSpeed = 500; //ms
	return {
		toString() {
			console.log("I am notification v1");
		},
		show: function (obj = {}) {
			var _a, _b;
			let CONTEXT = this;
			let notification = this.prepareNotification(obj);
			let containerExists = this.containerExists();
			if (containerExists === true)
				(_a = document.querySelector('.notification-container')) === null || _a === void 0 ? void 0 : _a.insertAdjacentHTML('afterbegin', notification.template);
			else {
				this.addNotificationContainer();
				(_b = document.querySelector('.notification-container')) === null || _b === void 0 ? void 0 : _b.insertAdjacentHTML('afterbegin', notification.template);
			}
			if (notification.data.time !== 0) {
				setTimeout(function () {
					CONTEXT.hide(notification.data.id);
				}, notification.data.time);
			}
			return notification;
		},
		hide: function (notificationId) {
			let notificationElement = document.getElementById(notificationId);
			notificationElement === null || notificationElement === void 0 ? void 0 : notificationElement.classList.remove('active');
			notificationElement === null || notificationElement === void 0 ? void 0 : notificationElement.classList.add('inactive');
			setTimeout(function () {
				var _a;
				notificationElement === null || notificationElement === void 0 ? void 0 : notificationElement.remove();
				let isContainerEmpty = document.querySelectorAll('.notification-container .notification').length <= 0;
				if (isContainerEmpty === true)
					(_a = document.querySelector('.notification-container')) === null || _a === void 0 ? void 0 : _a.remove();
			}, notificationSpeed);
		},
		hideDelayed: function (notificationId) {
			setTimeout(() => this.hide(notificationId), 1000);
		},
		hideAndShowDelayed: function (notificationId, obj = {}) {
			setTimeout(() => {
				this.hide(notificationId);
				setTimeout(() => {
					this.show(obj);
				}, 500);
			}, 1000);
		},
		containerExists() {
			let notificationContainer = document.querySelector('.notification-container');
			return notificationContainer !== null;
		},
		addNotificationContainer() {
			var _a;
			let container = `
			<div class="notification-container">
				<style>
					@keyframes notification-reveal {
						0% {
							transform: translateY(calc(100% + 20px));
						}
		
						100% {
							transform: translateY(0px);
						}
					}
		
					@keyframes notification-hide {
						0% {
							transform: translateY(0px);
						}
		
						100% {
							transform: translateY(calc(100% + 20px));
						}
					}
		
					.notification-container {
						display: flex;
						gap: 10px;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						min-width:35rem;
						padding-bottom: 20px;
						position: fixed;
						left: 50%;
						bottom: 0px;
						z-index: 99;
						transform: translateX(-50%);
					}
		
					.notification {
						background-color: var(--clr-neutral-400);
						display: flex;
						align-items: center;
						gap: 20px;
						max-width: 450px;
						width:100%;
						padding: 10px 25px 10px 12px;
						border: 2px solid var(--clr-neutral-500);
						border-radius: 0.6rem;
						transform: translateY(calc(100% + 20px));
					}
		
					.notification.active {
						animation-name: notification-reveal;
						animation-duration: ${notificationSpeed}ms;
						animation-fill-mode: forwards;
						animation-iteration-count: 1;
					}
		
					.notification.inactive {
						animation-name: notification-hide;
						animation-duration: ${notificationSpeed}ms;
						animation-fill-mode: forwards;
						animation-iteration-count: 1;
					}
		
					.notification-icon-container {
						background-color: var(--clr-neutral-500);
						display: flex;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						width: 40px;
						height: 40px;
						border-radius: 1000vmax;
					}
		
					.notification-icon {
						fill: var(--clr-neutral-700);
						width: 18px;
						height: 18px;
					}
		
					.notification-text-container {
						display: flex;
						font-size:1.7rem;
						font-weight:500;
						align-items: center;
						color: var(--clr-neutral-700);
					}

					.notification.success{
						color: var(--clr-neutral-200);
						background-color: var(--clr-green-400);
						border-color: var(--clr-green-400);
					}

					.notification.success .notification-icon-container
					{
						background-color: var(--clr-green-500);
					}

					.notification.success .notification-text-container{
						color: var(--clr-neutral-200);
					}

					.notification.success .notification-icon
					{
						fill: var(--clr-neutral-200);
					}

					.notification.fail{
						color: var(--clr-neutral-200);
						background-color: var(--clr-red-400);
						border-color: var(--clr-red-400);
					}

					.notification.fail .notification-icon-container
					{
						background-color: var(--clr-red-500);
					}

					.notification.fail .notification-icon{
						fill: var(--clr-neutral-200);
					}

					.notification.fail .notification-text-container{
						color: var(--clr-neutral-200);
					}

					@media (max-width: 576px){
						.notification-container{
							width:100%
						}
					}

				</style>
			</div>
		`;
			(_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.insertAdjacentHTML('afterbegin', container);
		},
		prepareNotification(obj = {}) {
			let { text = 'Default Message', time = 6000, classes = [] } = obj;
			let id = this.generateId();
			let template = `
				<div id="${id}" class="notification active | cursor-pointer ${classes.join(' ')}">
					<div class="notification-icon-container">
						<svg class="notification-icon">
							<use xlink:href="${icons}#solid-info" />
						</svg>
					</div>
					<div class="notification-text-container">
						<p class=" | fs-700 fw-medium">${text}</p>
					</div>
				</div>
			`;
			return {
				template: template,
				data: {
					id: id,
					text: text,
					time: time
				}
			};
		},
		generateId() {
			return (new Date()).getTime() + '-' + Math.floor(Math.random() * 500);
		}
	};
}();
export default Notification;
/* eslint-enable no-unused-vars */
