<template>
  <div>
    <div :class="{'container': this.$route.params.userId === undefined}">
      <!-- Account Management -->

      <div class="account-panel-container | padding-top-3 padding-bottom-3">
        <h2 class="section-heading section-heading-3 | margin-bottom-2">Account Management</h2>

        <div class="account-panel">
          <div class="grids grids-2">
            <div class="grid">
              <form @submit.prevent="saveProfile" action="#" id="profile-form">
                <div class="form-group inline">
                  <label for="first-name" class="label-style-1">First name:</label>
                  <input name="first-name" class="input-style-1" type="text" v-model="this.profile.firstName" />
                </div>

                <div class="form-group inline">
                  <label for="last-name" class="label-style-1">Last name:</label>
                  <input name="last-name" class="input-style-1" type="text" v-model="this.profile.lastName" />
                </div>

                <div class="form-group inline">
                  <label for="email" class="label-style-1">Email:</label>
                  <input name="last-name" class="input-style-1" type="text" v-model="this.profile.email" />
                </div>

                <!-- <div class="form-group inline">
                  <label for="phone" class="label-style-1">Phone number:</label>
                  <input
                    name="phone"
                    class="input-style-1"
                    type="text"
                    v-model="this.profile.phone"
                  />
                </div> -->

                <div class="form-group inline">
                  <label for="password" class="label-style-1">Password:</label>
                  <input name="password" class="input-style-1" type="password" placeholder="new password" v-model="this.profile.password" autocomplete="new-password" />
                </div>

                <!-- <div class="form-group inline">
                  <label for="dob" class="label-style-1">Date of birth:</label>
                  <input
                    name="dob"
                    class="input-style-1"
                    type="date"
                    v-model="this.profile.dob"
                  />
                </div> -->
              </form>
            </div>
            <div v-if="this.profile.roleTitle === 'individual' || this.$route.params.userId !== undefined" class="grid">
              <h3 class="section-heading section-heading-4 | margin-bottom-1">Associated Caregivers</h3>
              <p v-if="this.profile.roleTitle === 'individual'" class="section-text section-text-1">You can view and add associated caregivers</p>
              <p v-else class="section-text section-text-1">You can add/remove the associated caregivers</p>

              <ul class="margin-top-2" id="caregiver-list">
                <li v-for="(association, index) in this.myCaregivers" :key="index">
                  <span class="text">
                    {{ association['caregiver_name'] == null ? association['caregiver.first_name'] + ' ' + association['caregiver.last_name'] : association['caregiver_name'] }}
                  </span>

                  <button v-if="this.$route.params.userId !== undefined" @click="removeCaregiver(association['id'])" class="button button-danger | no-shadow">Delete</button>
                </li>
              </ul>

              <form @submit.prevent="" action="#" class="margin-top-3">
                <div class="form-group inline">
                  <select v-model="this.selectedCaregiver" class="input-style-1" id="caregiver">
                    <option value="-1">Select a caregiver from the list</option>
                    <option v-for="(caregiver, index) in this.otherCaregivers" :key="index" :value="caregiver.id">
                      {{ caregiver.first_name + ' ' + caregiver.last_name }}
                    </option>
                  </select>
                  <div>
                    <svg @click.prevent="this.assignCaregiver" class="icon input-primary-icon no-position | cursor-pointer">
                      <use xlink:href="@/assets/icons.svg#solid-plus"></use>
                    </svg>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="margin-top-3 | d-flex justify-content-space-between">
          <router-link :to="this.$store.getters.dashboardURL" class="button no-shadow">Back</router-link>
          <button @click.prevent="saveProfile" class="button button-primary">Submit Changes</button>
        </div>
      </div>
    </div>
    <!-- Confirmation Popup -->
    <ConfirmationPopup />
  </div>
</template>

<script>
  import ConfirmationPopup from '@/components/Popup/Confirmation.vue';

  export default {
    name: 'AccountProfile',
    components: {
      ConfirmationPopup,
    },
    data() {
      return {
        profile: {
          id: this.$route.params.userId,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          dob: '',
          password: '',
          roleTitle: '',
        },

        myCaregivers: [],
        otherCaregivers: [],
        selectedCaregiver: '-1',
      };
    },

    methods: {
      async populateProfile() {
        if (!this.$route.params.userId) {
          let me = this.$store.getters.user;
          this.profile.firstName = me.firstName;
          this.profile.lastName = me.lastName;
          this.profile.phone = me.phone;
          this.profile.dob = me.dob;
          this.profile.email = me.email;
          this.profile.roleTitle = me.roleTitle;
        } else {
          let userId = this.$route.params.userId;
          let individual = await this.$store.dispatch('individual', userId);

          let caregivers = individual.data.caregivers;
          this.myCaregivers = caregivers.myCaregivers;
          this.otherCaregivers = caregivers.other;
          this.selectedCaregiver = '-1';

          let user = individual.data.user;

          this.profile.firstName = user.first_name;
          this.profile.lastName = user.last_name;
          this.profile.email = user.email;
          this.profile.phone = user.phone;
          this.profile.dob = user.dob;
        }
      },

      async saveProfile() {
        let n = this.$helper.Notification.show({
          text: 'Saving profile, please wait...',
          classes: [],
          time: 0,
        });

        let response = await this.$store.dispatch('saveProfile', this.profile);
        this.$helper.Notification.hide(n.data.id);

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });
      },

      async populateCaregivers() {

				let action = 'myCaregivers';
				if(this.$route.params.userId !== undefined && this.$route.params.userId !== null) action = 'userCaregivers';

        let caregivers = await this.$store.dispatch(action, this.$route.params.userId);
        this.myCaregivers = caregivers.data.myCaregivers;
        this.otherCaregivers = caregivers.data.other;
        this.selectedCaregiver = '-1';
      },

      async assignCaregiver() {
        let n = this.$helper.Notification.show({
          text: 'Adding caregiver, please wait...',
          classes: [],
          time: 0,
        });

        let response = await this.$store.dispatch('assignCaregiver', {
          caregiverId: this.selectedCaregiver,
          userId: this.$route.params.userId,
        });
        this.$helper.Notification.hide(n.data.id);

        if (response.data.status === 'success') this.populateCaregivers();

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });
      },

      async removeCaregiver(id) {
        let actionType = await this.$store.dispatch('showConfirmation', {
          id: id,
        });

        if (actionType !== 'positive') return;

        let n = this.$helper.Notification.show({
          text: 'Removing caregiver, please wait...',
          classes: [],
          time: 0,
        });

        let response = await this.$store.dispatch('disassociateCaregiver', id);
        this.$helper.Notification.hide(n.data.id);

        if (response.data.status === 'success') this.populateCaregivers();

        this.$helper.Notification.show({
          text: response.data.msg,
          classes: [response.data.status],
        });
      },
    },

    mounted() {
      this.populateProfile();
      this.populateCaregivers();
    },
  };
</script>
