<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Thoughtful Eating</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why is it important to eat?</h2>
					<ul>
            <li>To get energy</li>
            <li>To get healthy</li>
            <li>To have fun with friends and family</li>
          </ul>
          <img class="margin-top-2" src="@/assets/pre-survey-24.jpg" alt="img1" />
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Sometimes we eat because:</h2>
          <ul>
            <li>we are bored</li>
            <li>we are sad</li>
            <li>we want a reward</li>
          </ul>
          <img class="margin-top-2" src="@/assets/pre-survey-25.jpg" alt="img1" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center | margin-top-3">
      <div class="grid" style="max-width:97rem;width:100%;">
        <div class="pre-survey-vertical-section solid-border d-flex break-md-100 gap-3 ">
          <img src="@/assets/pre-survey-26.jpg" alt="img1" style="max-width: 30rem" />
          <div>
            <h2 class="pre-survey-vertical-section-heading | color-secondary-400" style="text-align:left;">That is ok! But instead we can try:</h2>
            <ul>
              <li>a fun exercise</li>
              <li>watching a show</li>
              <li>listening to music</li>
              <li>talking to a friend</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
			<div class="margin-top-3">
				<router-link to="/resources/goal-5" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
    name: 'ResourceGoal5a',
  };
</script>
