import axios from "axios";
import config from "@/config/index.js";

const individual = {
	state: {},

	mutations: {},

	actions: {
		async individuals() {
			return await axios.get(config.serverBaseURL + '/api/individuals');
		},

		async individual(action, userId) {
			return await axios.get(config.serverBaseURL + '/api/individuals/one/' + userId);
		},

		async individualByUidOrEmail(action, keyword) {
			return await axios.post(config.serverBaseURL + '/api/individuals/one/uid-or-email', {
				keyword: keyword
			});
		},

		async assignIndividual(action, individualId) {
			return axios.get(config.serverBaseURL + '/api/individuals/assign/' + individualId)
		},
	},

	getters: {}
};

export default individual;