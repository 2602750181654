<template>
  <div class="container">
    <h1 class="pre-survey-title | color-secondary-400 text-align-center">Brush Twice a Day</h1>
    <div class="pre-survey-grids-3 | margin-top-3">
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Why?</h2>
          <img src="@/assets/resource-1.jpg" alt="img1" />
          <ul>
            <li>We brush to clean our teeth and prevent cavities</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">What to do?</h2>
          <img src="@/assets/resource-2.jpg" alt="img2" />
          <ul>
            <li>Always use fluoride toothpaste</li>
            <li>Use just a little toothpaste (like a pea)</li>
            <li>Brush every morning when you get up and every night before bed</li>
          </ul>
        </div>
      </div>
      <div class="grid">
        <div class="pre-survey-vertical-section solid-border">
          <h2 class="pre-survey-vertical-section-heading | color-secondary-400">Tips</h2>
          <img src="@/assets/resource-3.jpg" alt="img3" />
          <ul>
            <li>Always use a soft toothbrush</li>
            <li>Choose a place you like to brush like the bathroom or kitchen</li>
            <li>Do your best and let other people help you</li>
          </ul>
        </div>
      </div>
    </div>
			<div class="margin-top-3">
				<router-link to="/resources/goal-2" class="button button-primary button-md-100 min-width-button | no-shadow">Back</router-link>
			</div>
  </div>
</template>

<script>
  export default {
		name:"ResurcesGoal2a"
	};
</script>