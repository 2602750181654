<template>
  <svg :class="{icon:true, [renderClasses]:true}">
    <use :xlink:href="this.iconURL" />
  </svg>
</template>
<script>
  import Icon from '@/assets/icons.svg';
  export default {
    name: 'IconsSprite',
    components: [Icon],
    props: ['name', 'classes'],

    /**
     * ===== Computed
     */

    computed: {
      iconURL() {
        return Icon + `#${this.name}`;
      },
				renderClasses(){
					
					if(this.classes !== undefined) return this.classes.join(' ')
					return [];
				}
    }

	

  };
</script>
