<template>
  <div class="section-border">
    <div class="widget-header">
      <div class="widget-icon-text-header left">
        <Icon name="outline-printer" class="icon" style="stroke: var(--clr-neutral-900)" />
        <h2 v-if="this.activeGoalId" class="widget-heading">Print -- <router-link :to="'/action-plan/print/' + this.activeGoalId" class="color-secondary-400">Click here to view print option</router-link></h2>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icons.vue';
  export default {
    name: 'ActionPlanResultWidget',
    props: ['goals'],
    components: {
      Icon,
    },
    data() {
      return {
        activeGoalId: null,
      };
    },
    mounted() {
      let activeGoal = this.goals.find((g) => g.active == true);
      if (activeGoal === undefined) this.activeGoalId = -1;
      else this.activeGoalId = activeGoal.id;
    },
  };
</script>
